import { CREATE_DEVICE_ACCESS } from "./actionTypes";
import { BASE_URL } from '../../utils/network';


export const createDeviceAccess = (access, handleAPISuccess, handleAPIOnError) => {
  return function(dispatch, getState, { getFirebase }) {
    const action = {
      type: CREATE_DEVICE_ACCESS,
      data: {
        isLoading: true,
      }
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + '/api/v1/firebase/link/device/access';
            const headers = {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              "user-token": latestToken,
            };
        
            const body = JSON.stringify(access);
            
            return fetch(apiUrl, {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              body: body,
              // body data type must match "Content-Type" header
            }).then(response => response.json())
              .then(data => {        
                if (data.error) {
                  handleAPIOnError(data.error)
                } else {
                  //success
                  handleAPISuccess(data);
                }
                action.data.isLoading = false;
                dispatch(action);
              })
              .catch( (err) => {
                //hadle error
                handleAPIOnError(err)
                action.data.isLoading = false;
                dispatch(action);
                
            });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.accessList = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};