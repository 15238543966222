import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import { NAME } from "./constants";
import { getUserLocks, getLockInformation } from "./actions";
import { getDeviceAccess, removeDeviceAccess } from "./actions";

import CircularProgress from "@material-ui/core/CircularProgress";

import ContactUsAsset from "../../assets/contact/contactus.png";
import NoResourcesImage from "../../assets/empty/empty.svg";
import AccessItemListView from "../../components/AccessItemListView";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KeyPermissions from "../KeyPermissions";
import DeleteAccessDialog from "../../components/DeleteAccessDialog";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  fab: {
    backgroundColor: "#55c9f4",
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "#ffffff",
    "&:hover": {
      alfa: "0.4",
      backgroundColor: "#55c9f4",
      color: "#ffffff",
    },
  },
  title: {
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "14pt",
    height: "30px",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  gridContainer: {
    justifyContent: "center",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
    padding: "40px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  noWiFiContainer: {
    marginTop: 50,
    width: "100%",
    height: "100%",
    textAlign: "center",
    alignItems: "center",
  },
  noWiFiImage: {
    maxWidth: 500,
    textAlign: "center",
    margin: "auto",
  },
  noWiFiText: {
    maxWidth: 600,
    textAlign: "center",
    margin: "auto",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "200",
    fontSize: "15pt",
  },
  noWiFiContact: {
    maxWidth: 500,
    textAlign: "center",
    margin: "auto",
    marginTop: 10,
    color: "rgba(66,64,64,0.5)",
    fontWeight: "200",
    fontSize: "15pt",
  },
  accessList: {
    marginTop: 30,
  },
}));

const KeysSection = ({
  isLoading,
  userLocks,
  lockInfo,
  getLockInformation,
  getDeviceAccess,
  removeDeviceAccess,
  accessList,
}) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);

  const [locks, setUserLocks] = React.useState(userLocks ? userLocks : []);
  const [isLockWiFi, setLockHasWiFi] = React.useState(false);

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [selectedLockKey, selectLock] = React.useState(
    userLocks && userLocks.count > 0 ? userLocks[0].key : ""
  );

  const [currentLock, setCurrentLock] = React.useState(
    userLocks && userLocks.count > 0 ? userLocks[0] : null
  );

  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (userLocks) {
      setUserLocks(userLocks);
      if (userLocks.length > 0) {
        const firstLock = userLocks[0];
        setCurrentLock(firstLock);
        selectLock(firstLock.key);
      }
    }
  }, [userLocks]);

  React.useEffect(() => {
    if (currentLock) {
      // Get lock info to check if its wifi
      getLockInformation(currentLock.key);
    }
  }, [currentLock]);

  React.useEffect(() => {
    // Get lock keys if wifi
    if (lockInfo && lockInfo.wifi && lockInfo.wifi === true) {
      setLockHasWiFi(true);
      // Get lock keys
      if (currentLock) {
        getDeviceAccess(currentLock.key, handleAPIOnError);
      }
    } else {
      setLockHasWiFi(false);
    }
  }, [lockInfo]);

  const handleNewLockSelected = (event) => {
    event.persist();
    const key = event.target.value;
    const result = locks.filter((lock) => lock.key === key);
    setCurrentLock(result[0]);
    selectLock(key);
  };

  function togglePermissionDrawer(opened) {
    if (isLockWiFi) {
      setOpenDrawer(opened);
    } else {
      toast.success(
        "Para disfrutar esta funcionalidad, consulta al equipo de Cittyo"
      );
    }
  }

  function handleAPIOnError(error) {
    toast.error(error);
  }

  function handleOnAccessCreated() {
    toast.success("Permiso creado exitosamente.");
    if (currentLock) {
      getDeviceAccess(currentLock.key, handleAPIOnError);
    }
  }

  function handleDeleteAction(access) {
    handleCloseDeleteAccess();
    removeDeviceAccess(
      currentLock.key,
      access,
      () => {
        toast.success("Acceso eliminado correctamente.");
        if (currentLock) {
          getDeviceAccess(currentLock.key, handleAPIOnError);
        }
      },
      handleAPIOnError
    );
  }

  const handleCloseDeleteAccess = () => {
    setItemToDelete(null);
    setOpenDeleteDialog(false);
  };

  return (
    <div>
      <ToastContainer hideProgressBar={true}></ToastContainer>
      <Typography className={classes.title} gutterBottom component="h2">
        Seleccionar Acceso
      </Typography>
      <div className={classes.mainContent}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple" />
          <Select
            native
            value={selectedLockKey}
            onChange={handleNewLockSelected}
            labelWidth={labelWidth}
            inputProps={{
              lock: "",
              id: "age-native-helper",
            }}
            classes={{
              root: classes.selected,
            }}
          >
            {locks &&
              locks.map((newLock, index) => (
                <option
                  className={classes.option}
                  key={newLock.key}
                  value={newLock.key}
                >
                  {newLock.deviceName}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
      {isLoading ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinnerStyle} />
        </div>
      ) : (
        <div>
          {isLockWiFi ? (
            accessList && accessList.length > 0 ? (
              <div>
                <Typography
                  className={classes.title}
                  gutterBottom
                  component="h2"
                >
                  Llaves virtuales
                </Typography>
                <AccessItemListView
                  clasName={classes.accessList}
                  list={accessList}
                  device={currentLock}
                  isLoading={isLoading}
                  handleItemClick={() => {}}
                  handleDeleteAction={(e, device, access) => {
                    setItemToDelete(access);
                    setOpenDeleteDialog(true);
                  }}
                  handleOnLinkCopied={() => toast.success("Link copiado")}
                />
              </div>
            ) : (
              <div className={classes.noWiFiContainer}>
                <Typography className={classes.noWiFiText} component="p">
                  Tu dipositivo{" "}
                  {currentLock ? '"' + currentLock.deviceName + '"' : ""} no
                  posee ninguna llave virtual.
                </Typography>
                <Typography className={classes.noWiFiContact} component="p">
                  Presiona el "+" para crear tu primer link de acceso.
                </Typography>
                <img
                  className={classes.noWiFiImage}
                  alt=""
                  src={NoResourcesImage}
                />
              </div>
            )
          ) : (
            <div className={classes.noWiFiContainer}>
              <Typography className={classes.noWiFiText} component="p">
                Tu dipositivo{" "}
                {currentLock ? '"' + currentLock.deviceName + '"' : ""} es
                Bluetooth.
              </Typography>
              <Typography className={classes.noWiFiContact} component="p">
                Contacta al equipo de Cittyo para actualizar tu sistema y así
                utilizar el potencial de nuestra plataforma WiFi.
              </Typography>
              <img
                className={classes.noWiFiImage}
                alt=""
                src={ContactUsAsset}
              />
            </div>
          )}
        </div>
      )}
      <Tooltip title="Agregar llave virtual" placement="left">
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => togglePermissionDrawer(true)}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <KeyPermissions
        open={openDrawer}
        togglePermissionDrawer={togglePermissionDrawer}
        currentLock={currentLock}
        handleOnSuccess={handleOnAccessCreated}
      />
      <DeleteAccessDialog
        handleClose={handleCloseDeleteAccess}
        open={openDeleteDialog}
        handleDeleteItem={(e, access) => handleDeleteAction(access)}
        itemToDelete={itemToDelete}
      />
    </div>
  );
};

class KeysScreen extends Component {
  render(props) {
    return <KeysSection {...this.props} />;
  }

  componentDidMount() {
    this.props.getUserLocks();
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    lockInfo: state[NAME].lockInfo,
    userLocks: state[NAME].userLocks,
    accessList: state[NAME].accessList,
    isLoading: state[NAME].isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserLocks: () => dispatch(getUserLocks()),
    getLockInformation: (lockId) => dispatch(getLockInformation(lockId)),
    getDeviceAccess: (serialNumber, handleAPIOnError) =>
      dispatch(getDeviceAccess(serialNumber, handleAPIOnError)),
    removeDeviceAccess: (
      serialNumber,
      access,
      handleAPISuccess,
      handleAPIOnError
    ) =>
      dispatch(
        removeDeviceAccess(
          serialNumber,
          access,
          handleAPISuccess,
          handleAPIOnError
        )
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeysScreen);
