// VENDOR
import { combineReducers } from "redux";
// CONTAINERS
import { NAME as LOGIN_NAME } from "../../containers/SignIn/constants";
import authReducer from "../../containers/SignIn/reducer";

import { NAME as LOCKS_NAME } from "../../containers/Locks/constants";
import locksReducer from "../../containers/Locks/reducer";

import { NAME as HOME_NAME } from "../../containers/Home/constants";
import homeReducer from "../../containers/Home/reducer";

import { NAME as ACTIVITY_NAME } from "../../containers/Activity/constants";
import activityReducer from "../../containers/Activity/reducer";

import { NAME as PERMISSION_NAME } from "../../containers/Permission/constants";
import permissionReducer from "../../containers/Permission/reducer";

import { NAME as LOCK_USERS_NAME } from "../../containers/Users/constants";
import lockUsersReducer from "../../containers/Users/reducer";

import { NAME as KEYS_NAME } from "../../containers/Keys/constants";
import lockKeysReducer from "../../containers/Keys/reducer";

import { NAME as KEY_NAME } from '../../containers/KeyAccess/constants';
import  keyAccessReducer from '../../containers/KeyAccess/reducer';

import { NAME as KEY_DASHBOARD_CREATE_ACCESS } from '../../containers/KeyPermissions/constants';
import  createAccessReducer from '../../containers/KeyPermissions/reducer';

import { NAME as CONTROL_POINT } from '../../containers/ControlPoint/constants';
import  controlPointReducer from '../../containers/ControlPoint/reducer';

import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  [LOGIN_NAME]: authReducer,
  [LOCKS_NAME]: locksReducer,
  [HOME_NAME]: homeReducer,
  [ACTIVITY_NAME]: activityReducer,
  [PERMISSION_NAME]: permissionReducer,
  [LOCK_USERS_NAME]: lockUsersReducer,
  [KEYS_NAME]: lockKeysReducer,
  [KEY_NAME]: keyAccessReducer,
  [KEY_DASHBOARD_CREATE_ACCESS]: createAccessReducer,
  [CONTROL_POINT]: controlPointReducer,
  firebase: firebaseReducer, //auth
});

export default rootReducer;
