import {
  GET_USER_LOCKS,
  GET_LOCK_USERS,
  REMOVE_PERMISSION_FOR_USER,
} from "./actionTypes";
import Firebase from "firebase/compat/app";
import { sortUsersByRole, sortUsersDisplayedByName } from "../../helpers/SortHelpers";

export const getUserLocks = () => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_USER_LOCKS,
      data: {
        userLocks: [],
      },
    };
    const firebase = getFirebase();

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userDoorLocksRef = Firebase.database().ref(
          "/userDoorLocks/" + user.uid
        );
        userDoorLocksRef.on("value", (snapshot) => {
          var state = {};

          snapshot.forEach(function (childSnapshot) {
            const key = childSnapshot.key;
            const value = snapshot.child(key).child("role").val();
            //add only if user is owner or admin
            if (value === "OWNER" || value === "ADMIN") {
              state[key] = snapshot.child(key).val();
              state[key].key = key;
            }
          });

          const locks = sortLocks(state);
          action.data.userLocks = locks;
          dispatch(action);
        });
      } else {
        // No user is signed in.
        dispatch(action);
      }
    });
  };
};

function sortLocks(locks) {
  var l = Object.values(locks);
  if (l !== undefined && l.length > 1) {
    l.sort(function (a, b) {
      if (a.deviceName === undefined) {
        a.deviceName = "";
      }
      if (b.deviceName === undefined) {
        b.deviceName = "";
      }
      return a.deviceName.localeCompare(b.deviceName);
    });
  }

  if (l === undefined) {
    l = [];
  }
  return l;
}

export const getLockUsers = (lockId) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_LOCK_USERS,
      data: {
        lockUsers: [],
        isLoading: true,
      },
    };
    dispatch(action);
    let doorLockUsersRef = Firebase.database().ref("/doorLockUsers/" + lockId);
    doorLockUsersRef.on("value", (snapshot) => {
      var state = {};
      snapshot.forEach(function (childSnapshot) {
        const key = childSnapshot.key;
        state[key] = snapshot.child(key).val();
        state[key].key = key;
      });

      var users = sortUsersDisplayedByName(state);
      users = sortUsersByRole(users);
      action.data.lockUsers = users;
      action.data.isLoading = false;
      dispatch(action);
    });
  };
};


export const removePermissionForUser = (lockId, userId) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: REMOVE_PERMISSION_FOR_USER,
    };

    var updates = {};

    updates["/doorLockUsers/" + lockId + "/" + userId] = null;
    updates["/userDoorLocks/" + userId + "/" + lockId] = null;

    const params = JSON.parse(JSON.stringify(updates));

    Firebase.database()
      .ref()
      .update(params, function (error) {
        if (error) {
          console.log("Error: ", error);
        }
        dispatch(action);
      });
  };
};
