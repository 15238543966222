import React, { PureComponent } from 'react';

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const pinStyle = {
    cursor: 'pointer',
    fill: '#d00',
    stroke: 'none'
};

export default class Pin extends PureComponent {

    render() {
        const { size = 20, onClick } = this.props;

        return (

            <svg xmlns="http://www.w3.org/2000/svg"
                width="51"
                height="45"
                viewBox="0 0 51 45"
                style={{ ...pinStyle, transform: `translate(${-size / 2}px,${-size}px)` }}
                onClick={onClick}>
                <defs>
                    <filter id="a" width="102.2%" height="103.5%" x="-1.1%" y="-1.2%" filterUnits="objectBoundingBox">
                        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
                        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="1" />
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
                        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                        <feMerge>
                            <feMergeNode in="shadowMatrixOuter1" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    <linearGradient id="b" x1="78.866%" x2="12.801%" y1="-22.068%" y2="100%">
                        <stop offset="0%" stopColor="#404042" stopOpacity="0" />
                        <stop offset="100%" stopColor="#404042" />
                    </linearGradient>
                </defs>
                <g fill="none" fillRule="evenodd" filter="url(#a)" transform="translate(-142 -79)">
                    <path fill="url(#b)" fillRule="nonzero" d="M39.098 21c-8.785 0-20.223 3.752-25.589 8.395-6.572 5.686 1.807 11.296 2.124 11.528.102.038.204.077.35.077.147 0 .338-.039.529-.077.927-.232 21.975-5.648 28.771-11.528 5.32-4.604 2.6-8.395-6.185-8.395z" opacity=".418" transform="translate(144 80)" />
                    <path fill="#FFF" fillRule="nonzero" d="M160 80c-8.848 0-16 7.505-16 16.79 0 11.372 14.968 22.591 15.558 23.055.147.078.295.155.442.155.147 0 .295-.077.442-.155.664-.464 15.558-11.296 15.558-23.056C176 87.582 168.848 80 160 80z" />
                    <path fill="#52C9F4" d="M162.672 108.58l-2.036-6.056a.19.19 0 0 0-.178-.124h-.161a.191.191 0 0 0-.178.123l-2.108 6.054a.198.198 0 0 0 .023.177.191.191 0 0 0 .157.084l4.3.001c.063 0 .12-.032.156-.082a.198.198 0 0 0 .025-.177" />
                    <path fill="#404042" d="M162.215 92.6h-3.425a.188.188 0 0 0-.134.06.206.206 0 0 0-.055.14l-.001 8.671c0 .053.02.105.055.143.035.038.084.06.135.06l3.424-.001c.104 0 .19-.09.19-.202v-8.67a.208.208 0 0 0-.055-.142.184.184 0 0 0-.134-.059" />
                    <path fill="#52C9F4" d="M158.011 85.266l2.077 6.214c.029.077.1.127.181.127h.164c.08 0 .153-.05.183-.126l2.148-6.212a.2.2 0 0 0-.182-.268L158.195 85a.193.193 0 0 0-.158.084.202.202 0 0 0-.026.182" />
                </g>
            </svg>
        );
    }
}