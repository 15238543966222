import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  sideBar: {
    marginTop: 65,
  },
}));

const DefaultModalDialog = ({ item, title, message, cancelText, acceptText, onCancel, onAccept }) => {
  const classes = useStyles();
  return <div className={classes.root}>
    <Dialog
      open={item !== null}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title !== null ? title : ""}</DialogTitle>
      <DialogContent>
        <Typography>
          {message !== null ? message : ""}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()} color="secondary">
          {cancelText != null ? cancelText : "Cancelar"}
        </Button>
        <Button
          onClick={(e) => onAccept(e, item)}
          color="secondary"
        >
          {acceptText != null ? acceptText : "Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  </div>
};

export default DefaultModalDialog;
