const ACCESS_CODE_KEY = 'ACK'



class Session {

    static getAccessCode() {
        return sessionStorage.getItem(ACCESS_CODE_KEY);
    }

    static setAccessCode(accessCode) {
        sessionStorage.setItem(ACCESS_CODE_KEY, accessCode);
    }

    static clearAccessCode() {
        sessionStorage.removeItem(ACCESS_CODE_KEY);
    }
}

export default Session;
