import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Grid, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const DAYS = [
    {
        key: "Domingo",
        label: "D",
    },
    {
        key: "Lunes",
        label: "L",
    },
    {
        key: "Martes",
        label: "M",
    },
    {
        key: "Miercoles",
        label: "M",
    },
    {
        key: "Jueves",
        label: "J",
    },
    {
        key: "Viernes",
        label: "V",
    },
    {
        key: "Sabado",
        label: "S",
    },
];


const useStyles = makeStyles((theme) => ({
    dow: {
        marginTop: 10,
        marginBottom: 10,
        justifyContent: "center",
        width: "100%",
    },
    selected: {
        margin: 1,
        width: 20,
        height: 20,
        backgroundColor: "#52c9f4",
        border: "1px solid",
        borderColor: "#52c9f4",
        borderRadius: "50%",
        aspectRatio: 1 / 1,
        textAlign: "center"
    },
    notSelected: {
        margin: 1,
        width: 20,
        height: 20,
        alignItem: "center",
        border: "1px solid",
        borderColor: "#52c9f4",
        borderRadius: "50%",
        aspectRatio: 1 / 1,
        textAlign: "center"
    },
    dayTextSelected: {
        color: "#fff",
        fontSize: 12,
        fontWeight: "bold"
    },
    dayTextNotSelected: {
        color: "#52c9f4",
        fontSize: 12,
        fontWeight: "bold"
    }
}));

const ScheduleDays = ({ days }) => {
    const classes = useStyles();
    return (
        <div>
            <Grid container 
                className={classes.dow}
            >
                {DAYS.map((day, index) => (
                    <Grid item
                        className={days.find(element => element === index) >= 0 ? classes.selected : classes.notSelected}
                        key={day.key + index}
                        value={index}
                    >
                        <Typography className={days.find(element => element === index) >= 0 ? classes.dayTextSelected : classes.dayTextNotSelected}>{ day.label }</Typography>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ScheduleDays;
