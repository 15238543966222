import {
  GET_USER_LOCKS,
  GET_USER_BY_EMAIL,
  GET_LOCK_USERS,
  CREATE_ACCESS,
  GET_LOCK_INFORMATION,
  RESET_STORE,
} from "./actionTypes";
import Firebase from "firebase/compat/app";

export const getUserLocks = () => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_USER_LOCKS,
      data: {
        userLocks: [],
      },
    };
    const firebase = getFirebase();

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userDoorLocksRef = Firebase.database().ref(
          "/userDoorLocks/" + user.uid
        );
        userDoorLocksRef.on("value", (snapshot) => {
          var state = {};

          snapshot.forEach(function (childSnapshot) {
            const key = childSnapshot.key;
            const value = snapshot.child(key).child("role").val();

            if (value === "OWNER" || value === "ADMIN") {
              state[key] = snapshot.child(key).val();
              state[key].key = key;
            }
          });
          action.data.userLocks = state;
          dispatch(action);
        });
      } else {
        // No user is signed in.
        dispatch(action);
      }
    });
  };
};

export const getUserByEmail = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_USER_BY_EMAIL,
      data: {
        newUser: null,
        isLoading: true,
      },
    };

    dispatch(action);
    const query = Firebase.database()
      .ref("/users/")
      .orderByChild("email")
      .equalTo(email);

    query.once("value", (snapshot) => {
      snapshot.forEach(function (childSnapshot) {
        var childKey = childSnapshot.key;
        var childData = JSON.parse(JSON.stringify(childSnapshot.val()));
        childData.key = childKey;
        action.data.newUser = childData;
      });

      action.data.isLoading = false;
      dispatch(action);
    });
  };
};

export const removeUserFromRedux = () => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_USER_BY_EMAIL,
      data: {
        newUser: null,
        isLoading: false,
      },
    };

    dispatch(action);
  };
};

export const getLockUsers = (lockId) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_LOCK_USERS,
      data: {
        lockUsers: [],
      },
    };
    let doorLockUsersRef = Firebase.database().ref("/doorLockUsers/" + lockId);
    doorLockUsersRef.on("value", (snapshot) => {
      var state = {};

      snapshot.forEach(function (childSnapshot) {
        const key = childSnapshot.key;
        state[key] = snapshot.child(key).val();
        state[key].key = key;
      });

      action.data.lockUsers = state;
      dispatch(action);
    });
  };
};

export const getLockInformation = (lockId) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_LOCK_INFORMATION,
      data: {
        lockInfo: null,
      },
    };

    let lockRef = Firebase.database().ref("/doorLocks/" + lockId);

    lockRef.on("value", (snapshot) => {
      var lock = snapshot.val();
      lock.key = lockId;

      action.data.lockInfo = lock;
      dispatch(action);
    });
  };
};

export const createAccess = (users, lock, permissionInfo) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: CREATE_ACCESS,
      data: {
        isPermissionLoading: true,
        error: null,
        isPermissionCreated: false,
      },
    };
    dispatch(action); //dispatch loading.

    var updates = {};

    var guestPermission = null;
    var guestPermissionKey = null;
    if (permissionInfo.role == "GUEST_VISITOR") {
      guestPermissionKey = Firebase.database()
        .ref()
        .child("guestPermissions")
        .push().key;

      guestPermission = {
        fromTimeStamp: permissionInfo.fromTimeStamp,
        toTimeStamp: permissionInfo.toTimeStamp,
        securityInviteHash: permissionInfo.securityInviteHash,
        role: permissionInfo.role,
      };

      updates["/guestPermissions/" + guestPermissionKey] = guestPermission;
    }

    if (permissionInfo.role == "GUEST_WORKER") {
      guestPermissionKey = Firebase.database()
        .ref()
        .child("guestPermissions")
        .push().key;

      guestPermission = {
        fromTimeOfDay: permissionInfo.fromTimeOfDay,
        toTimeOfDay: permissionInfo.toTimeOfDay,
        monday: permissionInfo.monday,
        tuesday: permissionInfo.tuesday,
        wednesday: permissionInfo.wednesday,
        thursday: permissionInfo.thursday,
        friday: permissionInfo.friday,
        saturday: permissionInfo.saturday,
        sunday: permissionInfo.sunday,
        securityInviteHash: permissionInfo.securityInviteHash,
        role: permissionInfo.role,
      };

      updates["/guestPermissions/" + guestPermissionKey] = guestPermission;
    }

    var lockUserData = {};
    var userLockData = {};
    for (var k in users) {
      lockUserData = {
        createdTimeStamp: Firebase.database.ServerValue.TIMESTAMP,
        firstName: users[k].firstName,
        lastName: users[k].lastName,
        invitationStatus: "ACCEPTED",
        role: permissionInfo.role,
        userPictureUrl: users[k].userPictureUrl,
      };

      userLockData = {
        deviceName: lock.deviceName,
        devicePictureUrl: lock.devicePictureUrl,
        doorLockAddress: lock.doorLockAddress,
        invitationStatus: "ACCEPTED",
        role: permissionInfo.role,
      };

      if (guestPermission != null && guestPermissionKey != null) {
        lockUserData.guestPermissionId = guestPermissionKey;
        userLockData.guestPermissionId = guestPermissionKey;
      }
      updates["/doorLockUsers/" + lock.key + "/" + users[k].key] = lockUserData;
      updates["/userDoorLocks/" + users[k].key + "/" + lock.key] = userLockData;
    }

    const params = JSON.parse(JSON.stringify(updates));

    Firebase.database()
      .ref()
      .update(params, function (error) {
        if (error) {
          // The write failed...
          action.data.isPermissionCreated = false;
          action.data.error = error;
        } else {
          // Data saved successfully!
          dispatch(action); //dispatch loading.
          action.data.error = null;
          action.data.isPermissionCreated = true;
        }

        action.data.isPermissionLoading = false;
        dispatch(action);
      });
  };
};

export const resetPermissionStore = () => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: RESET_STORE,
    };
    dispatch(action);
  };
};
