import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route } from "react-router-dom";

import About from "../containers/About";
import Locks from "../containers/Locks";
import Permission from "../containers/Permission";
import Activity from "../containers/Activity";
import Account from "../containers/Account";
import Users from "../containers/Users";
import KeysScreen from "../containers/Keys";
import ControlPoints from "../containers/ControlPoint";

import MenuContent from "./navigation/MenuContent";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fafafc",
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 50px)",
  },
  menu: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flex: 4,
    overflowY: "scroll",
  },
}));

export default function Container(props) {
  const { userInfo, isLoading } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.menu}>
        <MenuContent userInfo={userInfo}></MenuContent>
      </div>
      <div className={classes.content}>
        <Route path="/home/activity" component={Activity} />
        <Route
          path="/home/account"
          component={() => (
            <Account userInfo={userInfo} isLoading={isLoading} />
          )}
        />
        <Route path="/home/locks" component={Locks} />
        <Route path="/home/about" component={About} />
        <Route path="/home/users" component={Users} />
        <Route path="/home/permissions" component={Permission} />
        <Route path="/home/keys" component={KeysScreen} />
        {/* <Route path="/home/control-points" component={ControlPoints} /> */}
        <Redirect to="/home/locks" />
      </div>
    </div>
  );
}
