import { GET_USER_LOCKS } from "./actionTypes";
import Firebase from "firebase/compat/app";

export const getUserLocks = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const action = {
      type: GET_USER_LOCKS,
      data: {
        locks: [],
        isLoading: true,
      },
    };

    dispatch(action);

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let ref2 = Firebase.database().ref("/userDoorLocks/" + user.uid);
        ref2.on("value", (snapshot) => {
          var state = snapshot.val();
          if (state) {
            var count = Object.values(state).length;

            snapshot.forEach(function (childSnapshot) {
              const key = childSnapshot.key;
              var starCountRef = Firebase.database()
                .ref("/activity/" + key)
                .orderByChild("timeStamp")
                .limitToLast(1);

              var lock = childSnapshot.val();
              if (lock.role === "ADMIN" || lock.role === "OWNER") {
                starCountRef.on("value", function (childChild) {
                  if (childChild.val() !== null) {
                    var lockState = Object.values(childChild.val());

                    var ts = new Intl.DateTimeFormat("es-AR", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    }).format(lockState[0].timeStamp);
                    state[key].stateTimeStamp = ts;
                    state[key].stateLock = lockState[0].stateLock;
                    state[key].stateBattery = lockState[0].stateBattery;
                    state[key].stateUserEmail = lockState[0].userEmail;
                    state[key].key = key;
                    action.data.locks = state;
                  }

                  action.data.isLoading = false;
                  count--;
                  if (count <= 0) {
                    dispatch(action);
                  }
                });
              } else {
                action.data.isLoading = false;
                count--;
                if (count <= 0) {
                  action.data.locks = state;
                  dispatch(action);
                }
              }
            });
          } else {
            action.data.locks = [];
            action.data.isLoading = false;
            dispatch(action);
          }
        });
      } else {
        // No user is signed in.
        action.data.isLoading = false;
        dispatch(action);
      }
    });
  };
};
