import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Divider, Grid, Typography } from "@material-ui/core";
import IconAdministrator from "../../assets/permission/administrator_icon.svg";
import IconCheckmark from "../../assets/permission/checkmark.svg";

const useStyles = makeStyles((theme) => ({
  adminCard: {
    marginLeft: "50px",
    marginRight: "50px",
    backgroundColor: "white",
    padding: "5px",
  },
  cardTitleIcon: {
    widh: "30px",
    height: "30px",
    marginTop: "10px",
    marginLeft: "10px",
    top: "0px",
    justifyContent: "center",
  },
  checkMarkIconGrid: {
    display: "flex",
    justifyContent: "center",
  },
  permissionCardTitle: {
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    paddingTop: "10px",
    paddingLeft: "10px",
    marginBottom: "0px",
  },
  permissionCardDescription: {
    color: "black",
    fontSize: "10pt",
    paddingLeft: "20px",
    marginBottom: "10px",
    paddingBottom: "10px",
  },
  checkMarkIcon: {
    width: "20px",
    height: "20px",
    top: "0px",
    justifyContent: "center",
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
}));

// This kind of access never expires
export default function AdminAccess() {
  const classes = useStyles();

  return (
    <Card className={classes.adminCard}>
      <Grid container>
        <Grid item xs={1} md={1} className={classes.checkMarkIconGrid}>
          <img
            className={classes.cardTitleIcon}
            alt=""
            src={IconAdministrator}
          />
        </Grid>
        <Grid item xs={11} md={11}>
          <Typography className={classes.permissionCardTitle} component="h2">
            Administrador
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={11} md={11}>
          <Typography
            className={classes.permissionCardDescription}
            component="h3"
          >
            Acceso total y pueden generar permisos a nuevos invitados.
          </Typography>
        </Grid>
        <Grid item xs={1} md={1}>
          <img className={classes.checkMarkIcon} alt="" src={IconCheckmark} />
        </Grid>
      </Grid>
    </Card>
  );
}
