import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: 10,
        marginTop: 10,
        justifyContent: "center",
        width: "100%",
    },
    container: {
        padding: 5,
        backgroundColor: "#f2f2f2",
        justifyContent: "center",
        borderRadius: 5
    },
    title: {
        margin: 5,
        marginTop: 2,
        marginBottom: 0,
        justifyContent: "center",
        color: "#52c9f4",
        fontSize: 12,
        fontWeight: "bold"
    },
    text: {
        margin: 5,
        marginTop: 0,
        marginBottom: 2,
        justifyContent: "center",
        color: "#000",
        fontSize: 12,
        fontWeight: "bold"
    },
}));


const ScheduleHours = ({ from, to }) => {
    const classes = useStyles();

    // TODO: Unify this code with the AccessItemListView and the UserPermissions
    const getTime = (timeInMinutes) => {
        const hours = parseInt(Math.floor(timeInMinutes / 60));
        const minutes = parseInt((timeInMinutes / 60 - hours) * 60);
        const time =
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0");
        return time;
    };

    return (
        <div>
            <Grid container
                className={classes.section}
            >
                <Grid item xs={4} className={classes.container}>
                    <Typography className={classes.title}>DESDE</Typography>
                    <Typography className={classes.text}>{getTime(from)}</Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4} className={classes.container}>
                    <Typography className={classes.title}>HASTA</Typography>
                    <Typography className={classes.text}>{getTime(to)}</Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default ScheduleHours;
