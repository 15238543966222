import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { ToastContainer } from "react-toastify";
import { FormControl, InputLabel, Select, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CreateControlPointDialog from "../../components/controlPoint/CreateControlPointDialog";
import {
  createControlPoint,
  getControlPoints,
  getControlPointUsers,
  getControlPointActivity,
  updateControlPoint,
  deleteControlPoint,
  deleteControlPointUsersByEmails,
  addUsersToControlPoint,
  generateQRToken
} from "./actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { NAME } from "./constants";
import { validationLatitudeLongitude } from "validation-latitude-longitude";
import ControlPointUsers from "../../components/controlPoint/ControlPointUsers";
import ControlPointInfo from "../../components/controlPoint/ControlPointInfo";
import CircularProgress from "@material-ui/core/CircularProgress";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import DefaultModalDialog from "../../components/DefaultModalDialog";
import ControlPointPermissions from "../../components/controlPoint/ControlPointPermissions";
import ControlPointActivity from "../../components/controlPoint/ControlPointActivity";
import ControlPointManageQR from "../../components/controlPoint/ControlPointManageQR";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ padding: 0, margin: 0, marginTop: 30 }}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#52c9f4",
    },
    secondary: {
      main: "#52c9f4",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  title: {
    backgroundColor: "#fafafc",
    textTransform: "capitalize",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "14pt",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  createControlPointButton: {
    float: "right",
    marginRight: 50,
    alignSelf: "center",
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#55c9f4!important",
      color: "#ffffff!important",
    },
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  usersList: {
    marginTop: 30,
  },
}));

const ControlPointSection = ({ createControlPoint,
  getControlPoints,
  getControlPointUsers,
  updateControlPoint,
  deleteControlPoint,
  deleteControlPointUsersByEmails,
  addUsersToControlPoint,
  getControlPointActivity,
  generateQRToken,
  controlPoints,
  users,
  activity,
  isLoading }) => {
  const classes = useStyles();
  const [isControlPointDialogOpen, openCreateControlPointDialog] = React.useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const inputLabel = React.useRef(null);
  const [controlPoint, selectControlPoint] = React.useState(null);
  const [currentTab, selectTab] = React.useState(0);

  // Dialogs
  const [userToRemove, setUserToRemove] = React.useState(null);
  const [openDeleteControlPoint, setDeleteControlPoint] = React.useState(null);

  // Drawer 
  const [openUsersDrawer, setOpenUsersDrawer] = React.useState(false);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function tabsProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  React.useEffect(() => {
    if (controlPoints !== null && controlPoints.length > 0 && controlPoint === null) {
      selectControlPoint(controlPoints[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlPoints]);

  React.useEffect(() => {
    if (controlPoint !== null) {
      getControlPointUsers(controlPoint.key);
      getControlPointActivity(controlPoint.key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlPoint]);

  React.useEffect(() => {
    if (inputLabel.current !== null) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, [inputLabel]);

  async function createNewControlPoint(controlPoint) {
    if (validationLatitudeLongitude.longitude(controlPoint.longitude) &&
      validationLatitudeLongitude.latitude(controlPoint.latitude)) {
      createControlPoint(controlPoint, handleAPISucess, handleAPIOnError);
    } else {
      toast.error("Please enter valid latitude and longitude values");
    }
  }

  function handleAPIOnError(error) {
    toast.error(error);
  }

  function handleAPISucess(data) {
    openCreateControlPointDialog(false);
    getControlPoints();
  }

  const hanldeControlPointSelected = (event) => {
    event.persist();
    const controlPointKey = event.target.value;
    const result = controlPoints.filter((cp) => cp.key === controlPointKey);
    selectControlPoint(result[0]);
  };

  function handleUpdateControlPoint(controlPoint) {
    updateControlPoint(controlPoint, handleAPISucess, handleAPIOnError);
  }

  function handleAddMembersToControlpoint(payload) {
    addUsersToControlPoint(controlPoint.key, payload, () => {
      setOpenUsersDrawer(false);
      getControlPointUsers(controlPoint.key);
    }, (error) => handleAPIOnError(error));
  }

  function onUpdateQRToken() {
    generateQRToken(controlPoint.key, (updatedControlPoint) => {
      getControlPoints();
    }, (error) => handleAPIOnError(error));
  }

  return (
    <React.Fragment>
      <ToastContainer hideProgressBar={true}></ToastContainer>
      <CreateControlPointDialog open={isControlPointDialogOpen} handleClose={() => openCreateControlPointDialog(false)} handleCreate={(controlPoint) => createNewControlPoint(controlPoint)}></CreateControlPointDialog>
      <ThemeProvider theme={customTheme}>
        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <Typography className={classes.title} style={{ flexGrow: 1 }} component="h2">
            Seleccionar punto de control
          </Typography>
          <Button
            className={classes.createControlPointButton}
            onClick={() => openCreateControlPointDialog(true)}
          >
            <AddIcon></AddIcon>
            Crear CP
          </Button>
        </div>

        {controlPoint == null ? <div></div> :
          <div className={classes.mainContent}>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple" />
              <Select
                native
                value={controlPoint.key}
                onChange={hanldeControlPointSelected}
                labelWidth={labelWidth}
                inputProps={{
                  id: "age-native-helper",
                }}
                classes={{
                  root: classes.selected,
                }}
              >
                {controlPoints &&
                  controlPoints.map((option, index) => (
                    <option
                      className={classes.option}
                      key={index}
                      value={option.key}
                    >
                      {option.name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <AppBar style={{ marginTop: 20, background: "white", flexDirection: "row" }} elevation={0} position="static">
              <Tabs style={{ flexGrow: 1 }} value={currentTab} onChange={(e, v) => { selectTab(v) }}>
                <Tab label="Punto de control" {...tabsProps(0)} />
                <Tab label="Usuarios" {...tabsProps(1)} />
                <Tab label="Actividad" {...tabsProps(2)} />
                <Tab label="QR" {...tabsProps(2)} />
              </Tabs> {
                isLoading ?
                  <div style={{ alignSelf: "center", margin: 5, marginRight: 10 }}>
                    <CircularProgress size={30} />

                  </div> : null
              }

            </AppBar>
            <TabPanel value={currentTab} index={0}>
              <ControlPointInfo
                controlPoint={controlPoint}
                handleUpdateControlPoint={handleUpdateControlPoint}
                handleDeleteControlPoint={() => setDeleteControlPoint(true)}
              ></ControlPointInfo>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <ControlPointUsers
                clasName={classes.usersList}
                users={users}
                currentUserRole={controlPoint !== null ? controlPoint.role : ""}
                handleItemClick={() => { }}
                onRemoveUser={(user) => {
                  setUserToRemove(user)
                }
                }
                onOpenDrawer={() => setOpenUsersDrawer(true)}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <ControlPointActivity
                isLoading={isLoading}
                activity={activity}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <ControlPointManageQR
                controlPoint={controlPoint}
                onUpdateQRToken={onUpdateQRToken}>
              </ControlPointManageQR>

            </TabPanel>
          </div>
        }
      </ThemeProvider>
      <DefaultModalDialog
        item={userToRemove}
        title="Eliminar usuario"
        message={"Esta seguro que desea eliminar a " + (userToRemove != null ? userToRemove.email : "") + " del punto de control ?"}
        onCancel={() => {
          setUserToRemove(null)
        }}
        onAccept={(e, item) => {
          deleteControlPointUsersByEmails(controlPoint.key,
            [item.email],
            () => {
              // Refetch users after user was removed
              getControlPointUsers(controlPoint.key);
              // Close dialog
              setUserToRemove(null);
            },
            handleAPIOnError)
        }
        }
      ></DefaultModalDialog>
      <DefaultModalDialog
        item={openDeleteControlPoint}
        title="Eliminar Punto de Control"
        message={"Esta seguro que desea eliminar el punto de control " + (controlPoint != null ? controlPoint.name : "") + "?. Se eliminará todos los registros relacionados a este punto de control."}
        onCancel={() => {
          setDeleteControlPoint(null);
        }}
        onAccept={(e, item) => {
          deleteControlPoint(controlPoint.key,
            () => {
              // Request control points
              getControlPoints();
              // Close Delete control point dialog
              setDeleteControlPoint(null);
              // Clean current control point
              selectControlPoint(null);
            },
            handleAPIOnError)
        }
        }
      ></DefaultModalDialog>
      <ControlPointPermissions
        open={openUsersDrawer}
        controlPoint={controlPoint}
        togglePermissionDrawer={setOpenUsersDrawer}
        onAddMembersToControlPoint={handleAddMembersToControlpoint}
      />
    </React.Fragment>
  );
};

class ControlPoint extends Component {
  render(props) {
    return (
      <ControlPointSection {...this.props}
        createControlPoint={this.props.createControlPoint}
        getControlPoints={this.props.getControlPoints}
        getControlPointUsers={this.props.getControlPointUsers}
        updateControlPoint={this.props.updateControlPoint}
        deleteControlPoint={this.props.deleteControlPoint}
        addUsersToControlPoint={this.props.addUsersToControlPoint}
        deleteControlPointUsersByEmails={this.props.deleteControlPointUsersByEmails}
        getControlPointActivity={this.props.getControlPointActivity}
        generateQRToken={this.props.generateQRToken} />
    );
  }

  componentDidMount() {
    this.props.getControlPoints();
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state[NAME].isLoading,
    controlPoints: state[NAME].controlPoints,
    users: state[NAME].users,
    activity: state[NAME].activity,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createControlPoint: (controlPoint, handleAPISucess, handleAPIOnError) =>
      dispatch(createControlPoint(controlPoint, handleAPISucess, handleAPIOnError)),
    getControlPoints: (handleAPIOnError) =>
      dispatch(getControlPoints(handleAPIOnError)),
    getControlPointUsers: (controlPointId, handleAPIOnError) =>
      dispatch(getControlPointUsers(controlPointId, handleAPIOnError)),
    updateControlPoint: (controlPoint, handleAPISucess, handleAPIOnError) =>
      dispatch(updateControlPoint(controlPoint, handleAPISucess, handleAPIOnError)),
    deleteControlPoint: (controlPointId, handleAPISucess, handleAPIOnError) =>
      dispatch(deleteControlPoint(controlPointId, handleAPISucess, handleAPIOnError)),
    deleteControlPointUsersByEmails: (controlPointId, usersEmails, handleAPISucess, handleAPIOnError) =>
      dispatch(deleteControlPointUsersByEmails(controlPointId, usersEmails, handleAPISucess, handleAPIOnError)),
    addUsersToControlPoint: (controlPointId, payload, handleAPISucess, handleAPIOnError) =>
      dispatch(addUsersToControlPoint(controlPointId, payload, handleAPISucess, handleAPIOnError)),
    getControlPointActivity: (controlPointId, handleAPIOnError) =>
      dispatch(getControlPointActivity(controlPointId, handleAPIOnError)),
    generateQRToken: (controlPointId, handleAPIOnError) =>
      dispatch(generateQRToken(controlPointId, handleAPIOnError)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlPoint);
