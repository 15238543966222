import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  sideBar: {
    marginTop: 65,
  },
}));

const DeleteAccessDialog = (props) => {
  const classes = useStyles();
  const { handleClose, open, itemToDelete, handleDeleteItem } = props;
  return itemToDelete === null || itemToDelete === undefined ? (
    <div></div>
  ) : (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Eliminar permiso</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Esta seguro que desea eliminar este permiso ?{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={(e) => handleDeleteItem(e, itemToDelete)}
            color="secondary"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAccessDialog;
