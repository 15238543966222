import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { FormControl, Grid, TextField } from "@material-ui/core";
import TimezoneSelect, { allTimezones } from 'react-timezone-select'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  sideBar: {
    marginTop: 65,
  },
  formItemGrid: {
    display: "flex",
    justifyContent: "center",
  },
  formItemSection: {
    marginTop: "20px",
    marginBottom: "10px",
    minWidth: "250px",
    width: "100%",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  textField: {
    width: "80%",
    backgroundColor: "white",
  },
}));

const controlPointInitialState = {
  name: "",
  latitude: null,
  longitude: null,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};


const CreateControlPointDialog = (props) => {
  const classes = useStyles();
  const { open, handleClose, handleCreate } = props;
  const [controlPoint, setControlPoint] = React.useState(controlPointInitialState);
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );


  function handleChangeTextFieldInformation(e) {
    if (controlPoint.hasOwnProperty(e.target.id)) {
      controlPoint[e.target.id] = e.target.value;
    }
  }

  function handleTimeZoneChange(timezone) {
    if (controlPoint.hasOwnProperty('timeZone')) {
      controlPoint['timeZone'] = timezone.value;
    }
    setSelectedTimezone(timezone);
  }

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Crear Punto de Control</DialogTitle>
        <DialogContent>
          <Typography>
            Los puntos de control son los dispositivos en los cuales<br></br>los colaboradores podran registrar sus horarios de ingreso y egreso.
          </Typography>

          <FormControl variant="outlined" className={classes.formControl}>
            <Grid container >
              <Grid item xs={12} md={12} className={classes.formItemGrid}>
                <TextField
                  className={classes.textField}
                  inputProps={{ maxLength: 50, minlenght: 6 }}
                  required
                  margin="dense"
                  id="name"
                  label="Nombre"
                  type="text"
                  variant="outlined"
                  defaultValue={""}
                  color="secondary"
                  fullWidth
                  onChange={handleChangeTextFieldInformation}
                />

              </Grid>
              <Grid item xs={12} md={12} className={classes.formItemGrid}>

                <TextField
                  className={classes.textField}
                  inputProps={{ maxLength: 50, minlenght: 6 }}
                  required
                  pattern="[0-9]*"
                  margin="dense"
                  id="latitude"
                  label="Latitud"
                  type="nu"
                  variant="outlined"
                  defaultValue={""}
                  color="secondary"
                  fullWidth
                  onChange={handleChangeTextFieldInformation}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.formItemGrid}>

                <TextField
                  className={classes.textField}
                  inputProps={{ maxLength: 50, minlenght: 6 }}
                  required
                  margin="dense"
                  id="longitude"
                  label="Longitud"
                  type="text"
                  variant="outlined"
                  defaultValue={""}
                  color="secondary"
                  fullWidth
                  onChange={handleChangeTextFieldInformation}
                />
              </Grid>
              <Grid item xs={12} md={12} className={classes.formItemGrid}>

                <TimezoneSelect
                  className={classes.textField}

                  menuPosition="fixed"
                  value={selectedTimezone}
                  onChange={handleTimeZoneChange}
                  timezones={{
                    ...allTimezones,
                    'America/Lima': 'Pittsburgh',
                    'Europe/Berlin': 'Frankfurt',
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={(e) => handleCreate(controlPoint)}
            color="secondary"
          >
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateControlPointDialog;
