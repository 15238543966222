import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Divider, TextField, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import Cancel from '@material-ui/icons/Cancel';
import UpgradeIcon from '@material-ui/icons/Update';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MapPicker from "../map/MapPicker";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import TimezoneSelect, { allTimezones } from 'react-timezone-select'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#fafafc",
        padding: "10px!important",
        paddingBottom: "0px!important",
    },
    cardContent: {
        width: "100%",
        minWidth: "300px",
        margin: 0,
        padding: 0,
        color: "white!important",
        background: "white",
    },
    contentSection: {
        flexGrow: 1,
        margin: 0,
        padding: 0,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
    },
    stepsSection: {
        flex: "10",
    },
    stepsSectionTwo: {
        flexGrow: "1",
        flex: "1",
    },
    leftContent: {
        minWidth: "300px",
        flex: "1",
        margin: "30px",
        alignItems: "center!important",
        justifyContent: "center!important",
    },
    divider: {
        maxHeight: "2px",
        marginTop: "20px",
        marginBottom: "20px",
        opacity: "0.1",
        background: "#868689",
        height: "1px!important",
        marginLeft: "30px",
        marginRight: "30px",
    },
    rightContent: {
        minWidth: "300px",
        margin: "30px",
        flex: "1",
        color: "black",
    },
    title: {
        color: "#404042",
        fontSize: "20pt",
        fontWeight: "bold",
        margin: "0",
    },
    avatar: {
        marginTop: "50px",
        width: "180px!important",
        height: "180px!important",
        borderRadius: "50%",
        borderColor: "rgba(65,65,65,0.1)",
        borderWidth: "5px",
        borderStyle: "solid",
        textAlign: "center",
        justifyContent: "center",
        margin: "auto",
    },
    textField: {
        marginTop: 20,
        width: "100%",
        color: "black"
    },
    selectTimezoneSpinner: {
        marginTop: 20,
        width: "100%",
        color: "black",
    },
    timeZoneText: {
        marginTop: 20,
        color: 'rgb(0, 0, 0, 0.38)',
        borderColor: 'hsl(0, 0%, 80%)',
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 1,
        padding: 6,
        paddingLeft: 10
    },
    spinnerStyle: {
        textAlign: "center",
        color: "#55c9f4",
        size: 10
    },
    iconButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
    },
    cancelButton: {
        margin: 20,
        alignItems: "center",
        backgroundColor: "#ef4445",
        color: "#ffffff",
        fontWeight: "bold!important",
        textTransform: "capitalize!important",
        opacity: 1,
        "&:hover": {
            cursor: "pointer",
            opacity: 0.8,
        },
    },
    updateButton: {
        margin: 20,
        alignItems: "center",
        backgroundColor: "#def2fb",
        color: "#55c9f4",
        fontWeight: "bold!important",
        textTransform: "capitalize!important",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#55c9f4!important",
            color: "#ffffff!important",
        },
    },
}));

const EditOption = ({
    isEditMode,
    handleEditTapped,
    isLoading,
    handleUpdateControlPoint,
    handleDeleteControlPoint }) => {
    const classes = useStyles();
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {
                isEditMode ?
                    <div style={{ flexGrow: 1 }}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => handleEditTapped(false)}
                        >
                            <Cancel style={{ marginRight: 10 }} />
                            Cancelar
                        </Button>
                        <Button
                            className={classes.updateButton}
                            onClick={() => handleUpdateControlPoint()}
                        >
                            <UpgradeIcon style={{ marginRight: 10 }} />
                            Actualizar
                        </Button>
                    </div>

                    : <div style={{ flexGrow: 1 }}> <Button
                        className={classes.updateButton}
                        onClick={() => handleEditTapped(true)}
                    >
                        <Edit style={{ marginRight: 10 }} />
                        Editar
                    </Button></div>

            }
            <div style={{ float: "right" }}>
                <Button

                    className={classes.cancelButton}
                    onClick={() => handleDeleteControlPoint()}
                >
                    <DeleteIcon style={{ marginRight: 10 }} />
                    Eliminar Punto de Control
                </Button>
            </div>

        </div>

    );
};


const ControlPointInfo = ({ controlPoint, handleUpdateControlPoint, handleDeleteControlPoint }) => {
    const classes = useStyles();
    const [isEditMode, setEditMode] = React.useState(false);
    const [selectedTimezone, setSelectedTimezone] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone.value);

    const [localControlPoint, setControlPoint] = React.useState(
        controlPoint ? controlPoint : {
            name: "",
            latitude: "",
            longitude: "",
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
    );

    function handleEditTapped(value) {
        setEditMode(value);
        setControlPoint(controlPoint);
        setSelectedTimezone(controlPoint.timeZone);
    }

    function handleTimeZoneChange(timezone) {
        if (localControlPoint.hasOwnProperty('timeZone')) {
            localControlPoint['timeZone'] = timezone.value;
        }
        setSelectedTimezone(timezone.value);
    }

    function handleChange(e) {
        setControlPoint({ ...localControlPoint, [e.target.id]: e.target.value });
    }

    React.useEffect(() => {
        if (controlPoint) {
            setControlPoint(controlPoint);
            setSelectedTimezone(controlPoint.timeZone);
        }
    }, [controlPoint]);

    return (
        <div className={classes.root}>
            <ToastContainer hideProgressBar={true}></ToastContainer>
            <Card className={classes.cardContent}>
                <Grid container className={classes.contentSection}>
                    <Grid item xs={12} md={12} className={classes.stepsSection}>
                        <Grid container className={classes.stepsSectionTwo}>
                            <Grid item md={12} xs={12} lg={6} className={classes.leftContent}>
                                <TextField
                                    id="name"
                                    label="Nombre"
                                    disabled={!isEditMode}
                                    className={classes.textField}
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={
                                        localControlPoint ? localControlPoint.name : ""
                                    }

                                />

                                <TextField
                                    id="latitude"
                                    label="Latitud"
                                    disabled={!isEditMode}
                                    className={classes.textField}
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={
                                        localControlPoint ? localControlPoint.latitude : "-"
                                    }
                                />

                                <TextField
                                    id="longitude"
                                    label="Loingitude"
                                    disabled={!isEditMode}
                                    className={classes.textField}
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={
                                        localControlPoint ? localControlPoint.longitude : "-"
                                    }
                                />

                                <Grid item xs={12} md={12} className={classes.formItemGrid}>

                                    {
                                        isEditMode ?
                                            <TimezoneSelect
                                                className={classes.selectTimezoneSpinner}
                                                menuPosition="fixed"
                                                value={selectedTimezone}
                                                disabled={!isEditMode}
                                                onChange={handleTimeZoneChange}
                                                timezones={{
                                                    ...allTimezones,
                                                    'America/Lima': 'Pittsburgh',
                                                    'Europe/Berlin': 'Frankfurt',
                                                }}
                                            /> :
                                            <Typography
                                                id="timeZone"
                                                label="Timezone"
                                                className={classes.timeZoneText}>
                                                {selectedTimezone}
                                            </Typography>

                                    }

                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12} lg={6} className={classes.rightContent}>
                                <MapPicker
                                    longitude={localControlPoint ? localControlPoint.longitude : -64}
                                    latitude={localControlPoint ? localControlPoint.latitude : -31}
                                ></MapPicker>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.stepsSection}>
                        <div>
                            <Divider className={classes.firstDivider} />
                            <EditOption isEditMode={isEditMode}
                                handleEditTapped={handleEditTapped}
                                handleUpdateControlPoint={() => handleUpdateControlPoint(localControlPoint)}
                                handleDeleteControlPoint={() => handleDeleteControlPoint(controlPoint.key)}>
                            </EditOption>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};


export default (ControlPointInfo);
