import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { signIn, setLogged } from "./actions";
import { connect } from "react-redux";
import { NAME } from "./constants";
import LogoGray from "../../assets/topbar/ic_cittyo_logo_gray.svg";

const useStyles = makeStyles((theme) => ({
  box: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fafafc",
  },
  card: {
    minWidth: 400,
  },
  title: {
    maxHeight: 50,
    margin: 20,
    marginBottom: 0,
  },
  textContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  textField: {
    textAlign: "center",
    marginTop: 20,
    width: "100%",
  },
  loginButton: {
    textTransform: "capitalize!important",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
    marginRight: 25,
    width: "100%",
    height: 50,
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontSize: 18,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#55c9f4",
      color: "#ffffff",
    },
  },
  cssFocused: {
    color: "#868689!imporntat",
  },
  cssLabel: {
    color: "#868689!important",
  },
  cssOutlinedInput: {
    color: "#868689!important",
    borderColor: "#868689!important",
  },
  notchedOutline: {
    color: "#868689!important",
    borderColor: "#868689!important",
  },
  loginError: {
    fontWeight: "bold",
    color: "red",
    width: "100%",
    marginTop: 0,
    height: 30,
    fontSize: 10,
    justifyContent: "center",
    textAlign: "center",
  },
  spinnerStyle: {
    color: "#55c9f4",
    height: 50,
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
    marginTop: 10,
    marginBottom: 26,
  },
}));

var states = {
  email: "",
  password: "",
};

const SignIn = ({ authError, auth, isLoading, signIn, setLogged, history }) => {
  const classes = useStyles();

  function handleChange(e) {
    if (states.hasOwnProperty(e.target.id)) {
      states[e.target.id] = e.target.value;
    }
  }

  function buttonClickListener() {
    signIn(states);
  }

  React.useEffect(() => {
    //Redirect to home if user is logged
    if (auth && auth.uid) {
      setLogged(auth);
      let path = "/home";
      history.push(path);
    }
  }, [auth, setLogged]);

  return (
    <div className={classes.box}>
      <Card className={classes.card}>
        <CardContent>
          <img className={classes.title} alt="" src={LogoGray} />
          <div className={classes.textContainer}>
            <TextField
              required
              id="email"
              label="Email"
              type="email"
              defaultValue=""
              className={classes.textField}
              variant="outlined"
              onChange={handleChange}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
          <div className={classes.textContainer}>
            <TextField
              required
              id="password"
              label="Password"
              type="password"
              className={classes.textField}
              autoComplete="current-password"
              variant="outlined"
              onChange={handleChange}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </div>
        </CardContent>
        {isLoading ? (
          <div className={classes.spinnerContainer}>
            <CircularProgress className={classes.spinnerStyle} />
          </div>
        ) : (
          <div>
            <CardActions>
              <Button
                className={classes.loginButton}
                onClick={buttonClickListener}
              >
                Iniciar Sesión
              </Button>
            </CardActions>
          </div>
        )}
        <p className={classes.loginError}>
          {authError ? "Error: Verifique su usario y contraseña" : ""}
        </p>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isLoading: state[NAME].isLoading,
    authError: state[NAME].error,
    auth: state["firebase"].auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    setLogged: (auth) => dispatch(setLogged(auth)),
  };
}
//propiedades a guardar en el store
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
