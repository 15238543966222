import { set } from "dot-prop-immutable";

import {
  GET_CONTROL_POINTS,
  CREATE_CONTROL_POINT,
  GET_CONTROL_POINT_USERS,
  DELETE_CONTROL_POINT_USERS,
  UPDATE_CONTROL_POINT,
  DELETE_CONTROL_POINT,
  ADD_USERS_TO_CONTROL_POINT,
  GET_CONTROL_POINT_ACTIVITY,
  GENERATE_NEW_QR_TOKEN
} from "./actionTypes";

const initialState = {
  controlPoints: [],
  users: [],
  activity: [],
  isLoading: false,
  error: null,
};

const controlPointReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_CONTROL_POINTS) {
    state = set(state, "controlPoints", data.controlPoints || []);
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === CREATE_CONTROL_POINT) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === UPDATE_CONTROL_POINT) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === DELETE_CONTROL_POINT) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === GET_CONTROL_POINT_USERS) {
    if (data.users != null) {
      state = set(state, "users", data.users || []);
    }
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === DELETE_CONTROL_POINT_USERS) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === ADD_USERS_TO_CONTROL_POINT) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === GET_CONTROL_POINT_ACTIVITY) {
    if (data.activity != null) {
      state = set(state, "activity", data.activity || []);
    }
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  if (type === GENERATE_NEW_QR_TOKEN) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  return state;
};

export default controlPointReducer;
