import { SEND_ACTION } from "./actionTypes";
import { BASE_URL } from '../../utils/network';

export const sendAction = (params, token, handleOnSuccess, handleOnError) => {
  return function(dispatch, getState) {
    const action = {
      type: SEND_ACTION,
      data: {
        error: null,
        isLoading: true,
      }
    };
    dispatch(action);

    const apiUrl = BASE_URL + '/api/v1/device/action';
    const headers = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      'auth-token': token
    };

    const body = buildActionBody(params.accessUID, params.accessCode, params.actionUID);
    return fetch(apiUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: headers,
      body: body,
      // body data type must match "Content-Type" header
    }).then(response => response.json())
      .then(data => {
        //success
        if (data.error) {
          if (handleOnError) handleOnError(data.error)
          action.data.error = data.error;
        } else {
          action.data.error = null;
          if (handleOnSuccess) handleOnSuccess(data)
        }
        //store token
        action.data.isLoading = false;
        dispatch(action);

      })
      .catch( (err) => {
        //hadle error
        if (handleOnError) handleOnError(err)
        action.data.error = err;
        action.data.isLoading = false;
        dispatch(action);
    });
  }
};


function buildActionBody(accessUID, accessCode, actionUID) {
  return JSON.stringify({
    "accessUID": accessUID,
    "accessCode": accessCode,
    "actionUID": actionUID
  });
}
