// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import {
  GET_LOCK_ACTIVITY,
  GET_USER_LOCKS,
  GET_GUEST_LOCK_ACTIVITY,
} from "./actionTypes";

const initialState = {
  lockActivity: [],
  userLocks: [],
  isLoading: false,
};

const activityReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_LOCK_ACTIVITY || type === GET_GUEST_LOCK_ACTIVITY) {
    state = set(state, "lockActivity", data.lockActivity);
    state = set(state, "isLoading", data.isLoading);
  }

  if (type === GET_USER_LOCKS) {
    state = set(state, "userLocks", data.userLocks);
  }

  return state;
};

export default activityReducer;
