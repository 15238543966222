import React, { Component, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { NAME } from "./constants";
import { getUserLocks } from "./actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import LockItem from "../../components/LockItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
    padding: "40px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  title: {
    textTransform: "capitalize",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "14pt",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

const LocksSection = ({ isLoading, locks }) => {
  const classes = useStyles();
  const [userLocks, setUserLocks] = useState(Object.values(locks));
  const [adminLocks, setAdminLocks] = useState([]);
  const [guestLocks, setGuestLocks] = useState([]);

  useEffect(() => {
    setUserLocks(Object.values(locks));
  }, [locks]);

  useEffect(() => {
    async function sortLocks(locks) {
      if (locks !== undefined && locks.length > 1) {
        locks.sort(function (a, b) {
          if (a.deviceName === undefined) {
            a.deviceName = "";
          }
          if (b.deviceName === undefined) {
            b.deviceName = "";
          }
          return a.deviceName.localeCompare(b.deviceName);
        });
      }
    }

    async function setLocksByRole(locks) {
      var admins = [];
      var guests = [];
      for (var i = 0; i < locks.length; i++) {
        const currentLock = locks[i];
        if (currentLock.role === "OWNER" || currentLock.role === "ADMIN") {
          admins.push(locks[i]);
        } else {
          guests.push(locks[i]);
        }
      }

      sortLocks(admins);
      sortLocks(guests);
      setAdminLocks(admins);
      setGuestLocks(guests);
    }

    setLocksByRole(userLocks);
  }, [userLocks]);

  
  return isLoading ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress className={classes.spinnerStyle} />
    </div>
  ) : (
    <div>
      {adminLocks && adminLocks.length > 0 ? (
        <div>
          <Typography className={classes.title} gutterBottom component="h2">
            Como administrador
          </Typography>
          <Grid container className={classes.root}>
            {adminLocks &&
              adminLocks.map((lock, index) => (
                <LockItem key={"LOCKS" + index} lock={lock}></LockItem>
              ))}
          </Grid>
        </div>
      ) : (
        <div />
      )}
      {guestLocks && guestLocks.length > 0 ? (
        <div>
          <Typography className={classes.title} gutterBottom component="h2">
            Como Invitado
          </Typography>
          <Grid container className={classes.root}>
            {guestLocks &&
              guestLocks.map((lock, index) => (
                <LockItem key={"LOCKS" + index} lock={lock}></LockItem>
              ))}
          </Grid>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

class Locks extends Component {
  
  render(props) {
    return (
      <LocksSection
        locks={this.props.locks}
        isLoading={this.props.isLoading}
        history={this.props.history}
      />
    );
  }

  componentDidMount() {
    this.props.getUserLocks();
    window.scrollTo(0, 0);
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    locks: state[NAME].locks,
    isLoading: state[NAME].isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserLocks: () => dispatch(getUserLocks()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Locks);
