import React from "react";
import { Route } from "react-router-dom";

import SignIn from "../containers/SignIn";
import SignUp from "../containers/SignUp";
import Home from "../containers/Home";
import KeyAccess from "../containers/KeyAccess";

function MainContainer({ auth }) {
  return (
    <div>
      <Route path="/home" component={Home} />
      <Route path="/key" component={KeyAccess} />
      <Route path="/signin" component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route exact path="/" component={SignIn} />
      {/*  NOT FOUND PAGE */}
      {/* <Route path="*" component={SignIn} /> */}
    </div>
  );
}

//propiedades a guardar en el store
export default MainContainer;
