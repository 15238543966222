import {
  GET_CONTROL_POINTS,
  CREATE_CONTROL_POINT,
  GET_CONTROL_POINT_USERS,
  UPDATE_CONTROL_POINT,
  DELETE_CONTROL_POINT_USERS,
  DELETE_CONTROL_POINT,
  ADD_USERS_TO_CONTROL_POINT,
  GET_CONTROL_POINT_ACTIVITY,
  GENERATE_NEW_QR_TOKEN
} from "./actionTypes";
import { BASE_URL } from '../../utils/network';
import { sortUsersByRole, sortUsersDisplayedByName } from "../../helpers/SortHelpers";

/***
 * Create control point
 */
export const createControlPoint = (controlPoint, handleAPISuccess, handleAPIOnError) => {
  return function (dispatch, getState, { getFirebase }) {
    const action = {
      type: CREATE_CONTROL_POINT,
      data: {
        isLoading: true,
      }
    };

    dispatch(action);
    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + '/api/v1/firebase/control-point';
            const headers = {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              "user-token": latestToken,
            };

            const body = JSON.stringify(controlPoint);

            return fetch(apiUrl, {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              body: body,
            }).then(response => response.json())
              .then(data => {
                if (data.error) {
                  handleAPIOnError(data.error)
                } else {
                  //success
                  handleAPISuccess(data);
                }
                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.accessList = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

/***
 * Update control point
 */
export const updateControlPoint = (controlPoint, handleAPISuccess, handleAPIOnError) => {
  return function (dispatch, getState, { getFirebase }) {
    const action = {
      type: UPDATE_CONTROL_POINT,
      data: {
        isLoading: true,
      }
    };

    dispatch(action);
    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + '/api/v1/firebase/control-point';
            const headers = {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              "user-token": latestToken,
              'control-point-id': controlPoint.key,
            };

            const body = JSON.stringify({
              name: controlPoint.name,
              longitude: controlPoint.longitude,
              latitude: controlPoint.latitude,
              timeZone: controlPoint.timeZone
            });

            return fetch(apiUrl, {
              method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              body: body,
            }).then(response => response.json())
              .then(data => {
                if (data.error) {
                  handleAPIOnError(data.error)
                } else {
                  //success
                  handleAPISuccess(data);
                }
                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.accessList = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

/***
 * Delete control point
 */
export const deleteControlPoint = (controlPointId, handleAPISuccess, handleAPIOnError) => {
  return function (dispatch, getState, { getFirebase }) {
    const action = {
      type: DELETE_CONTROL_POINT,
      data: {
        isLoading: true,
      }
    };

    dispatch(action);
    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + '/api/v1/firebase/control-point';
            const headers = {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              "user-token": latestToken,
              'control-point-id': controlPointId,
            };

            return fetch(apiUrl, {
              method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              body: null,
            }).then(response => response.json())
              .then(data => {
                if (data.error) {
                  handleAPIOnError(data.error)
                } else {
                  //success
                  handleAPISuccess(data);
                }
                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.accessList = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

/***
 * Get control points for user
 */
export const getControlPoints = (handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_CONTROL_POINTS,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/control-point";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken
            };

            fetch(apiUrl, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  action.data.controlPoints = [];
                  handleAPIOnError(data.error);
                } else {
                  action.data.controlPoints = json2array(data.controlPoints);
                }

                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                //handleAPIOnError(err);
                action.data.controlPoints = [];
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.controlPoints = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};


/**
 * Returns the list of users for a given controlPointId
 * @param  controlPointId 
 * @param  handleAPIOnError 
 * @returns 
 */
export const getControlPointUsers = (controlPointId, handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_CONTROL_POINT_USERS,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/control-point/member";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "control-point-id": controlPointId
            };

            fetch(apiUrl, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  action.data.users = [];
                  handleAPIOnError(data.error);
                } else {
                  var users = json2array(data.users);
                  users = sortUsersDisplayedByName(users);
                  users = sortUsersByRole(users);
                  action.data.users = users;
                }

                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.users = [];
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.users = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

/**
 * Returns the list of user by their emails for a given controlPointId
 * @param  controlPointId 
 * @param  handleAPIOnError 
 * @returns 
 */
export const deleteControlPointUsersByEmails = (controlPointId, userEmails, handleAPISuccess, handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: DELETE_CONTROL_POINT_USERS,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/control-point/member";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "control-point-id": controlPointId
            };

            const body = JSON.stringify({
              "isEmail": true,
              "users": userEmails
            });

            fetch(apiUrl, {
              method: "DELETE", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              body: body,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  handleAPIOnError(data.error);
                } else {
                  handleAPISuccess();
                }
                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};



export const addUsersToControlPoint= (controlPointId, payload, handleAPISuccess, handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: ADD_USERS_TO_CONTROL_POINT,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/control-point/member";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "control-point-id": controlPointId
            };

            const body = JSON.stringify(payload);
            fetch(apiUrl, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              body: body,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  handleAPIOnError(data.error);
                } else {
                  handleAPISuccess();
                }
                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};


/**
 * Get the complete activity history for a given control point
 * @param {*} controlPointId 
 * @param {*} handleAPIOnError 
 * @returns 
 */
export const getControlPointActivity = (controlPointId, handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_CONTROL_POINT_ACTIVITY,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/control-point/register";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "control-point-id": controlPointId
            };

            fetch(apiUrl, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  action.data.activity = [];
                  handleAPIOnError(data.error);
                } else {

                  var newActivity = json2array(data.activity);
                  newActivity.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.date) - new Date(a.date);
                  });
        
                  action.data.activity = newActivity;
                }

                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.activity = [];
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.activity = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

/**
 * Generates a new QR Token to be used on the web
 * @param {*} controlPointId 
 * @param {*} handleAPIOnError 
 * @returns 
 */
export const generateQRToken = (controlPointId, handleAPISuccess, handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GENERATE_NEW_QR_TOKEN,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/control-point/token";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "control-point-id": controlPointId
            };

            fetch(apiUrl, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  handleAPIOnError(data.error);
                } else {
                  handleAPISuccess(data);
                }

                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

/**
 *  Converts a json response into an Array of items
 * @param  json 
 * @returns 
 */
function json2array(json) {
  var result = [];
  var keys = Object.keys(json);
  keys.forEach(function (key) {
    var object = json[key];
    object.key = key;
    result.push(object);
  });
  return result;
}