import Drawer from "@material-ui/core/Drawer";
import "date-fns";

import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Typography from "@material-ui/core/Typography";
import { Button, Grid, Card } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";

import WorkerAccess from "../access/WorkerAccess";

import SearchBar from "../SearchBar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ControlPointAdminDescription from "./ControlPointAdminDescription";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#52c9f4",
    },
    secondary: {
      main: "#52c9f4",
    },
  },
});

const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: "65px",
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
  },
  drawerPaper: {
    backgroundColor: "#fafafc",
    width: drawerWidth,
  },
  mainContainer: {
    backgroundColor: "#fafafc",
    height: "100vh",
  },
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  titleLock: {
    textTransform: "capitalize",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,1)",
    fontWeight: "bold",
    fontSize: "16pt",
    paddingTop: "30px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  title: {
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    padding: "50px",
    paddingTop: "5px",
    autoHeight: "true",
    width: "100%",
  },
  selected: {
    background: "white",
    backgroundColor: "white",
    borderStyle: "none!important",
    textTransform: "capitalize",
    "&:hover": {
      background: "white",
      backgroundColor: "white",
      borderStyle: "none!important",
    },
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  titleUsers: {
    marginTop: "10px",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    height: "20px",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "10px",
  },
  buttonContainer: {
    backgroundColor: "#fafafc",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  button: {
    height: "50px",
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "#55c9f4!important",
      color: "#ffffff!important",
    },
  },
  buttonCancel: {
    height: "50px",
    marginRight: "50px",
    width: "120px",
    backgroundColor: "#ef4445",
    color: "#ffffff",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "rgba(239, 68, 69, 0.3)",
      color: "#ffffff!important",
    },
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
  },
  textField: {
    width: "60%",
    backgroundColor: "white",
  },
  cardContainer: {
    padding: 20,
    textAlign: "center",
  },
  gridNewUsers: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    marginTop: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    width: "100%",
  },
  gridCard: {
    margin: "10px",
    padding: "0px!important",
  },
  cardTitle: {
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "14pt",
    top: "50%",
    height: "50px",
    marginLeft: "15px",
    padding: "0px!important",
    marginBottom: "0px",
  },
  userCardTitle: {
    alignSelf: "center",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "10pt",
    marginLeft: "15px",
  },
}));

const permissionType = ["Admin", "Trabajador"];

const ControlPointPermissionsSection = ({
  isLoading,
  open,
  togglePermissionDrawer,
  controlPoint,
  onAddMembersToControlPoint
}) => {
  const classes = useStyles();

  const ADMIN = 0;
  const GUEST_WORKER = 1;

  const [usersRole, setUsersRole] = React.useState(ADMIN);
  const [permission, setPermission] = React.useState(null);
  const [users, setUsers] = React.useState([]);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  function handleChangeUserRoleType(event) {
    const type = event.target.value;
    setUsersRole(type);
    if (type == ADMIN) {
      setPermission(null);
    }
  }

  function handleWorkerAccessUpdated(permission) {
    setPermission(permission);
  }

  function createPermission() {
    const type =
      usersRole == ADMIN
        ? "ADMIN"
        : usersRole == GUEST_WORKER
          ? "WORKER"
          : "UNKNOWN";

    var permissionPayload = {
      isEmail: true,
      role: type,
      users: users
    }

    if (permission !== null) {
      permissionPayload = {
        ...permissionPayload,
        schedule: permission
      }
    }
    onAddMembersToControlPoint(permissionPayload);
  }

  const onRemoveUserFromList = (_, index) => (event) => {
    event.persist();
    let items = [...users];
    if (index !== -1) {
      items.splice(index, 1);
      setUsers(items)
    }
  };

  const onAddUser = (user) => {
    for (var i = 0; i < users.length; i++) {
      if (users[i] == user) {
        return
      }
    }
    setUsers(users.concat([user]))
  }


  return (
    <React.Fragment>
      <ThemeProvider theme={customTheme}>
        <Drawer
          open={open}
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.mainContainer}>
            <Typography
              className={classes.titleLock}
              gutterBottom
              component="h2"
            >
              {controlPoint ? controlPoint.name : "-"}
            </Typography>

            <Typography className={classes.title} gutterBottom component="h2">
              Tipo de usuario
            </Typography>
            <div className={classes.mainContent}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  htmlFor="outlined-age-native-simple"
                />
                <Select
                  native
                  value={usersRole}
                  onChange={(e) => handleChangeUserRoleType(e)}
                  labelWidth={labelWidth}
                  inputProps={{
                    permission: "",
                    id: "age-native-helper",
                  }}
                  classes={{
                    root: classes.selected,
                  }}
                >
                  {permissionType &&
                    permissionType.map((option, index) => (
                      <option
                        className={classes.option}
                        key={index}
                        value={index}
                      >
                        {option}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            {usersRole == ADMIN ? (
              <ControlPointAdminDescription></ControlPointAdminDescription>
            ) : usersRole == GUEST_WORKER ? (
              <WorkerAccess
                handleWorkerAccessUpdated={handleWorkerAccessUpdated}
                hasPassword={false}
              ></WorkerAccess>
            ) : (
              <></>
            )}

            <div>
              <Typography
                className={classes.titleUsers}
                gutterBottom
                component="h2"
              >
                Buscar usuario
              </Typography>
              <SearchBar
                className={classes.searchBar}
                onSearch={(user) => onAddUser(user)}
                placeHolder={"Agregar usuario"}
                isLoading={isLoading}
              />
              <Grid container className={classes.gridNewUsers}>
                {users &&
                  users.map((user, index) => (
                    <Grid item key={"USER_" + index} xs={12}>
                      <Card style={{ display: "flex", flexDirection: "row" }} className={classes.gridCard}>
                        <Typography
                          style={{ flexGrow: 1 }}
                          className={classes.userCardTitle}
                          gutterBottom
                          component="h2"
                        >
                          {user}
                        </Typography>
                        <IconButton
                          style={{ float: "right" }}
                          className={classes.iconButton}
                          aria-label="remove"
                          onClick={onRemoveUserFromList(user, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </div>

            <div style={{ float: "bottom" }}>
              <div className={classes.buttonContainer}>
                {isLoading ? (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress className={classes.spinnerStyle} />
                  </div>
                ) : (
                  <div>
                    <Button
                      className={classes.buttonCancel}
                      onClick={() => togglePermissionDrawer(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={createPermission}
                    >
                      Crear permiso!
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </ThemeProvider>
    </React.Fragment>
  );
};

class ControlPointPermissionsDrawer extends Component {
  render(props) {
    return <ControlPointPermissionsSection {...this.props} />;
  }
}

export default connect(
  null,
  null
)(ControlPointPermissionsDrawer);
