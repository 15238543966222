import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";

import UserActivity from "../assets/lock/ic_user_activity.svg";
import IconLocation from "../assets/lock/ic_location.svg";
import IconClock from "../assets/lock/ic_clock.svg";
import IconStateLocked from "../assets/lock/ic_state_locked.svg";
import IconStateOpen from "../assets/lock/ic_state_open.svg";
import IconHouse from "../assets/lock/ic_house.svg";
import UsersActive from "../assets/lock/ic_users_active.svg";

import AccessGray from "../assets/lock/ic_access_gray.svg";
import ActivityGray from "../assets/lock/ic_activity_gray.svg";
import ActivityActive from "../assets/lock/ic_activity_active.svg";
import DetailsGray from "../assets/lock/ic_details_gray.svg";
import DetailsActive from "../assets/lock/ic_details_active.svg";

const useStyles = makeStyles((theme) => ({
  gridCard: {
    minWidth: "250px",
    margin: "10px",
    padding: "0px!important",
  },
  cardTitle: {
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "14pt",
    top: "50%",
    height: "35px",
    marginLeft: "15px",
    padding: "0px!important",
    marginBottom: "0px",
    marginTop: "10px",
    marginRight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    display: "-webkit-box",
  },
  picture: {
    height: "100px",
  },
  firstDivider: {
    paddingTop: "1px",
  },
  divider: {
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "20px",
    marginBottom: "20px",
  },
  addressSection: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "20px",
    marginBottom: "10px",
  },
  addressIcon: {
    width: "25px",
    height: "25px",
    marginLeft: "20px",
    marginRight: "5px",
    top: "0px",
    justifyContent: "flex-start",
  },
  activityIcon: {
    width: "25px",
    height: "25px",
    marginLeft: "20px",
  },
  stateIcon: {
    marginRight: "15px",
    width: "30px",
    height: "30px",
    minWidth: "40px",
    marginLeft: "10px",
  },
  cardSubtitle: {
    fontSize: "10pt",
    opacity: "0.5",
    padding: "0px!important",
    color: "#414142",
    textTransform: "capitalize",
    marginRight: "20px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    display: "-webkit-box",
  },
  lastAccess: {
    fontSize: "10pt",
    fontWeight: "bold",
    marginLeft: "20px!important",
    color: "#000000",
  },
  buttonSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center!important",
  },
  buttonUser: {
    width: "50px",
    height: "50px",
    margin: "10px",
    marginTop: "0px",
    background: "url(" + AccessGray + ")!important",
    "&:hover": {
      //55c9f4
      background: "url(" + UsersActive + ")!important",
    },
  },
  buttonActivity: {
    width: "50px",
    height: "50px",
    margin: "10px",
    marginTop: "0px",
    backgroundImage: "url(" + ActivityGray + ")!important",
    "&:hover": {
      //55c9f4
      backgroundImage: "url(" + ActivityActive + ")!important",
    },
  },
  buttonDetails: {
    width: "50px",
    height: "50px",
    margin: "10px",
    marginTop: "0px",
    backgroundImage: "url(" + DetailsGray + ")!important",
    "&:hover": {
      //55c9f4
      backgroundImage: "url(" + DetailsActive + ")!important",
    },
  },
  lastUpdateTimeStamp: {
    fontSize: "10pt",
    opacity: "0.5",
    padding: "0px!important",
    color: "#414142",
    marginLeft: "10px",
  },
  lastUpdateUserSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  lastUpdateUserEmail: {
    fontSize: "10pt",
    opacity: "0.5",
    padding: "0px!important",
    color: "#414142",
    marginLeft: "10px",
    marginRight: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default function LockItem({ lock }) {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card className={classes.gridCard}>
        <Typography className={classes.cardTitle} gutterBottom component="h2">
          {lock.deviceName.toLowerCase()}
        </Typography>
        <Divider className={classes.firstDivider} />
        <CardMedia
          className={classes.picture}
          component="img"
          alt={lock.deviceName}
          image={lock.devicePictureUrl}
          title={lock.deviceName}
          src={!lock.devicePictureUrl ? IconHouse : lock.devicePictureUrl}
          onError={(e) => {
            e.target.src = IconHouse;
          }}
        />

        <Divider className={classes.divider} />
        <div className={classes.addressSection}>
          <img className={classes.addressIcon} alt="" src={IconLocation} />
          <Typography className={classes.cardSubtitle} component="p">
            {lock.doorLockAddress}
          </Typography>
        </div>

        {!lock.stateTimeStamp ? (
          <div style={{marginBottom: 10}}/>
        ) : (
          <div style={{marginBottom: 10}}>
            <Divider className={classes.divider} />
            <Typography className={classes.lastAccess}>
              Última actualización
            </Typography>
            <div className={classes.lastUpdateUserSection}>
              <img className={classes.activityIcon} alt="" src={IconClock} />
              <Typography className={classes.lastUpdateTimeStamp} component="p">
                {lock.stateTimeStamp}
              </Typography>
              <img
                className={classes.stateIcon}
                alt=""
                src={
                  lock.stateLock === "LOCKED" ? IconStateLocked : IconStateOpen
                }
              />
            </div>

            <div className={classes.lastUpdateUserSection}>
              <img className={classes.activityIcon} alt="" src={UserActivity} />
              <Typography className={classes.lastUpdateUserEmail} component="p">
                {lock.stateUserEmail}
              </Typography>
            </div>
          </div>
        )}

        {/* <div className={classes.buttonSection}>
                          <img
                            className={classes.buttonUser}
                            alt=""
                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            onClick={()=>redirectUsers(lock)}
                          />
                          <img
                            className={classes.buttonActivity}
                            alt=""
                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            onClick={()=>redirectActivity(lock)}

                          />
                          <img
                            className={classes.buttonDetails}
                            alt=""
                            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"

                          />
                        </div> */}
      </Card>
    </Grid>
  );
}
