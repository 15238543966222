import Drawer from "@material-ui/core/Drawer";
import "date-fns";

import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import SearchBar from "../../components/SearchBar";

import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { NAME, ADMIN, GUEST_VISITOR, GUEST_WORKER } from "./constants";
import {
  getUserByEmail,
  removeUserFromRedux,
  getLockInformation,
  createAccess,
  resetPermissionStore,
} from "./actions";

import * as CryptoJS from "crypto-js";

import IconAvatar from "../../assets/account/ic_avatar.svg";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AdminAccess from "../../components/access/AdminAccess";
import GuestAccess from "../../components/access/GuestAccess";
import WorkerAccess from "../../components/access/WorkerAccess";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#52c9f4",
    },
    secondary: {
      main: "#52c9f4",
    },
  },
});

const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: "65px",
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
  },
  drawerPaper: {
    backgroundColor: "#fafafc",
    width: drawerWidth,
  },
  mainContainer: {
    backgroundColor: "#fafafc",
    height: "100vh",
  },
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  titleLock: {
    textTransform: "capitalize",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,1)",
    fontWeight: "bold",
    fontSize: "16pt",
    paddingTop: "30px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  title: {
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    padding: "50px",
    paddingTop: "5px",
    autoHeight: "true",
    width: "100%",
  },
  selected: {
    background: "white",
    backgroundColor: "white",
    borderStyle: "none!important",
    textTransform: "capitalize",
    "&:hover": {
      background: "white",
      backgroundColor: "white",
      borderStyle: "none!important",
    },
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  titleUsers: {
    marginTop: "10px",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    height: "20px",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "10px",
  },
  buttonContainer: {
    backgroundColor: "#fafafc",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  button: {
    height: "50px",
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "#55c9f4!important",
      color: "#ffffff!important",
    },
  },
  buttonCancel: {
    height: "50px",
    marginRight: "50px",
    width: "120px",
    backgroundColor: "#ef4445",
    color: "#ffffff",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "rgba(239, 68, 69, 0.3)",
      color: "#ffffff!important",
    },
  },
  gridNewUsers: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    marginTop: "10px",
    paddingLeft: "40px",
    paddingRight: "40px",
    width: "100%",
  },
  gridCard: {
    maxWidth: "150px",
    margin: "10px",
    padding: "0px!important",
  },
  cardTitle: {
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "14pt",
    top: "50%",
    height: "50px",
    marginLeft: "15px",
    padding: "0px!important",
    marginBottom: "0px",
    marginTop: "10px",
  },
  userCardTitle: {
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "10pt",
    top: "50%",
    marginLeft: "15px",
    marginTop: "10px",
  },
  picture: {
    height: "50px!important",
    width: "50px!important",
    borderRadius: "50%",
    marginTop: "10px",
    marginBottom: "10px",
    borderColor: "rgba(65,65,65,0.1)",
    borderWidth: "5px",
    borderStyle: "solid",
    margin: "auto",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
  },
}));

const permissionType = ["Administrador", "Visitante", "Trabajador"];

const PermissionSection = ({
  lockUsers,
  isLoading,
  newUser,
  getUserByEmail,
  removeUserFromRedux,
  getLockInformation,
  createAccess,
  lockInfo,
  isPermissionLoading,
  error,
  isPermissionCreated,
  resetPermissionStore,
  open,
  togglePermissionDrawer,
  currentLock,
}) => {
  const classes = useStyles();

  const initialState = {
    permission: "",
    users: [],
    passwordAdmin: null,
    passwordGuest: null,
  };

  const [state, setState] = React.useState(initialState);
  const [permission, setPermission] = React.useState(null);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  React.useEffect(() => {
    function redirectUsers() {
      resetPermissionStore();
      setState(initialState);
      togglePermissionDrawer(false);
      setPermission(null);
    }

    if (isPermissionCreated) {
      redirectUsers();
    }
  }, [isPermissionCreated, togglePermissionDrawer, resetPermissionStore]);

  React.useEffect(() => {
    if (currentLock) {
      getLockInformation(currentLock.key);
      setState((state) => ({
        permission: "",
        users: [],
        passwordAdmin: null,
        passwordGuest: null,
      }));
      setPermission(null);
    }
  }, [currentLock, getLockInformation]);

  React.useEffect(() => {
    function checkAndAddUser(newUser) {
      if (newUser) {
        for (var j in lockUsers) {
          if (lockUsers[j].key === newUser.key) {
            const message = "El usuario ya tiene permisos para este acceso.";
            toast.error(message);
            removeUserFromRedux();
            return;
          }
        }
        if (state !== undefined && state.users !== undefined) {
          for (var i = 0; i < state.users.length; i++) {
            if (state.users[i].key === newUser.key) {
              const message = "El usuario ya esta agregado a la lista";
              toast.error(message);
              removeUserFromRedux();
              return;
            }
          }
        }
        state.users.push(newUser);
        removeUserFromRedux();
      }
    }

    checkAndAddUser(newUser);
  }, [newUser]);

  const handleChangePermissionType = (permission) => (event) => {
    event.persist();
    //event.target.value contains the lock id
    setState((state) => ({
      ...state,
      [permission]: event.target.value,
    }));
  };

  function handleSearchUserClick(email) {
    if (email !== undefined && email !== "") {
      getUserByEmail(email);
    } else {
      toast.error("Ingrese un correo válido.");
    }
  }

  const removeUser = (user, index) => (event) => {
    event.persist();

    var array = state.users; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      setState((state) => ({
        ...state,
        users: array,
      }));
    }
  };

  function createPermission() {
    var permissionInfo;
    if (state.users.length <= 0) {
      toast.error("Debe agregar usuarios a la lista");
      return;
    }

    if (state.permission == ADMIN) {
      permissionInfo = {
        role: "ADMIN",
      };
    } else {
      if (state.passwordAdmin == null || state.passwordAdmin == "") {
        toast.error("Debe ingresar el password de administrador");
        return;
      }

      if (state.passwordGuest == null || state.passwordGuest == "") {
        toast.error("Debe ingresar el password de Invitado.");
        return;
      }

      var hash = calculateGuestHashTwoGuest(
        state.passwordAdmin,
        lockInfo.securityKeyTwo,
        state.passwordGuest,
        lockInfo.key
      );

      if (state.permission == GUEST_VISITOR) {
        const fromTimeStamp = permission.fromDate;
        const toTimeStamp = permission.toDate;

        if (fromTimeStamp >= toTimeStamp) {
          toast.error(
            "La hora de egreso debe ser posterior a la hora de ingreso! "
          );
          return;
        }

        permissionInfo = {
          fromTimeStamp: fromTimeStamp,
          toTimeStamp: toTimeStamp,
          securityInviteHash: hash,
          role: "GUEST_VISITOR",
        };
      } else if (state.permission == GUEST_WORKER) {
        // Time in seconds for Firebase permissions
        const fromTime = permission.fromTime * 60;
        const toTime = permission.toTime * 60;

        if (fromTime >= toTime) {
          toast.error(
            "La hora de egreso debe ser posterior a la hora de ingreso! "
          );
          return;
        }

        permissionInfo = {
          fromTimeOfDay: fromTime,
          toTimeOfDay: toTime,
          monday: permission.days.includes(1) ? true : false,
          tuesday: permission.days.includes(2) ? true : false,
          wednesday: permission.days.includes(3) ? true : false,
          thursday: permission.days.includes(4) ? true : false,
          friday: permission.days.includes(5) ? true : false,
          saturday: permission.days.includes(6) ? true : false,
          sunday: permission.days.includes(0) ? true : false,
          securityInviteHash: hash,
          role: "GUEST_WORKER",
        };
      }
    }

    // console.log("Permission users: ", state.users);
    // console.log("Permission currentLock: ", currentLock, permissionInfo);
    // console.log("Permission info: ", permissionInfo);

    createAccess(state.users, currentLock, permissionInfo);
    toast.success("Permiso creado correctamente.");
  }

  function calculateGuestHashTwoGuest(
    passwordOwner,
    key2,
    passwordGuest,
    deviceId
  ) {
    // console.log("deviceId :" + deviceId);
    // console.log("key2 :" + key2);
    // console.log("passwordOwner :" + passwordOwner);
    // console.log("passwordGuest :" + passwordGuest);

    if (
      passwordOwner == null ||
      key2 == null ||
      deviceId == null ||
      passwordGuest == null
    ) {
      toast.error("Hay parametros inválidos. Contacte al equipo de Cittyo.");
      return;
    }
    var guestHashTwo = null;

    var keyOne = CryptoJS.enc.Hex.parse(key2);
    var iv = CryptoJS.enc.Utf8.parse(completeString(passwordOwner));
    var val = CryptoJS.enc.Utf8.parse(completeString(passwordGuest));

    var encrypted = CryptoJS.AES.encrypt(val, keyOne, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });

    // console.log("Key :" + key);
    // console.log("iv :" + iv);
    // console.log("val :" + val);
    // console.log("ciphertext :" + encrypted.ciphertext.toString());

    var keyTwo = CryptoJS.enc.Utf8.parse(completeString(passwordGuest));
    var iv2 = CryptoJS.enc.Hex.parse(deviceId);
    var val2 = encrypted.ciphertext;

    var hash = CryptoJS.AES.encrypt(val2, keyTwo, {
      keySize: 16,
      iv: iv2,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });

    // console.log("Key :" + key2);
    // console.log("iv :" + iv2);
    // console.log("val :" + val2);
    // console.log("ciphertext :" + hash.ciphertext.toString());

    guestHashTwo = hash.ciphertext.toString();
    return guestHashTwo;
  }

  function completeString(str) {
    var newString = str.substring(0, 16);
    var i;
    if (newString.length < 16) {
      var lenght = newString.length;
      for (i = 0; i < 16 - lenght; i++) {
        newString += "\0";
      }
    }
    return newString;
  }

  function handleGuestAccessUpdated(permission) {
    setPermission(permission);
  }

  function handleWorkerAccessUpdated(permission) {
    setPermission(permission);
  }

  function handleChangePassword(e) {
    if (state.hasOwnProperty(e.target.id)) {
      state[e.target.id] = e.target.value;
    }
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={customTheme}>
        <Drawer
          open={open}
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.mainContainer}>
            <Typography
              className={classes.titleLock}
              gutterBottom
              component="h2"
            >
              {currentLock ? currentLock.deviceName : "-"}
            </Typography>

            <Typography className={classes.title} gutterBottom component="h2">
              Tipo de Permiso
            </Typography>
            <div className={classes.mainContent}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  htmlFor="outlined-age-native-simple"
                />
                <Select
                  native
                  value={state.permission}
                  onChange={handleChangePermissionType("permission")}
                  labelWidth={labelWidth}
                  inputProps={{
                    permission: "",
                    id: "age-native-helper",
                  }}
                  classes={{
                    root: classes.selected,
                  }}
                >
                  {permissionType &&
                    permissionType.map((option, index) => (
                      <option
                        className={classes.option}
                        key={index}
                        value={index}
                      >
                        {option}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            {
              // ADMINISTRADOR
              state.permission == ADMIN ? (
                <AdminAccess></AdminAccess>
              ) : state.permission == GUEST_VISITOR ? (
                <GuestAccess
                  handleGuestAccessUpdated={handleGuestAccessUpdated}
                  handleChangePassword={handleChangePassword}
                  hasPassword={true}
                  link={false}
                ></GuestAccess>
              ) : state.permission == GUEST_WORKER ? (
                <WorkerAccess
                  handleWorkerAccessUpdated={handleWorkerAccessUpdated}
                  handleChangePassword={handleChangePassword}
                  hasPassword={true}
                ></WorkerAccess>
              ) : (
                <></>
              )
            }

            <div>
              {/* Search users section */}
              <Typography
                className={classes.titleUsers}
                gutterBottom
                component="h2"
              >
                Buscar usuario
              </Typography>
              <SearchBar
                className={classes.searchBar}
                onSearch={handleSearchUserClick}
                placeHolder={"Buscar usuario"}
                isLoading={isLoading}
              />
              <Grid container className={classes.gridNewUsers}>
                {state.users &&
                  state.users.map((user, index) => (
                    <Grid item key={"USER_" + index} xs={12}>
                      <Card className={classes.gridCard}>
                        <Typography
                          className={classes.userCardTitle}
                          gutterBottom
                          component="h2"
                        >
                          {user.lastName + " " + user.firstName}
                        </Typography>
                        <Divider className={classes.firstDivider} />
                        <CardMedia
                          className={classes.picture}
                          component="img"
                          alt={user.lastName}
                          image={user.userPictureUrl}
                          title={user.lastName}
                          src={
                            !user.devicePictureUrl
                              ? IconAvatar
                              : user.devicePictureUrl
                          }
                          onError={(e) => {
                            e.target.src = IconAvatar; // some replacement image
                          }}
                        />
                        <Divider className={classes.firstDivider} />

                        <IconButton
                          className={classes.iconButton}
                          aria-label="remove"
                          onClick={removeUser(user, index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
              <div className={classes.buttonContainer}>
                {isPermissionLoading ? (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress className={classes.spinnerStyle} />
                  </div>
                ) : (
                  <div>
                    <Button
                      className={classes.buttonCancel}
                      onClick={() => togglePermissionDrawer(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => createPermission()}
                    >
                      Crear permiso!
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </ThemeProvider>
    </React.Fragment>
  );
};

class PermissionsDrawer extends Component {
  render(props) {
    return <PermissionSection {...this.props} />;
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    lockInfo: state[NAME].lockInfo,
    newUser: state[NAME].newUser,
    isLoading: state[NAME].isLoading,
    isPermissionLoading: state[NAME].isPermissionLoading,
    isPermissionCreated: state[NAME].isPermissionCreated,
    error: state[NAME].error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLockInformation: (lockId) => dispatch(getLockInformation(lockId)),
    removeUserFromRedux: () => dispatch(removeUserFromRedux()),
    getUserByEmail: (email) => dispatch(getUserByEmail(email)),
    createAccess: (users, lock, permissionInfo) =>
      dispatch(createAccess(users, lock, permissionInfo)),
    resetPermissionStore: () => dispatch(resetPermissionStore()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsDrawer);
