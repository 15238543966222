import React, { Component } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import Pin from "./Pin";
import 'mapbox-gl/dist/mapbox-gl.css'


const MapPicker = ({ zoom, latitude, longitude }) => {
    const latitudeLongitudeRegex = /^((\-?|\+?)?\d+(\.\d+)?)$/;

    const [viewPort, setViewPort] = React.useState();

    React.useEffect(() => {
        setViewPort({
            longitude: longitude ? (latitudeLongitudeRegex.test(longitude) ? longitude : -64) : -64,
            latitude: latitude ? (latitudeLongitudeRegex.test(latitude) ? latitude : -31) : -31,
            zoom: zoom ? zoom : 13
        })

    },[latitude, longitude, zoom]);


    return (
        <ReactMapGL
            initialViewState={{
                longitude: -31,
                latitude: -64,
                zoom: zoom ? zoom : 3,
            }}
            viewState={viewPort}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            style={{ width: '100vw - 0px', height: '40vh' }}
        >
            <Marker longitude={latitudeLongitudeRegex.test(longitude) ? longitude : -64}
                latitude={latitudeLongitudeRegex.test(latitude) ? latitude : -31}
                anchor="top"
                offset={[10, -20]} // This is the offset to adjust the pin position
            >
                <Pin></Pin>
            </Marker>
        </ReactMapGL>
    );
};

export default MapPicker;