import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import ControlPointUser from "./ControlPointUser";
import { Grid, Typography, Fab, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
    padding: 5,
  },
  title: {
    backgroundColor: "#fafafc",
    textTransform: "capitalize",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "14pt",
    paddingLeft: 10,
    marginBottom: "0px",
  },
  fab: {
    backgroundColor: "#55c9f4",
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "#ffffff",
    "&:hover": {
      alfa: "0.4",
      backgroundColor: "#55c9f4",
      color: "#ffffff",
    },
  },
}));

const ControlPointUserCard = (props) => {
  var { user } = props;
  var { onRemoveUser, currentUserRole } = props;
  return (
    <ControlPointUser
      user={user}
      currentUserRole={currentUserRole}
      onRemoveUser={onRemoveUser}>
    </ControlPointUser>
  );
};


const ControlPointUsers = (props) => {
  const {
    users,
    handleItemClick,
    currentUserRole,
    onRemoveUser,
    onOpenDrawer,
  } = props;
  const classes = useStyles();

  const [adminUsers, setAdminUsers] = React.useState([]);
  const [guestUsers, setGuestUsers] = React.useState([]);

  React.useEffect(() => {
    async function setUsersByRole(users) {
      const admins = [];
      const guests = [];
      for (var i = 0; i < users.length; i++) {
        if (users[i].role === "OWNER" || users[i].role === "ADMIN") {
          admins.push(users[i]);
        } else {
          guests.push(users[i]);
        }
      }

      setAdminUsers(admins);
      setGuestUsers(guests);
    }

    const u = users ? users : [];
    setUsersByRole(u);
  }, [users]);

  return (

    <div>
      {adminUsers.length <= 0 ? (
        <div />
      ) : (
        <div>
          <Typography className={classes.title} gutterBottom component="h2">
            Usuarios administradores
          </Typography>
          <Grid container className={classes.root}>
            {adminUsers &&
              adminUsers.map((user, index) => (
                <ControlPointUserCard
                  key={"USER_ADMIN_" + index}
                  user={user}
                  currentUserRole={currentUserRole}
                  onRemoveUser={onRemoveUser}
                ></ControlPointUserCard>
              ))}
          </Grid>
        </div>
      )}
      {guestUsers.length <= 0 ? (
        <div />
      ) : (
        <div>
          <Typography className={classes.title} gutterBottom component="h2">
            Personal
          </Typography>
          <Grid container className={classes.root}>
            {guestUsers &&
              guestUsers.map((user, index) => (
                <ControlPointUserCard
                  key={"USER_GUEST_" + index}
                  user={user}
                  currentUserRole={currentUserRole}
                  onRemoveUser={onRemoveUser}
                ></ControlPointUserCard>
              ))}
          </Grid>
        </div>
      )}

      <Tooltip title="Agregar usuarios" placement="left">
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={onOpenDrawer}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>


  );
};

export default ControlPointUsers;
