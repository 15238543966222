import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1px 1px",
    display: "flex",
    alignItems: "center",
    height: "50px",
    marginRight: "50px",
    marginLeft: "50px",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: "10px",
    margin: "5px",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
}));

var states = {
  inputText: "",
};

export default function SearchBar(props) {
  const { placeHolder, onSearch, isLoading } = props;
  const classes = useStyles();

  function handleChange(e) {
    e.persist();

    if (states.hasOwnProperty(e.target.id)) {
      states[e.target.id] = e.target.value;
    }
  }

  return (
    <Paper className={classes.root}>
      {isLoading ? (
        <div className={classes.iconButton}>
          <CircularProgress className={classes.spinnerStyle} />
        </div>
      ) : (
        <IconButton
          className={classes.iconButton}
          aria-label="search"
          onClick={() => onSearch(states.inputText)}
        >
          <SearchIcon />
        </IconButton>
      )}

      <InputBase
        id="inputText"
        className={classes.input}
        placeholder={placeHolder}
        inputProps={{ "aria-label": { placeHolder } }}
        onChange={handleChange}
      />
    </Paper>
  );
}
