import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Divider, TextField, Grid, Card, Button } from "@material-ui/core";
import { QRCode } from "react-qr-svg";
import UpgradeIcon from '@material-ui/icons/Update';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        backgroundColor: "#fafafc",
        padding: "10px!important",
        paddingBottom: "0px!important",
    },
    cardContent: {
        width: "100%",
        minWidth: "300px",
        margin: 0,
        padding: 60,
        color: "white!important",
        background: "white",
    },
    divider: {
        maxHeight: "2px",
        marginTop: "20px",
        marginBottom: "20px",
        opacity: "0.1",
        background: "#868689",
        height: "1px!important",
        marginLeft: "30px",
        marginRight: "30px",
    },
    textField: {
        margin: 20,
        width: "50%",
        color: "black",
        textAlign: "center"
    },
    qrContainer: {
        width: "100%",
        padding: "0px!important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    qrStyle: {
        width: 256,
        marginBottom: 60,
        alignSelf: "center"
    },
    updateButton: {
        marginBottom: 20,
        alignItems: "center",
        width: "50%",
        height: 50,
        backgroundColor: "#def2fb",
        color: "#55c9f4",
        fontWeight: "bold!important",
        textTransform: "capitalize!important",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#55c9f4!important",
            color: "#ffffff!important",
        },
    },
}));


const ControlPointManageQR = ({
    controlPoint,
    onUpdateQRToken
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Card className={classes.cardContent}>
                <Grid container >
                    <Grid item xs={12} md={12} className={classes.qrContainer}>
                        {
                            controlPoint && controlPoint.qrcode ?

                                <QRCode
                                    id="qr-code"
                                    bgColor="#FFFFFF"
                                    fgColor="#000000"
                                    level="Q"
                                    className={classes.qrStyle}
                                    value={controlPoint.qrcode}
                                /> : <div></div>
                        }
                    </Grid>
                    <Grid item xs={12} md={12} className={classes.qrContainer}>
                        <TextField
                            id="token"
                            label="Password"
                            disabled={true}
                            className={classes.textField}
                            variant="outlined"
                            onChange={() => { }}
                            value={
                                controlPoint && controlPoint.token ? controlPoint.token : "-"
                            }
                        />

                    </Grid>
                    <Grid item xs={12} md={12} className={classes.qrContainer} >
                        <Button
                            className={classes.updateButton}
                            onClick={onUpdateQRToken}
                        >
                            <UpgradeIcon style={{ marginRight: 10 }} />
                            Generar nuevo password
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};


export default (ControlPointManageQR);
