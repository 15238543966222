import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";
import ControlPointUser from "./ControlPointUser";
import { Grid, Typography } from "@material-ui/core";
import ScheduleDays from "./ScheduleDays";
import ScheduleHours from "./ScheduleHours";


const useStyles = makeStyles((theme) => ({
    title: {
        margin: 5,
        marginLeft: 10,
        justifyContent: "center",
        color: "#000",
        fontSize: 14,
        fontWeight: "bold"
    },
}));



const ControlPointUserSchedule = ({ schedule }) => {
    const classes = useStyles();

    return (

        <Grid container>
            <Grid item xs={12} md={12}>
                <div>
                    <Typography className={classes.title}>Cronograma</Typography>
                    <ScheduleDays days={schedule.days} />
                    <ScheduleHours from={schedule.fromTime} to={schedule.toTime}></ScheduleHours>
                </div>
            </Grid>
        </Grid>

    );
};

export default ControlPointUserSchedule;
