import { GET_USER_DATA, UPDATE_USER_DATA } from "./actionTypes";
import Firebase from "firebase/compat/app";

export const getUserData = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const action = {
      type: GET_USER_DATA,
      data: {
        user: null,
      },
    };

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let ref2 = Firebase.database().ref("/users/" + user.uid);

        ref2.on("value", (snapshot) => {
          const state = snapshot.val();
          action.data.user = state;
          dispatch(action);
        });
      } else {
        dispatch(action);
      }
    });
  };
};

export const updateUserData = (user) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const newName = user.firstName + " " + user.lastName;

    const action = {
      type: UPDATE_USER_DATA,
      data: {
        error: null,
        isLoading: true,
      },
    };
    dispatch(action);

    currentUser
      .updateProfile({
        displayName: newName,
      })
      .then((s) => {
        const updatedUser = firebase.auth().currentUser;
        if (user) {
          const userProfile = {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            address: user.address,
          };

          const param = JSON.parse(JSON.stringify(userProfile));
          Firebase.database()
            .ref("/users/" + updatedUser.uid)
            .update(param, function (error) {
              if (error) {
                action.data.error = error;
                action.data.isLoading = false;
              } else {
                action.data.error = null;
                action.data.isLoading = false;
              }

              dispatch(action);
            });
        } else {
          action.data.error = "User undefined";
          action.data.isLoading = false;
          dispatch(action);
        }
      });
  };
};
