import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import rootReducer from "./reducers";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import FirebaseConfig from "../../config/FBConfig";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase })),
    reactReduxFirebase(FirebaseConfig), // redux binding for firebase
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.devToolsExtension() : (f) => f
  )
);

export default store;
