import {
  GET_LOCK_ACTIVITY,
  GET_USER_LOCKS,
  GET_GUEST_LOCK_ACTIVITY,
} from "./actionTypes";
import Firebase from "firebase/compat/app";

export const getLockActivity = (lockId, quantity) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_LOCK_ACTIVITY,
      data: {
        lockActivity: [],
        isLoading: true,
      },
    };
    dispatch(action);

    if (quantity === 0) {
      //request all
      let ref = Firebase.database()
        .ref("/activity/" + lockId)
        .orderByChild("timeStamp")
        .limitToLast(5000);

      ref.on("value", function (snapshot) {
        var state = snapshot.val();

        if (state === null) {
          state = [];
        }

        async function processActivity(state) {
          action.data.lockActivity = Object.values(state);
          action.data.lockActivity.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.timeStamp) - new Date(a.timeStamp);
          });

          action.data.isLoading = false;
          dispatch(action);
        }

        processActivity(state);
      });
    } else {
      let ref = Firebase.database()
        .ref("/activity/" + lockId)
        .orderByChild("timeStamp")
        .limitToLast(10);
      ref.on("value", function (snapshot) {
        var state = snapshot.val();

        if (state === null) {
          state = [];
        }

        action.data.lockActivity = Object.values(state);
        action.data.lockActivity.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.timeStamp) - new Date(a.timeStamp);
        });

        action.data.isLoading = false;
        dispatch(action);
      });
    }
  };
};

export const getGuestLockActivity = (currentLock) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_GUEST_LOCK_ACTIVITY,
      data: {
        lockActivity: [],
        isLoading: true,
      },
    };
    dispatch(action);

    var user = Firebase.auth().currentUser;

    if (!currentLock) {
      return;
    }
    //request all
    var ref = Firebase.database()
      .ref("activity").child(currentLock.key)
      .orderByChild("timeStamp")
      .limitToLast(5000);
    ref.on("value", function (snapshot) {
      var state = snapshot.val();
      if (state === null) {
        state = [];
      }

      var activityHistory = Object.values(state);
      activityHistory = activityHistory.filter(activity => (activity.userEmail == user.email || activity.author && activity.author.email == user.email));
      action.data.lockActivity = activityHistory
      action.data.lockActivity.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.timeStamp) - new Date(a.timeStamp);
      });
      action.data.isLoading = false;
      dispatch(action);
    });
  };
};

export const getUserLocks = () => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_USER_LOCKS,
      data: {
        userLocks: [],
      },
    };
    const firebase = getFirebase();

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userDoorLocksRef = Firebase.database().ref(
          "/userDoorLocks/" + user.uid
        );
        userDoorLocksRef.on("value", (snapshot) => {
          var state = {};

          snapshot.forEach(function (childSnapshot) {
            const key = childSnapshot.key;
            const value = snapshot.child(key).val();

            state[key] = value;
            state[key].key = key;
          });

          const locks = sortLocks(state);
          action.data.userLocks = locks;
          dispatch(action);
        });
      } else {
        // No user is signed in.
        dispatch(action);
        console.log("There is no logged in user");
      }
    });
  };
};

function sortLocks(locks) {
  var l = Object.values(locks);
  if (l !== undefined && l.length > 1) {
    l.sort(function (a, b) {
      if (a.deviceName === undefined) {
        a.deviceName = "";
      }
      if (b.deviceName === undefined) {
        b.deviceName = "";
      }
      return a.deviceName.localeCompare(b.deviceName);
    });
  }

  if (l === undefined) {
    l = [];
  }
  return l;
}
