import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import IconAvatar from "../assets/account/ic_avatar.svg";
import IconOwner from "../assets/permission/owner_tag.svg";

const useStyles = makeStyles((theme) => ({
  gridCard: {
    minWidth: "150px",
    margin: "10px",
    padding: "0px!important",
  },
  cardTitle: {
    display: "-webkit-box",
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "10pt",
    top: "50%",
    marginLeft: "15px",
    padding: "0px!important",
    marginBottom: "5px",
    marginTop: "10px",
    marginRight: "10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 1,
    maxLines: 1,
  },
  picture: {
    height: "80px!important",
    width: "80px!important",
    borderRadius: "50%",
    marginTop: "10px",
    marginBottom: "10px",
    borderColor: "rgba(65,65,65,0.1)",
    borderWidth: "2px",
    borderStyle: "solid",
    margin: "auto",
  },
  selected: {
    background: "white",
    backgroundColor: "white",
    borderStyle: "none!important",
    textTransform: "capitalize",
    "&:hover": {
      background: "white",
      backgroundColor: "white",
      borderStyle: "none!important",
    },
  },
  ownerImage: {
    margin: 10,
  },
  iconButton: {
    margin: 0,
    padding: 5,
  },
}));

export default function UserItem({ currentLock, user, removeUser }) {
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={4} md={3} lg={2}>
      <Card className={classes.gridCard}>
        <Typography className={classes.cardTitle} gutterBottom component="h2">
          {user.lastName + " " + user.firstName}
        </Typography>
        <Divider className={classes.firstDivider} />
        <CardMedia
          className={classes.picture}
          component="img"
          alt={user.email}
          image={user.userPictureUrl}
          title={user.email}
          src={!user.devicePictureUrl ? IconAvatar : user.devicePictureUrl}
          onError={(e) => {
            e.target.src = IconAvatar;
          }}
        />
        {currentLock && currentLock.role === "OWNER" ? (
          <div>
            <Divider className={classes.firstDivider} />

            {user.role === "OWNER" ? (
              <img
                alt="owner role"
                className={classes.ownerImage}
                src={IconOwner}
              />
            ) : (
              <div>
                <IconButton
                  className={classes.iconButton}
                  aria-label="remove"
                  onClick={() => removeUser(user)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
          </div>
        ) : (
          <div>
            {user.role === "OWNER" ? (
              <div>
                <Divider className={classes.firstDivider} />
                <img className={classes.ownerImage} alt="" src={IconOwner} />
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </Card>
    </Grid>
  );
}
