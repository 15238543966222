export const NAME = "PERMISSIONS";

export const ADMIN = 0;
export const GUEST_VISITOR = 1;
export const GUEST_WORKER = 2;

//permissions related
//userDoorLocks is used to store all the locks related to a user
///dbroot/userDoorLocks/userId/doorLockId
export const REFERENCE_USER_DOOR_LOCKS = "userDoorLocks";
export const REFERENCE_DEVICE_NAME = "deviceName";

//doorLockUsers is userd to store all the users related to a lock
//dbroot/doorLockUsers/doorLockId/userId
//This will contain the field guestPermissionId in case that the user is added as Guest
export const REFERENCE_DOOR_LOCK_USERS = "doorLockUsers";
//guestPermissions will be created only when a user is added as a guest keeping all the
//related information of the period of time this permission is valid.
//This will create a new guestPermissionId that is the one that should be placed onto doorLockUsers.
//dbroot/guestPermissions/guestPermissionId
//dbroot/doorLockUsers/doorLockId/userId/guestPermissionId
export const REFERENCE_MAIN_GUEST_PERMISSIONS = "guestPermissions";
export const REFERENCE_GUEST_PERMISSION_ID = "guestPermissionId";
export const REFERENCE_PERMISISONS_ROLE = "role"; //ADMIN/OWNER/GUEST_VISITOR/GUEST_WORKER/GUEST_CUSTOM
export const REFERENCE_PERMISSIONS_FROM_TIMESTAMP = "fromTimeStamp"; //For GUEST_VISITOR
export const REFERENCE_PERMISSIONS_TO_TIMESTAMP = "toTimeStamp"; //For GUEST_VISITOR
//If the permission is maintainable over time.
export const REFERENCE_PERMISSIONS_IS_ALWAYS = "isAlways"; //For GUEST_VISITOR = false/ GUEST_WORKER = true.
export const REFERENCE_PERMISSIONS_SECURITY_INVITE_HASH = "securityInviteHash";

export const REFERENCE_PERMISSIONS_DAY_MONDAY = "monday";
export const REFERENCE_PERMISSIONS_DAY_TUESDAY = "tuesday";
export const REFERENCE_PERMISSIONS_DAY_WEDNESDAY = "wednesday";
export const REFERENCE_PERMISSIONS_DAY_THURSDAY = "thursday";
export const REFERENCE_PERMISSIONS_DAY_FRIDAY = "friday";
export const REFERENCE_PERMISSIONS_DAY_SATURDAY = "saturday";
export const REFERENCE_PERMISSIONS_DAY_SUNDAY = "sunday";

export const REFERENCE_PERMISSIONS_FROM_TIME_OF_DAY = "fromTimeOfDay";
export const REFERENCE_PERMISSIONS_TO_TIME_OF_DAY = "toTimeOfDay";

export const REFERENCE_PERMISSIONS_CREATED_TIME_STAMP = "createdTimeStamp";
export const REFERENCE_INVITATION_STATUS = "invitationStatus"; //SENT/ACCEPTED/DECLINED. Will always be on accepted.

//Security References;
export const REFERENCE_SECURITY_PASSWORD = "securityPassword"; //This should be local
export const REFERENCE_SECURITY_KEY_ONE = "securityKeyOne";
export const REFERENCE_SECURITY_KEY_TWO = "securityKeyTwo";

//Global Nodes References
//users related
//All users are stored into /dbroot/users
export const REFERENCE_USERS = "users";
export const REFERENCE_USER_REGISTER_TIMESTAMP = "userRegisterTimeStamp";
export const REFERENCE_USER_EMAIL = "email";
export const REFERENCE_USER_FIRST_NAME = "firstName";
export const REFERENCE_USER_LAST_NAME = "lastName";
export const REFERENCE_USER_PICTURE_URL = "userPictureUrl";

//TERMS AND CONDITIONS Section:
export const REFERENCE_LEGAL = "legal";
export const REFERENCE_CURRENT_TERMS_AND_CONDITIONS =
  "termsAndConditionsVersion";

//Session related
//All sessions are stored into /dbroot/usersSession
export const REFERENCE_USERS_SESSION = "usersSession";
export const REFERENCE_USER_LOGIN_TIMESTAMP = "userLoginTimeStamp";
export const REFERENCE_LAST_SESSION_TIMESTAMP = "lastSessionTimeStamp";
export const REFERENCE_LAST_SESSION_DEVICE_ID = "deviceId";
export const REFERENCE_TERMS_AND_CONDITIONS = "termsAndConditions";

//device related
//All devices are stored into /dbroot/doorLocks
export const REFERENCE_DOOR_LOCKS = "doorLocks";
export const REFERENCE_DOOR_LOCK_NAME = "deviceName";
export const REFERENCE_DOOR_LOCK_LATITUDE = "latitude";
export const REFERENCE_DOOR_LOCK_LONGITUDE = "longitude";
export const REFERENCE_DOOR_LOCK_ADDRESS = "doorLockAddress";
export const REFERENCE_DOOR_LOCK_ADDRESS_COMPLETION =
  "doorLockAddressCompletion";
export const REFERENCE_DEVICE_PICTURE = "devicePictureUrl";
export const REFERENCE_TIME_ZONE = "timeZone";
export const REFERENCE_PHOTO_URL = "photoUrl";
export const REFERENCE_MODEL_NAME = "modelName";
export const REFERENCE_MODEL_NUMBER = "modelNumber";
export const REFERENCE_SERIAL_NUMBER = "serialNumber";
export const REFERENCE_SOFTWARE_REVISION = "softwareRevision";
export const REFERENCE_FIRMWARE_REVISION = "firmwareRevision";
export const REFERENCE_HARDWARE_REVISION = "hardwareRevision";
export const REFERENCE_MANUFACTURER_NAME = "manufacturerName";
export const REFERENCE_CONFIGURATION_PASSWORD = "configurationPassword";
export const REFERENCE_CONFIG_DOOR_LOCK_ORIENTATION = "configDoorOrientation";
export const REFERENCE_CONFIG_DOOR_LOCK_LOCK_SOUND = "configLockSound";
export const REFERENCE_CONFIG_DOOR_LOCK_AUTO_LOCK = "configAutoLock";
