// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import {
  GET_USER_LOCKS,
  GET_USER_BY_EMAIL,
  GET_LOCK_USERS,
  CREATE_ACCESS,
  GET_LOCK_INFORMATION,
  RESET_STORE,
} from "./actionTypes";

const initialState = {
  userLocks: [],
  isLoading: false,
  newUser: null,
  lockUsers: [],
  error: null,
  isPermissionLoading: false,
  isPermissionCreated: false,
};

const permissionReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_USER_LOCKS) {
    state = set(state, "userLocks", data.userLocks);
  }

  if (type === GET_USER_BY_EMAIL) {
    state = set(state, "newUser", data.newUser);
    state = set(state, "isLoading", data.isLoading);
  }

  if (type === GET_LOCK_USERS) {
    state = set(state, "lockUsers", data.lockUsers);
  }

  if (type === GET_LOCK_INFORMATION) {
    state = set(state, "lockInfo", data.lockInfo);
  }

  if (type === CREATE_ACCESS) {
    state = set(state, "error", data.error);
    state = set(state, "isPermissionLoading", data.isPermissionLoading);
    state = set(state, "isPermissionCreated", data.isPermissionCreated);
  }

  if (type === RESET_STORE) {
    state = set(state, "error", initialState.error);
    state = set(state, "isPermissionLoading", initialState.isPermissionLoading);
    state = set(state, "isPermissionCreated", initialState.isPermissionCreated);
    state = set(state, "lockUsers", initialState.lockUsers);
    state = set(state, "newUser", initialState.newUser);
    state = set(state, "userLocks", initialState.userLocks);
    state = set(state, "isLoading", initialState.isLoading);
  }

  return state;
};

export default permissionReducer;
