import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import ProfileMenu from "./ProfileMenu";
import { signOut } from "../../containers/SignIn/actions";
import { connect } from "react-redux";

import KeychainGray from "../../assets/menu/ic_keychain_white.svg";
import AccessGray from "../../assets/menu/ic_access_white.svg";
import ActivityGray from "../../assets/menu/ic_activity_white.svg";
import AccountGray from "../../assets/menu/ic_account_white.svg";
import IconLogout from "../../assets/menu/ic_logout_white.svg";
import LockIcon from "../../assets/menu/lock_icon_inactive_white.svg";
import ControlPointIcon from "../../assets/menu/ic_control_point_menu.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000",
    height: "100vh",
    width: 300,
    overflowY: "scroll"
  },
  listItem: {
    height: "40px",
    borderRadius: 10,
    width: "80%",
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    "&:hover": {
      backgroundColor: "#52c9f4",
      backgroundSize: "cover",
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
    },
  },
  listItemSelected: {
    height: "40px",
    width: "80%",
    margin: "auto",
    backgroundColor: "#52c9f4",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5,
    "&:hover": {
      backgroundColor: "#52c9f4",
      overflow: "hidden",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  icon: {
    marginRight: "20px",
    marginLeft: "20px",
    height: "25px",
    width: "25px",
    color: "#fff"
  },
  menuLink: {
    height: "40px",
    textDecoration: "none",
    color: "#fff",
  },
  dividerElements: {
    background: "#fff",
    height: "1px",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  text: {
    fontSize: "11pt",
    color: "#ffffff",
    fontWeight: "bold",
  },
  logOutElement: {
    marginTop: 30,
    height: "40px",
    textDecoration: "none",
    color: "#fff",
  }
}));

const MenuContentSection = ({
  userInfo,
  signOut,
  selectedItem,
  refreshItem,
  selectedItem2,
  refreshItem2,
}) => {
  const classes = useStyles();
  const listElements = [
    {
      title: "Accesos",
      route: "/home/locks",
      asset: LockIcon,
    },
    {
      title: "Permisos",
      route: "/home/users",
      asset: AccessGray,
    },
    {
      title: "Llaves Virtuales",
      route: "/home/keys",
      asset: KeychainGray,
    },
    {
      title: "Actividad",
      route: "/home/activity",
      asset: ActivityGray,
    },
    // {
    //   title: "Puntos de control",
    //   route: "/home/control-points",
    //   asset: ControlPointIcon,
    // },
  ];
  const secondMenu = [
    {
      title: "Mi Cuenta",
      route: "/home/account",
      asset: AccountGray,
    },
  ];

  function logOutClickListener() {
    signOut();
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <ProfileMenu userInfo={userInfo} />
        <List>
          {listElements.map((item, index) => (
            <Link
              to={item.route}
              key={"MENU_ITEM_" + index}
              className={classes.menuLink}
            >
              <ListItem
                button
                className={
                  selectedItem !== index
                    ? classes.listItem
                    : classes.listItemSelected
                }
                onClick={() => refreshItem(index)}
              >
                <Icon className={classes.icon}>
                  <img src={item.asset} alt="" />
                </Icon>
                <p className={classes.text}>{item.title}</p>
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider className={classes.dividerElements} />
        <List>
          {secondMenu.map((item, index) => (
            <Link
              to={item.route}
              key={"MENU_ITEM_BASE_" + index}
              className={classes.menuLink}
            >
              <ListItem
                button
                className={
                  selectedItem2 !== index
                    ? classes.listItem
                    : classes.listItemSelected
                }
                onClick={() => refreshItem2(index)}
              >
                <Icon className={classes.icon}>
                  <img src={item.asset} alt="" />
                </Icon>
                <p className={classes.text}>{item.title}</p>
              </ListItem>
            </Link>
          ))}
        </List>
        <div className={classes.logOutElement}>
          <ListItem onClick={logOutClickListener} className={classes.listItem}>
            <Icon className={classes.icon}>
              <img src={IconLogout} alt="" />
            </Icon>
            <p className={classes.text}>{"Salir"}</p>
          </ListItem>
        </div>
      </div>
    </React.Fragment>
  );
};

class MenuContent extends Component {
  state = {
    item: 0,
    item2: -1,
  };

  refreshItem = (res) => {
    this.setState({ item: res });
    this.setState({ item2: -1 });
  };

  refreshItem2 = (res) => {
    this.setState({ item: -1 });
    this.setState({ item2: res });
  };

  render(props) {
    const { item } = this.state;
    const { item2 } = this.state;

    return (
      <MenuContentSection
        {...this.props}
        selectedItem={item}
        refreshItem={this.refreshItem}
        selectedItem2={item2}
        refreshItem2={this.refreshItem2}
      ></MenuContentSection>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
  };
}

export default connect(null, mapDispatchToProps)(MenuContent);
