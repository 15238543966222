import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import { connect } from "react-redux";
import { NAME } from "./constants";
import { getLockUsers, getUserLocks, removePermissionForUser } from "./actions";
import PermissionsDrawer from "../Permission/PermissionsDrawer";
import UsersExcelExport from "./UsersExcelExport";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserItem from "../../components/UserItem";
import DeleteAccessDialog from "../../components/DeleteAccessDialog";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  fab: {
    backgroundColor: "#55c9f4",
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: "#ffffff",
    "&:hover": {
      alfa: "0.4",
      backgroundColor: "#55c9f4",
      color: "#ffffff",
    },
  },
  title: {
    backgroundColor: "#fafafc",
    textTransform: "capitalize",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "14pt",
    height: "30px",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  gridContainer: {
    justifyContent: "center",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
    padding: "40px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
}));

const UsersSection = ({
  removePermissionForUser,
  isLoading,
  userLocks,
  lockUsers,
  getLockUsers,
}) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);

  const [itemToDelete, setItemToDelete] = React.useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const [locks, setUserLocks] = React.useState(userLocks ? userLocks : []);
  const [users, setLockUsers] = React.useState(lockUsers ? lockUsers : []);
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [guestUsers, setGuestUsers] = React.useState([]);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [selectedLockKey, selectLock] = React.useState(
    userLocks && userLocks.count > 0 ? userLocks[0].key : ""
  );
  const [currentLock, setCurrentLock] = React.useState(
    userLocks && userLocks.count > 0 ? userLocks[0] : null
  );

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (userLocks) {
      setUserLocks(userLocks);
      if (userLocks.length > 0) {
        const firstLock = userLocks[0];
        setCurrentLock(firstLock);
        selectLock(firstLock.key);
      }
    }
  }, [userLocks]);

  React.useEffect(() => {
    if (currentLock) {
      getLockUsers(currentLock.key);
    }
  }, [currentLock, getLockUsers]);

  React.useEffect(() => {
    async function setLockUsersByRole(users) {
      const admins = [];
      const guests = [];
      for (var i = 0; i < users.length; i++) {
        if (users[i].role === "OWNER" || users[i].role === "ADMIN") {
          admins.push(users[i]);
        } else {
          guests.push(users[i]);
        }
      }
      setAdminUsers(admins);
      setGuestUsers(guests);
    }

    const u = lockUsers ? lockUsers : [];
    setLockUsers(u);
    setLockUsersByRole(u);
  }, [lockUsers]);

  const handleNewLockSelected = (event) => {
    event.persist();
    const key = event.target.value;
    const result = locks.filter((lock) => lock.key === key);
    setCurrentLock(result[0]);
    selectLock(key);
  };

  function togglePermissionDrawer(opened) {
    setOpenDrawer(opened);
  }

  function handleDeleteAction(user) {
    handleCloseDeleteAccess();
    removePermissionForUser(currentLock.key, user.key);
    toast.success("Se eliminó el permiso correctamente");
  }

  const handleCloseDeleteAccess = () => {
    setItemToDelete(null);
    setOpenDeleteDialog(false);
  };

  return (
    <div>
      <ToastContainer hideProgressBar={true}></ToastContainer>
      <Typography className={classes.title} gutterBottom component="h2">
        Seleccionar Acceso
      </Typography>
      <div className={classes.mainContent}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple" />
          <Select
            native
            value={selectedLockKey}
            onChange={handleNewLockSelected}
            labelWidth={labelWidth}
            inputProps={{
              lock: "",
              id: "age-native-helper",
            }}
            classes={{
              root: classes.selected,
            }}
          >
            {locks &&
              locks.map((newLock, index) => (
                <option
                  className={classes.option}
                  key={newLock.key}
                  value={newLock.key}
                >
                  {newLock.deviceName}
                </option>
              ))}
          </Select>
        </FormControl>
      </div>
      {isLoading ? (
        <div className={classes.spinnerContainer}>
          <CircularProgress className={classes.spinnerStyle} />
        </div>
      ) : (
        <div>
          <Grid container justifyContent="flex-end">
            <UsersExcelExport
              className={classes.downloadStyle}
              isLoading={isLoading}
              lockUsers={users}
              lockName={currentLock ? currentLock.deviceName : ""}
            />
          </Grid>
          {adminUsers.length <= 0 ? (
            <div />
          ) : (
            <div>
              <Typography className={classes.title} gutterBottom component="h2">
                Usuarios administradores
              </Typography>
              <Grid container className={classes.root}>
                {adminUsers &&
                  adminUsers.map((user, index) => (
                    <UserItem
                      key={"USER_ADMIN_" + index}
                      user={user}
                      currentLock={currentLock}
                      removeUser={(user) => {
                        setItemToDelete(user);
                        setOpenDeleteDialog(true);
                      }}
                    ></UserItem>
                  ))}
              </Grid>
            </div>
          )}
          {guestUsers.length <= 0 ? (
            <div />
          ) : (
            <div>
              <Typography className={classes.title} gutterBottom component="h2">
                Usuarios invitados
              </Typography>
              <Grid container className={classes.root}>
                {guestUsers &&
                  guestUsers.map((user, index) => (
                    <UserItem
                      key={"USER_GUEST_" + index}
                      user={user}
                      currentLock={currentLock}
                      removeUser={(user) => {
                        setItemToDelete(user);
                        setOpenDeleteDialog(true);
                      }}
                    ></UserItem>
                  ))}
              </Grid>
            </div>
          )}
        </div>
      )}
      <Tooltip title="Agregar permiso" placement="left">
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => togglePermissionDrawer(true)}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <PermissionsDrawer
        open={openDrawer}
        togglePermissionDrawer={togglePermissionDrawer}
        currentLock={currentLock}
        lockUsers={lockUsers}
      />
      <DeleteAccessDialog
        handleClose={handleCloseDeleteAccess}
        open={openDeleteDialog}
        handleDeleteItem={(e, access) => handleDeleteAction(access)}
        itemToDelete={itemToDelete}
      />
    </div>
  );
};

class Users extends Component {
  render(props) {
    return <UsersSection {...this.props} />;
  }

  componentDidMount() {
    this.props.getUserLocks();
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    userLocks: state[NAME].userLocks,
    lockUsers: state[NAME].lockUsers,
    isLoading: state[NAME].isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLockUsers: (lockId) => dispatch(getLockUsers(lockId)),
    getUserLocks: () => dispatch(getUserLocks()),
    removePermissionForUser: (lockId, userId) =>
      dispatch(removePermissionForUser(lockId, userId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
