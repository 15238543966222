import { createTheme } from "@material-ui/core/styles";

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      dark: 'rgba(82, 201, 244, 0.3)'
    },
    secondary: {
      main: 'rgba(64, 64, 66, 0.7)',
      dark: "#404042"
    },
    button: {
      background: 'rgba(82, 201, 244, 0.3)',
      text: '#52c9f4'
    },
    buttonCancel: {
      background: 'rgba(64, 64, 66, 0.7)',
      text: '#404042'
    },
    spinner: {
      color: '#52c9f4'
    },
    inputText: {
      borderColor: 'rgba(64, 64, 66, 0.7)',
      background: 'rgba(64, 64, 66, 0.7)',
      text: 'rgba(64, 64, 66, 1)'
    }

  },
});
