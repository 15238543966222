import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";


function getUser(activity) {
  return activity != null ? activity.user.email : "";
}

function getEvent(activity) {
  return activity != null ? activity.event : "";
}


const columns = [
  {
    id: "event",
    label: "Evento",
    minWidth: 100,
    align: "left",
    format: (value) => {
      return getEvent(value);
    },
  },
  {
    id: "date",
    label: "Fecha",
    minWidth: 100,
    align: "center",
    format: (value) => {
      var ts = new Intl.DateTimeFormat("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(value);
      return ts;
    },
  },
  {
    id: "date",
    label: "Hora",
    minWidth: 100,
    align: "center",
    format: (value) => {
      var ts = new Intl.DateTimeFormat("es-AR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(value);
      return ts;
    },
  },
  {
    id: "email",
    label: "Email",
    minWidth: 100,
    align: "center",
    format: (value) => {
      return getUser(value);
    },
  }
];

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginLeft: 0,
    marginRight: 0,
    minWidth: 120,
  },
  tableWrapper: {
    overflow: "auto",
  },
  container: {
    backgroundColor: "red",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  downloadStyle: {
    width: "100%",
  },
});

const ActivityTableSection = ({
  isLoading,
  controlPointActivity,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [activity, setLockActivity] = React.useState(Object.values([]));
  const [newActivity, setNewActivity] = React.useState([]);

  React.useEffect(() => {
    if (controlPointActivity !== null) {
        setLockActivity(controlPointActivity);
    }
  }, [controlPointActivity]);

  React.useEffect(() => {
    if (activity != null) {
        const values = activity.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          );
          setNewActivity(values);
    }
  }, [activity, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return isLoading ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress className={classes.spinnerStyle} />
    </div>
  ) : (
    <div>
      {/* <Grid container justifyContent="flex-end">
        <CustomExcelExport
          className={classes.downloadStyle}
          isLoading={isLoading}
          lockActivity={lockActivity}
          lockName={currentLock.deviceName}
        ></CustomExcelExport>
      </Grid> */}

      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id + index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newActivity.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.date + index}
                  >
                    {columns.map((column) => {
                      const value =
                        column.id === "event" || column.id === "email"
                          ? row
                          : row[column.id];
                      return (
                        <TableCell
                          key={column.label + index}
                          align={column.align}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={activity != null? activity.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const ControlPointActivityTable = ({
  isLoading,
  activity,
}) => {
  return (
    <ActivityTableSection
      isLoading={isLoading}
      controlPointActivity={activity}
    />
  );
};

export default connect(null, null)(ControlPointActivityTable);
