import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import MainContainer from "./components/MainContainer";
import store from "./providers/redux/store";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { customTheme } from "./providers/theme/customTheme";

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <Provider store={store}>
        <div className="CittyoApp">
          <Router>
            <MainContainer></MainContainer>
          </Router>
        </div>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
