import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SideBar from "./SideBar";
import CittyoLogo from "../../assets/topbar/cittyo_small_logo.svg";

const NavBar = (props) => {
  const classes = useStyles();
  const [auth] = React.useState(true);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [userInfo, setUser] = React.useState(props.userInfo);

  useEffect(() => {
    setUser(props.userInfo);
  }, [props.userInfo]);

  function handleDrawerOpen() {
    setOpenDrawer(true);
  }

  function handleDrawerClose() {
    setOpenDrawer(false);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.topBar}>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
          <img alt="" variant="h6" className={classes.logo} src={CittyoLogo} />
          {auth && <div className={classes.accountButton}></div>}
        </Toolbar>
      </AppBar>

      <SideBar
        userInfo={userInfo}
        open={openDrawer}
        handleClose={handleDrawerClose}
      />
    </div>
  );
};

export default NavBar;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  topBar: {
    minHeight: "50px",
    backgroundColor: "#ffffff",
    color: "#404042",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none!important",
    },
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
    },
    marginLeft: "0px",
    height: "30px",
  },
  accountButton: {
    flex: "10",
    textAlign: "right",
  },
}));
