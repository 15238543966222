// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { SIGN_IN, SIGN_OUT } from "./actionTypes";
// CONSTANTS
import { NAME } from "./constants";

const initialState = {
  isLogged: false,
  isLoading: false,
  username: null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === "persist/REHYDRATE" && action.payload) {
    state = set(state, "username", action.payload[NAME].username);
    state = set(state, "isLogged", action.payload[NAME].isLogged);
  }

  if (type === SIGN_IN) {
    state = set(state, "username", data.username || null);
    state = set(state, "isLogged", data.isLogged);
    state = set(state, "error", data.error || null);
    state = set(state, "isLoading", data.isLoading);
  }

  if (type === SIGN_OUT) {
    state = set(state, "username", null);
    state = set(state, "isLogged", false);
    state = set(state, "error", null);
    state = set(state, "isLoading", data.isLoading);
  }

  return state;
};

export default authReducer;
