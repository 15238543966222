import { SIGN_IN, SIGN_OUT } from "./actionTypes";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const action = {
      type: SIGN_IN,
      data: {
        username: credentials.email,
        isLogged: false,
        error: null,
        isLoading: true,
      },
    };
    dispatch(action);
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        action.data.isLogged = true;
        action.data.error = null;
        action.data.isLoading = false;
        dispatch(action);
      })
      .catch((err) => {
        action.data.isLogged = false;
        action.data.error = err;
        action.data.isLoading = false;
        dispatch(action);
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const action = {
      type: SIGN_OUT,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);
    firebase
      .auth()
      .signOut()
      .then(() => {
        action.data.isLogged = false;
        action.data.error = null;
        action.data.isLoading = false;
        dispatch(action);
      });
  };
};

export const setLogged = (auth) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: SIGN_IN,
      data: {
        username: auth.email,
        isLogged: true,
        error: null,
        isLoading: true,
      },
    };
    dispatch(action);
  };
};
