import { NAME } from "./constants";

export const GET_CONTROL_POINTS = NAME + "_GET_CONTROL_POINTS";
export const CREATE_CONTROL_POINT = NAME + "_CREATE_CONTROL_POINT";
export const UPDATE_CONTROL_POINT = NAME + "_UPDATE_CONTROL_POINT";
export const DELETE_CONTROL_POINT = NAME + "_DELETE_CONTROL_POINT";

export const GET_CONTROL_POINT_USERS = NAME + "_GET_CONTROL_POINT_USERS";
export const DELETE_CONTROL_POINT_USERS = NAME + "_DELETE_CONTROL_POINT_USERS";
export const ADD_USERS_TO_CONTROL_POINT = NAME + "_ADD_USERS_TO_CONTROL_POINT";


export const GET_CONTROL_POINT_ACTIVITY = NAME + "_GET_CONTROL_POINT_ACTIVITY";

export const GENERATE_NEW_QR_TOKEN = NAME + "_GENERATE_NEW_QR_TOKEN";
