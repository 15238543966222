import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { updateUserData } from "../Home/actions";

import UserAvatar from "../../assets/account/ic_avatar.svg";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    padding: "10px!important",
    paddingBottom: "0px!important",
  },
  cardContent: {
    width: "100%",
    minWidth: "300px",
    margin: "20px",
    color: "white!important",
    background: "white",
  },
  contentSection: {
    flexGrow: 1,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  stepsSection: {
    flex: "10",
  },
  stepsSectionTwo: {
    flexGrow: "1",
    flex: "1",
  },
  leftContent: {
    minWidth: "300px",
    flex: "1",
    margin: "30px",
    alignItems: "center!important",
    justifyContent: "center!important",
  },
  divider: {
    maxHeight: "2px",
    marginTop: "20px",
    marginBottom: "20px",
    opacity: "0.1",
    background: "#868689",
    height: "1px!important",
    marginLeft: "30px",
    marginRight: "30px",
  },
  rightContent: {
    minWidth: "300px",
    margin: "30px",
    flex: "1",
    color: "black",
  },
  title: {
    color: "#404042",
    fontSize: "20pt",
    fontWeight: "bold",
    margin: "0",
  },
  avatar: {
    marginTop: "50px",
    width: "180px!important",
    height: "180px!important",
    borderRadius: "50%",
    borderColor: "rgba(65,65,65,0.1)",
    borderWidth: "5px",
    borderStyle: "solid",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
  },
  name: {
    padding: "0px!important",
    margin: "0px!important",
    marginTop: "45px!important",
    color: "#231f20!important",
    textAlign: "center",
    fontSize: "18pt",
    fontWeight: "bold",
  },
  email: {
    padding: "0px!important",
    margin: "0px!important",
    marginTop: "10px!important",
    color: "#231f20!important",
    textAlign: "center",
    fontSize: "16pt",
  },
  resetPassword: {
    padding: "0px!important",
    margin: "0px!important",
    marginTop: "30px!important",
    color: "#55c9f4!important",
    textAlign: "center",
    fontSize: "18pt",
    opacity: "1",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.3",
    },
  },
  deleteAccount: {
    padding: "0px!important",
    margin: "0px!important",
    marginTop: "30px!important",
    color: "#ef4445!important",
    textAlign: "center",
    fontSize: "18pt",
    opacity: "1",
    fontWeight: "bold",
    "&:hover": {
      cursor: "pointer",
      opacity: "0.3",
    },
  },
  textField: {
    marginTop: 20,
    width: "100%",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
  },
  button: {
    width: "100px",
    alignItems: "flex-end",
    marginTop: "50px",
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#55c9f4!important",
      color: "#ffffff!important",
    },
  },
  spinnerStyle: {
    marginTop: "50px",
    color: "#55c9f4",
  },
}));

const Account = (props) => {
  const { userInfo, isLoading, updateUserData } = props;
  const [user, setUser] = useState(userInfo);
  const classes = useStyles();

  useEffect(() => {
    setUser(userInfo);
  }, [userInfo]);

  function handleChange(e) {
    setUser({ ...user, [e.target.id]: e.target.value });
  }

  function handleUpdateButtonClick() {
    if (user && user.firstName !== "" && user.lastName !== "") {
      updateUserData({
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        address: user.address,
      });
    } else {
      toast.error("Complete nombre y apellido");
    }
  }

  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true}></ToastContainer>
      <Card className={classes.cardContent}>
        <Grid container className={classes.contentSection}>
          <Grid item xs={12} md={12} className={classes.stepsSection}>
            <Grid container className={classes.stepsSectionTwo}>
              <Grid item md={6} xs={12} className={classes.leftContent}>
                <p className={classes.title}>Mi Cuenta</p>
                <Avatar
                  alt="Default Avatar"
                  className={classes.avatar}
                  src={
                    userInfo && userInfo.userPictureUrl
                      ? userInfo.userPictureUrl
                      : UserAvatar
                  }
                  onError={(e) => {
                    e.target.src = UserAvatar;
                  }}
                />
                <p className={classes.name}>
                  {userInfo && userInfo.firstName && userInfo.lastName
                    ? userInfo.firstName + " " + userInfo.lastName
                    : ""}
                </p>
                <p className={classes.email}>
                  {userInfo && userInfo.email ? userInfo.email : ""}
                </p>
              </Grid>
              <Grid item md={6} xs={12} className={classes.rightContent}>
                <TextField
                  id="firstName"
                  label="Nombre"
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  defaultValue={
                    userInfo && userInfo.firstName ? userInfo.firstName : ""
                  }
                />

                <TextField
                  id="lastName"
                  label="Apellido"
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  defaultValue={
                    userInfo && userInfo.lastName ? userInfo.lastName : "-"
                  }
                />

                <TextField
                  id="address"
                  label="Dirección"
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  defaultValue={
                    userInfo && userInfo.address ? userInfo.address : ""
                  }
                />

                <TextField
                  id="phoneNumber"
                  label="Teléfono"
                  className={classes.textField}
                  variant="outlined"
                  onChange={handleChange}
                  defaultValue={
                    userInfo && userInfo.phoneNumber ? userInfo.phoneNumber : ""
                  }
                />
                <div className={classes.buttonContainer}>
                  {isLoading ? (
                    <CircularProgress className={classes.spinnerStyle} />
                  ) : (
                    <Button
                      className={classes.button}
                      onClick={() => handleUpdateButtonClick()}
                    >
                      Actualizar
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    updateUserData: (user) => dispatch(updateUserData(user)),
  };
}

export default connect(null, mapDispatchToProps)(Account);
