import React from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  button: {
    height: "30px",
    backgroundColor: "#55c955",
    color: "#ffffff",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "#88ea88!important",
      color: "#ffffff!important",
    },
    marginRight: "50px",
    marginTop: "10px",
  },
}));

const UsersExcelExport = ({ isLoading, lockUsers, lockName }) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState(lockName);
  const [users, setUsers] = React.useState(lockUsers ? lockUsers : []);

  React.useEffect(() => {
    function getCurrentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      return mm + "/" + dd + "/" + yyyy;
    }

    const file = "cittyo_usuarios_" + lockName + "_" + getCurrentDate();
    setFileName(file);
  }, [lockName]);

  React.useEffect(() => {
    async function convertDataToExcel(lockUsers) {
      var result = [];
      for (var i = 0; i < lockUsers.length; i++) {
        var role = await getAccessType(lockUsers[i].role);
        var displayedName = await getDisplayedName(lockUsers[i]);
        var date = await getSinceDate(lockUsers[i].createdTimeStamp);

        const item = {
          date: date,
          role: role,
          displayedName: displayedName,
        };
        result.push(item);
      }
      setUsers(result);
    }

    async function getSinceDate(value) {
      var ts = new Intl.DateTimeFormat("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      }).format(value);
      return ts;
    }

    async function getAccessType(role) {
      if (role === "OWNER") {
        return "Owner";
      }
      if (role === "ADMIN") {
        return "Admin";
      }
      if (role === "GUEST_VISITOR") {
        return "Visitante";
      }
      if (role === "GUEST_WORKER") {
        return "Trabajador";
      }
      return "-";
    }

    async function getDisplayedName(user) {
      var firstName = user.firstName;
      if (firstName === undefined) {
        firstName = "";
      }
      var lastName = user.lastName;
      if (lastName === undefined) {
        lastName = "";
      }
      return lastName + " " + firstName;
    }

    convertDataToExcel(lockUsers ? lockUsers : []);
  }, [lockUsers]);

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <ExcelFile
          filename={fileName}
          element={
            <Button className={classes.button}>Descargar Usuarios</Button>
          }
        >
          <ExcelSheet data={users} name="Usuarios">
            <ExcelColumn label="Rol" value="role" />
            <ExcelColumn label="Usuario" value="displayedName" />
            <ExcelColumn label="Activo desde" value="date" />
          </ExcelSheet>
        </ExcelFile>
      )}
    </div>
  );
};

export default UsersExcelExport;
