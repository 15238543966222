import Drawer from "@material-ui/core/Drawer";
import "date-fns";

import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { NAME, FREE_PASS, GUEST_VISITOR, GUEST_WORKER } from "./constants";

import { createDeviceAccess } from "./actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GuestAccess from "../../components/access/GuestAccess";
import WorkerAccess from "../../components/access/WorkerAccess";
import FastPassAccess from "../../components/access/FastPassAccess";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#52c9f4",
    },
    secondary: {
      main: "#52c9f4",
    },
  },
});

const drawerWidth = 600;

const useStyles = makeStyles((theme) => ({
  drawer: {
    marginTop: "65px",
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    overflowY: "auto",
  },
  drawerPaper: {
    backgroundColor: "#fafafc",
    width: drawerWidth,
  },
  mainContainer: {
    backgroundColor: "#fafafc",
    height: "100vh",
  },
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  titleLock: {
    textTransform: "capitalize",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,1)",
    fontWeight: "bold",
    fontSize: "16pt",
    paddingTop: "30px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  title: {
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    padding: "50px",
    paddingTop: "5px",
    autoHeight: "true",
    width: "100%",
  },
  selected: {
    background: "white",
    backgroundColor: "white",
    borderStyle: "none!important",
    textTransform: "capitalize",
    "&:hover": {
      background: "white",
      backgroundColor: "white",
      borderStyle: "none!important",
    },
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  titleUsers: {
    marginTop: "10px",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    height: "20px",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "10px",
  },
  buttonContainer: {
    backgroundColor: "#fafafc",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
  button: {
    height: "50px",
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "#55c9f4!important",
      color: "#ffffff!important",
    },
  },
  buttonCancel: {
    height: "50px",
    marginRight: "50px",
    width: "120px",
    backgroundColor: "#ef4445",
    color: "#ffffff",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "rgba(239, 68, 69, 0.3)",
      color: "#ffffff!important",
    },
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
  },
  textField: {
    width: "60%",
    backgroundColor: "white",
  },
  cardContainer: {
    padding: 20,
    textAlign: "center",
  },
}));

const permissionType = ["Free pass", "Visitante", "Trabajador"];

const KeyPermissionSection = ({
  isLoading,
  open,
  togglePermissionDrawer,
  currentLock, // Lock selected
  createDeviceAccess, // Api call
  handleOnSuccess, // Callback to the other component
}) => {
  const classes = useStyles();

  const accessFormInitialState = {
    deviceSerialNumber: currentLock ? currentLock.key : "",
    guestName: "",
    accessDescription: "",
    accessCode: "",
    accessType: "link",
  };

  const [accessForm, setAccessForm] = React.useState(accessFormInitialState);
  const [permission, setPermission] = React.useState(null);
  const [temporaryType, setTemporaryType] = React.useState(FREE_PASS);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    if (currentLock) {
      // Reset form if current lock has changed
      resetVariables();
    }
  }, [currentLock]);

  function resetVariables() {
    // Reset form if current lock has changed
    setPermission(null);
    setTemporaryType(FREE_PASS);
    setAccessForm(accessFormInitialState);
  }
  function handleChangePermissionType(event) {
    const type = event.target.value;
    setTemporaryType(type);
    if (type == FREE_PASS) {
      setPermission(null);
    }
  }

  function handleGuestAccessUpdated(permission) {
    setPermission(permission);
  }

  function handleWorkerAccessUpdated(permission) {
    setPermission(permission);
  }

  function handleChangeTextFieldInformation(e) {
    if (accessForm.hasOwnProperty(e.target.id)) {
      accessForm[e.target.id] = e.target.value;
    }
  }

  function createPermission() {
    // Validate access information
    if (accessForm.guestName.length < 6 || accessForm.guestName.length > 50) {
      toast.error(
        "El nombre de invitado tiene que tener entre 6 y 50 caracteres"
      );
      return;
    }
    if (
      accessForm.accessDescription.length < 6 ||
      accessForm.accessDescription.length > 50
    ) {
      toast.error(
        "La descripción del permiso tiene que tener entre 6 y 50 caracteres"
      );
      return;
    }
    if (accessForm.accessCode.length < 6 || accessForm.accessCode.length > 50) {
      toast.error(
        "El código de acceso tiene que tener entre 6 y 50 caracteres"
      );
      return;
    }

    const type =
      temporaryType == FREE_PASS
        ? "FREE_PASS"
        : temporaryType == GUEST_VISITOR
        ? "GUEST"
        : temporaryType == GUEST_WORKER
        ? "WORKER"
        : "UNKNOWN";
    const permissionInfo = { ...permission, type: type };
    const newAccess = { ...accessForm, temporaryAccess: permissionInfo };

    //Create the new access with expiration dates.
    createDeviceAccess(newAccess, handleAPISucess, handleAPIOnError);
  }

  function handleAPIOnError(error) {
    toast.error(error);
  }

  function handleAPISucess(data) {
    resetVariables();
    togglePermissionDrawer(false);
    handleOnSuccess();
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={customTheme}>
        <Drawer
          open={open}
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.mainContainer}>
            <Typography
              className={classes.titleLock}
              gutterBottom
              component="h2"
            >
              {currentLock ? currentLock.deviceName : "-"}
            </Typography>
            <div className={classes.cardContainer}>
              <TextField
                className={classes.textField}
                inputProps={{ maxLength: 50, minlenght: 6 }}
                required
                margin="dense"
                id="guestName"
                label="Nombre del invitado"
                type="text"
                variant="outlined"
                defaultValue={""}
                color="secondary"
                fullWidth
                onChange={handleChangeTextFieldInformation}
              />
              <TextField
                className={classes.textField}
                inputProps={{ maxLength: 50, minlenght: 6 }}
                required
                margin="dense"
                id="accessDescription"
                label="Descripción del permiso"
                type="text"
                variant="outlined"
                defaultValue={""}
                color="secondary"
                fullWidth
                onChange={handleChangeTextFieldInformation}
              />
              <TextField
                className={classes.textField}
                inputProps={{ maxLength: 50, minlenght: 6 }}
                required
                margin="dense"
                id="accessCode"
                label="Código de acceso"
                type="text"
                variant="outlined"
                defaultValue={""}
                color="secondary"
                fullWidth
                onChange={handleChangeTextFieldInformation}
              />
            </div>

            <Typography className={classes.title} gutterBottom component="h2">
              Tipo de Permiso
            </Typography>
            <div className={classes.mainContent}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel
                  ref={inputLabel}
                  htmlFor="outlined-age-native-simple"
                />
                <Select
                  native
                  value={temporaryType}
                  onChange={(e) => handleChangePermissionType(e)}
                  labelWidth={labelWidth}
                  inputProps={{
                    permission: "",
                    id: "age-native-helper",
                  }}
                  classes={{
                    root: classes.selected,
                  }}
                >
                  {permissionType &&
                    permissionType.map((option, index) => (
                      <option
                        className={classes.option}
                        key={index}
                        value={index}
                      >
                        {option}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </div>
            {temporaryType == FREE_PASS ? (
              <FastPassAccess></FastPassAccess>
            ) : temporaryType == GUEST_VISITOR ? (
              <GuestAccess
                handleGuestAccessUpdated={handleGuestAccessUpdated}
                hasPassword={false}
                link={true}
              ></GuestAccess>
            ) : temporaryType == GUEST_WORKER ? (
              <WorkerAccess
                handleWorkerAccessUpdated={handleWorkerAccessUpdated}
                hasPassword={false}
              ></WorkerAccess>
            ) : (
              <></>
            )}

            <div>
              <div className={classes.buttonContainer}>
                {isLoading ? (
                  <div className={classes.spinnerContainer}>
                    <CircularProgress className={classes.spinnerStyle} />
                  </div>
                ) : (
                  <div>
                    <Button
                      className={classes.buttonCancel}
                      onClick={() => togglePermissionDrawer(false)}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className={classes.button}
                      onClick={() => createPermission()}
                    >
                      Crear permiso!
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Drawer>
      </ThemeProvider>
    </React.Fragment>
  );
};

class KeyPermissionsDrawer extends Component {
  render(props) {
    return <KeyPermissionSection {...this.props} />;
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    isLoading: state[NAME].isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createDeviceAccess: (access, handleAPISucess, handleAPIOnError) =>
      dispatch(createDeviceAccess(access, handleAPISucess, handleAPIOnError)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyPermissionsDrawer);
