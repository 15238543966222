// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { GET_USER_LOCKS, GET_LOCK_INFORMATION, REQUEST_DEVICE_ACCESS } from "./actionTypes";

const initialState = {
  userLocks: [],
  lockInfo: null,
  isLoading: false,
};

const lockKeysReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_USER_LOCKS) {
    state = set(state, "userLocks", data.userLocks);
    state = set(state, "isLoading", data.isLoading);
  }

  if (type === GET_LOCK_INFORMATION) {
    state = set(state, "lockInfo", data.lockInfo);
  }

  if (type === REQUEST_DEVICE_ACCESS) {
    state = set(state, "accessList", data.accessList || []);
    state = set(state, "isLoading", data.isLoading || false);
    state = set(state, "error", data.error || null);
  }

  return state;
};

export default lockKeysReducer;
