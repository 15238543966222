import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Typography } from "@material-ui/core";

const DAYS = [
  {
    key: "sunday",
    label: "S",
  },
  {
    key: "monday",
    label: "M",
  },
  {
    key: "tuesday",
    label: "T",
  },
  {
    key: "wednesday",
    label: "W",
  },
  {
    key: "thursday",
    label: "T",
  },
  {
    key: "friday",
    label: "F",
  },
  {
    key: "saturday",
    label: "S",
  },
];

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1),
    "&:not(:first-child)": {
      border: "1px solid",
      borderColor: "#52c9f4",
      borderRadius: "50%",
    },
    "&:first-child": {
      border: "1px solid",
      borderColor: "#52c9f4",
      borderRadius: "50%",
    },
  },
}))(ToggleButtonGroup);

const StyledToggle = withStyles((theme) => ({
  root: {
    border: "1px solid",
    color: "#52c9f4",
    "&$selected": {
      color: "white",
      background: "#52c9f4",
      borderColor: "#52c9f4",
    },
    "&:hover": {
      color: "#52c9f4",
      borderColor: "#52c9f4",
      background: "rgba(82, 201, 244, 0.3)",
    },
    "&:hover$selected": {
      borderColor: "#52c9f4",
      background: "rgba(82, 201, 244, 0.3)",
    },
    width: 32,
    height: 32,
  },
  selected: {},
}))(ToggleButton);

const useStyles = makeStyles((theme) => ({
  dow: {
    justifyContent: "center",
    width: "100%",
  },
}));

const ToggleDays = ({ handleDaysUpdated }) => {
  const classes = useStyles();
  const [days, setDays] = useState([1, 2, 3, 4, 5]);

  //propagate state to parent component
  React.useEffect(() => {
    handleDaysUpdated(days);
  }, [days]);

  return (
    <div>
      <StyledToggleButtonGroup
        size="small"
        arial-label="Days of the week"
        value={days}
        className={classes.dow}
        onChange={(event, value) => setDays(value)}
      >
        {DAYS.map((day, index) => (
          <StyledToggle
            key={day.key + index}
            value={index}
            aria-label={day.key}
          >
            {day.label}
          </StyledToggle>
        ))}
      </StyledToggleButtonGroup>
    </div>
  );
};

export default ToggleDays;
