import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ToggleDays from "./ToggleDays";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

import IconWorker from "../../assets/permission/worker_icon.svg";
import IconCheckmark from "../../assets/permission/checkmark.svg";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  adminCard: {
    marginLeft: "50px",
    marginRight: "50px",
    backgroundColor: "white",
    padding: "5px",
  },
  permissionCardTitle: {
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    paddingTop: "10px",
    paddingLeft: "10px",
    marginBottom: "0px",
  },
  permissionCardDescription: {
    color: "black",
    fontSize: "10pt",
    paddingLeft: "20px",
    marginBottom: "10px",
    paddingBottom: "10px",
  },
  cardTitleIcon: {
    widh: "30px",
    height: "30px",
    marginTop: "10px",
    marginLeft: "10px",
    top: "0px",
    justifyContent: "center",
  },
  checkMarkIconGrid: {
    display: "flex",
    justifyContent: "center",
  },
  checkMarkIcon: {
    width: "20px",
    height: "20px",
    top: "0px",
    justifyContent: "center",
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  passwordSectionGrid: {
    display: "flex",
    justifyContent: "center",
  },
  passwordSection: {
    marginTop: "10px",
    marginBottom: "10px",
    minWidth: "250px",
    width: "50%",
  },
}));

// this kint of access never expires. User needs to removed manually
export default function WorkerAccess({
  handleWorkerAccessUpdated,
  handleChangePassword,
  hasPassword = false
}) {
  const classes = useStyles();
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [days, setDays] = React.useState([1, 2, 3, 4, 5]);

  React.useEffect(() => {
    const startTimeMinutes = buildHourInMinutes(startTime);
    const endTimeMinutes = buildHourInMinutes(endTime);

    if (startTimeMinutes > endTimeMinutes) {
      toast.error("El horario de ingreso debe ser anterior al horario de salida.");
      setEndTime(startTime);
    } else {
      handleWorkerAccessUpdated({
        days: days,
        fromTime: startTimeMinutes,
        toTime: endTimeMinutes,
      });
    }
  }, [startTime, endTime, days]);

  const handleDaysUpdated = (days) => {
    setDays(days);
  };

  const buildHourInMinutes = (dateTime) => {
    return dateTime.getHours() * 60 + dateTime.getMinutes();
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.adminCard}>
        <Grid container>
          <Grid item xs={1} md={1} className={classes.checkMarkIconGrid}>
            <img className={classes.cardTitleIcon} alt="" src={IconWorker} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Trabajador
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography
              className={classes.permissionCardDescription}
              component="h3"
            >
              Tiene acceso días específicos de la semana durante horas
              específicas.
            </Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <img className={classes.checkMarkIcon} alt="" src={IconCheckmark} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Días de la semana
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className={classes.toggleContainer}>
              <ToggleDays handleDaysUpdated={handleDaysUpdated} />
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Horarios
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerContainer}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker-start"
              label="DESDE"
              value={startTime}
              onChange={setStartTime}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerContainer}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker-end"
              label="HASTA"
              value={endTime}
              onChange={setEndTime}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
{
  hasPassword ?  <>
  <Grid item xs={12} md={12}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Password Administrador:
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} className={classes.passwordSectionGrid}>
            <TextField
              required
              id="passwordAdmin"
              label="Password Admin"
              type="password"
              className={classes.passwordSection}
              autoComplete="current-password"
              variant="outlined"
              onChange={handleChangePassword}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Ingrese un nuevo password de Invitado
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} className={classes.passwordSectionGrid}>
            <TextField
              required
              id="passwordGuest"
              label="Password Invitado"
              type="password"
              className={classes.passwordSection}
              autoComplete="current-password"
              variant="outlined"
              onChange={handleChangePassword}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
  </> : <></>
}
          
        </Grid>
      </Card>
    </MuiPickersUtilsProvider>
  );
}
