import React, { Component } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
  },
  gridCard: {
    borderRadius: 1,
    margin: "2px",
    padding: "10px!important",
  },
  cardContent: {
    textTransform: "none",
    color: "#424143",
    fontSize: "10pt",
    marginLeft: "10px",
  },
  cardTitle: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    fontSize: "12pt",
    marginLeft: 10,
    marginBottom: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  cardItemTitle: {
    color: "#000000",
    fontWeight: "bold",
    fontSize: "11pt",
    marginLeft: 10,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  itemText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#000000",
    fontSize: "10pt",
    marginLeft: 10,
    marginRight: 10,
  },
  centerGrid: {
    alignSelf: "center",
  },
  actionButton: {
    textTransform: "capitalize!important",
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 5,
    marginRight: 5,
    width: "80%",
    height: 30,
    backgroundColor: theme.palette.button.background,
    color: theme.palette.button.text,
    fontSize: 12,
    fontWeight: "bold",
    "&:hover": {
      background: theme.palette.button.text,
      color: "#ffffff",
    },
  },
  buttonContainer: {
    alignSelf: "center",
    alignItems: "center",
  },
  iconButton: {
    height: 30,
    width: 30,
  },
  temporaryType: {
    height: 10,
    marginLeft: 10,
  },
}));

const AccessItem = (props) => {
  var { device, isLoading, access, handleDeleteAction, handleOnLinkCopied } =
    props;
  var { handleItemClick } = props;
  const classes = useStyles();

  const date = parseInt(access.date);
  const dateObject = new Date(date);

  var type = "/key/";
  if (access.temporaryAccess.type.toUpperCase() === "DOOR_BELL") {
    type = "/doorbell/";
  }

  const accessURL =
    window.location.protocol +
    "//" +
    window.location.host +
    type +
    access._id +
    "/" +
    access.accessToken.replaceAll(".", "/");

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    handleOnLinkCopied();
  };

  const getTemporaryAccessView = (temporaryAccess) => {
    return (
      <Grid container justiy="center">
        <Grid item xs={4} sm={3} className={classes.centerGrid}>
          <Typography className={classes.cardItemTitle} component="h2">
            Access type:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} className={classes.centerGrid}>
          {temporaryAccess.type == "FREE_PASS" ? (
            <img
              className={classes.temporaryType}
              alt={""}
              src={require("../assets/keys/ic_free_pass_tag.SVG").default}
            />
          ) : temporaryAccess.type == "GUEST" ? (
            <img
              className={classes.temporaryType}
              alt={""}
              src={require("../assets/keys/ic_guest_tag.SVG").default}
            />
          ) : temporaryAccess.type == "WORKER" ? (
            <img
              className={classes.temporaryType}
              alt={""}
              src={require("../assets/keys/ic_worker_tag.SVG").default}
            />
          ) : (
            <Typography className={classes.itemText} component="h2">
              {temporaryAccess.type}
            </Typography>
          )}
        </Grid>
        {temporaryAccess.type === "GUEST" ? (
          getTemporaryGuestView(temporaryAccess)
        ) : temporaryAccess.type === "WORKER" ? (
          getTemporaryWorkerView(temporaryAccess)
        ) : (
          <div />
        )}
      </Grid>
    );
  };

  const getTemporaryGuestView = (temporaryAccess) => {
    return (
      <Grid container>
        <Grid item xs={4} sm={3} className={classes.centerGrid}>
          <Typography className={classes.cardItemTitle} component="h2">
            From date:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} className={classes.centerGrid}>
          <Typography className={classes.itemText} component="h2">
            {getDate(temporaryAccess.fromDate)}
          </Typography>
        </Grid>

        <Grid item xs={4} sm={3} className={classes.centerGrid}>
          <Typography className={classes.cardItemTitle} component="h2">
            To date:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} className={classes.centerGrid}>
          <Typography className={classes.itemText} component="h2">
            {getDate(temporaryAccess.toDate)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getTemporaryWorkerView = (temporaryAccess) => {
    return (
      <Grid container>
        <Grid item xs={4} sm={3} className={classes.centerGrid}>
          <Typography className={classes.cardItemTitle} component="h2">
            Days:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} className={classes.centerGrid}>
          {getDaysOfWeek(temporaryAccess)}
        </Grid>
        <Grid item xs={4} sm={3} className={classes.centerGrid}>
          <Typography className={classes.cardItemTitle} component="h2">
            From time:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} className={classes.centerGrid}>
          <Typography className={classes.itemText} component="h2">
            {getTime(temporaryAccess.fromTime)}
          </Typography>
        </Grid>

        <Grid item xs={4} sm={3} className={classes.centerGrid}>
          <Typography className={classes.cardItemTitle} component="h2">
            To time:
          </Typography>
        </Grid>
        <Grid item xs={8} sm={9} className={classes.centerGrid}>
          <Typography className={classes.itemText} component="h2">
            {getTime(temporaryAccess.toTime)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getTime = (timeInMinutes) => {
    const hours = parseInt(Math.floor(timeInMinutes / 60));
    const minutes = parseInt((timeInMinutes / 60 - hours) * 60);
    const time =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0");
    return time;
  };

  const getDate = (dateInmseconds) => {
    const date = dateInmseconds * 1000;
    const newDate = new Date(date);
    const time =
      (newDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      newDate.getDate().toString().padStart(2, "0") +
      "/" +
      newDate.getFullYear().toString() +
      "," +
      newDate.getHours().toString().padStart(2, "0") +
      ":" +
      newDate.getMinutes().toString().padStart(2, "0");
    return time;
  };

  const getDaysOfWeek = (temporaryAccess) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var daysOfWeek = "";
    temporaryAccess.days.map((day, key) => {
      if (key > 0) {
        daysOfWeek = daysOfWeek + ", ";
      }
      daysOfWeek = daysOfWeek + days[day];
    });
    return (
      <Typography className={classes.itemText} component="h2">
        {daysOfWeek}
      </Typography>
    );
  };

  return (
    <Grid container className={classes.container} justifyContent="center" spacing={1}>
      <Grid item xs={11} sm={11}>
        <Card
          className={classes.gridCard}
          onClick={(e) => handleItemClick(e, device, access)}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={12} className={classes.centerGrid}>
                  <Typography className={classes.cardTitle} component="h2">
                    {access.guestName}
                  </Typography>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid container justiy="center">
                <Grid item xs={4} sm={3} className={classes.centerGrid}>
                  <Typography className={classes.cardItemTitle} component="h2">
                    Description:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9} className={classes.centerGrid}>
                  <Typography className={classes.itemText} component="h2">
                    {access.accessDescription ? access.accessDescription : ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justiy="center">
                <Grid item xs={4} sm={3} className={classes.centerGrid}>
                  <Typography className={classes.cardItemTitle} component="h2">
                    Type:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9} className={classes.centerGrid}>
                  <Typography className={classes.itemText} component="h2">
                    {access.accessType.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justiy="center">
                <Grid item xs={4} sm={3} className={classes.centerGrid}>
                  <Typography className={classes.cardItemTitle} component="h2">
                    Created at:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9} className={classes.centerGrid}>
                  <Typography className={classes.itemText} component="h2">
                    {dateObject.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justiy="center">
                <Grid item xs={4} sm={3} className={classes.centerGrid}>
                  <Typography className={classes.cardItemTitle} component="h2">
                    Access link:
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={9} className={classes.centerGrid}>
                  <Typography className={classes.itemText} component="h2">
                    <a href={accessURL}>Probar Link</a>
                  </Typography>
                </Grid>
              </Grid>

              {access.temporaryAccess === null ||
              access.temporaryAccess === undefined ? (
                <div />
              ) : (
                getTemporaryAccessView(access.temporaryAccess)
              )}
            </Grid>

            {/* //buttons section */}
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={2} sm={1} className={classes.buttonContainer}>
                  <IconButton
                    className={classes.iconButton}
                    aria-label="delete"
                    onClick={(e) => handleDeleteAction(e, device, access)}
                  >
                    <img
                      className={classes.iconButton}
                      alt={""}
                      src={require("../assets/keys/delete.svg").default}
                    />
                  </IconButton>
                </Grid>

                <Grid item xs={2} sm={1} className={classes.buttonContainer}>
                  <IconButton
                    className={classes.iconButton}
                    aria-label="delete"
                    onClick={(e) => copyToClipboard(accessURL)}
                  >
                    <img
                      className={classes.iconButton}
                      alt={""}
                      src={require("../assets/keys/link.svg").default}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

class AccessItemListView extends Component {
  render() {
    const {
      list,
      device,
      isLoading,
      handleItemClick,
      handleDeleteAction,
      handleOnLinkCopied,
    } = this.props;

    return (
      <div>
        {list === undefined || list.length <= 0 ? (
          <div />
        ) : (
          list.map((value, index) => (
            <AccessItem
              style={{ padding: "0!important" }}
              key={`item-${index}`}
              index={index}
              device={device}
              access={value}
              handleItemClick={handleItemClick}
              handleDeleteAction={handleDeleteAction}
              handleOnLinkCopied={handleOnLinkCopied}
            />
          ))
        )}
      </div>
    );
  }
}

export default AccessItemListView;
