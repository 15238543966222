import React, { Component } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { NAME } from "./constants";
import { sendAction } from "./actions";
import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import UserSectionTopbar from "../../components/access/UserAccessTopBar";
import Lottie from "react-lottie";
import Animations from "../../utils/Animations";
import Session from "../../utils/Session";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "45px",
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  container: {
    marginTop: 50,
  },
  controlButton: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  bottomAnimation: {
    position: "absolute!important",
  },
  topAnimation: {
    width: "100%",
    left: 0,
    top: 0,
    position: "absolute",
  },
  cssFocused: {
    color: "#000!imporntat",
  },
  cssLabel: {
    color: "#000!important",
  },
  cssOutlinedInput: {
    color: "#000!important",
    borderColor: "#000!important",
  },
  notchedOutline: {
    color: "#000!important",
    borderColor: "#000!important",
  },
  textContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
    height: 30,
  },
  textField: {
    textAlign: "center",
    width: "100%",
    background: "#fafafc",
  },
}));

var states = {
  accessCode: "",
};

const KeyAccessSection = (props) => {
  const classes = useStyles();
  const { access, sendAction } = props;

  const [lockState, setLockState] = React.useState("closed");

  function open() {
    const params = {
      accessUID: access.accessUID,
      accessCode: Session.getAccessCode()
        ? Session.getAccessCode()
        : states.accessCode,
      actionUID: "open",
    };
    sendAction(params, access.accessToken, handleOnSuccess, handleOnError);
    setLockState("loading");
  }

  function handleOnSuccess(data) {
    if (!Session.getAccessCode()) {
      Session.setAccessCode(states.accessCode);
    }

    setLockState("open", data);
    setTimeout(() => {
      setLockState("closed");
    }, 4000);
  }

  function handleOnError(error) {
    toast.error(error);
    setLockState("closed");
    Session.clearAccessCode();
  }

  function getAnimation() {
    if (lockState === "closed") {
      return Animations.getLockOpenToClosedAnimation();
    }

    if (lockState === "open") {
      return Animations.getLockClosedtOpenAnimation();
    }

    if (lockState === "loading") {
      return Animations.getLockLoadingAnimation();
    }
  }

  function handleChange(e) {
    if (states.hasOwnProperty(e.target.id)) {
      states[e.target.id] = e.target.value;
    }
  }

  return (
    <div className={classes.root}>
      <ToastContainer hideProgressBar={true}></ToastContainer>
      <div>
        <UserSectionTopbar />
        <Grid container>
          <Grid item xs={1} sm={3} md={4} />
          <Grid item xs={10} sm={6} md={4}>
            {!Session.getAccessCode() ? (
              <div className={classes.textContainer}>
                <TextField
                  required
                  id="accessCode"
                  label="Access Code"
                  type="password"
                  autoComplete="password"
                  className={classes.textField}
                  variant="outlined"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }}
                />
              </div>
            ) : (
              <div className={classes.textContainer} />
            )}
          </Grid>
          <Grid item xs={1} sm={3} md={4} />
        </Grid>

        <Grid container className={classes.container}>
          <Grid item xs={2} sm={3} md={4} />
          <Grid item xs={8} sm={6} md={4}>
            <Button
              disableRipple
              className={classes.controlButton}
              style={{ backgroundColor: "transparent" }}
              onClick={() => open()}
            >
              <Lottie
                className={classes.topAnimation}
                options={getAnimation()}
              />
              <div className={classes.bottomAnimation}>
                <Lottie
                  isStopped={true}
                  isPaused={true}
                  options={Animations.getActionAnimation()}
                />
              </div>
            </Button>
          </Grid>
          <Grid item xs={2} sm={3} md={4} />
        </Grid>
      </div>
    </div>
  );
};

class KeyAccess extends Component {
  componentWillMount() {
    document.body.style.background = "#ffffff";
  }

  componentDidMount() {
    this.createManifest();
  }

  // This function creates a dinamyc manifest.json that allows a PWA app.
  // When the app is saved into the desktop on a mobile device it use this new manifest.json
  // to create teh PWA app using the Key start URL
  createManifest() {
    var myDynamicManifest = {
      short_name: "Cittyo control",
      name: "Cittyo smart access control.",
      icons: [
        {
          src: "icon.png",
          sizes: "64x64 32x32 24x24 16x16",
          type: "image/x-icon",
        },
        {
          src: "logo192.png",
          type: "image/png",
          sizes: "192x192",
        },
        {
          src: "logo512.png",
          type: "image/png",
          sizes: "512x512",
        },
      ],
      start_url: this.props.location.pathname,
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
    };

    const stringManifest = JSON.stringify(myDynamicManifest);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const manifestURL = URL.createObjectURL(blob);
    document
      .querySelector("#my-manifest-placeholder")
      .setAttribute("href", manifestURL);
  }

  getAccessParameters() {
    var pathParams = this.props.location.pathname.split("/");
    if (pathParams.length >= 6 && pathParams[1] === "key") {
      return {
        accessUID: pathParams[2],
        accessToken: pathParams[3] + "." + pathParams[4] + "." + pathParams[5],
      };
    }

    return undefined;
  }

  render() {
    const params = this.getAccessParameters();
    return (
      <KeyAccessSection access={params} sendAction={this.props.sendAction} />
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state[NAME].error,
    isLoading: state[NAME].isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendAction: (action, token, handleOnSuccess, handleOnError) =>
      dispatch(sendAction(action, token, handleOnSuccess, handleOnError)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyAccess);
