import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import DateFnsUtils from "@date-io/date-fns";
import "date-fns";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import IconVisitor from "../../assets/permission/visitor_icon.svg";
import IconCheckmark from "../../assets/permission/checkmark.svg";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  adminCard: {
    marginLeft: "50px",
    marginRight: "50px",
    backgroundColor: "white",
    padding: "5px",
  },
  permissionCardTitle: {
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    paddingTop: "10px",
    paddingLeft: "10px",
    marginBottom: "0px",
  },
  permissionCardDescription: {
    color: "black",
    fontSize: "10pt",
    paddingLeft: "20px",
    marginBottom: "10px",
    paddingBottom: "10px",
  },
  cardTitleIcon: {
    widh: "30px",
    height: "30px",
    marginTop: "10px",
    marginLeft: "10px",
    top: "0px",
    justifyContent: "center",
  },
  checkMarkIconGrid: {
    display: "flex",
    justifyContent: "center",
  },
  checkMarkIcon: {
    width: "20px",
    height: "20px",
    top: "0px",
    justifyContent: "center",
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  passwordSectionGrid: {
    display: "flex",
    justifyContent: "center",
  },
  passwordSection: {
    marginTop: "10px",
    marginBottom: "10px",
    minWidth: "250px",
    width: "50%",
  },
}));

// this kint of access never expires. User needs to removed manually
export default function GuestAccess({
  handleGuestAccessUpdated,
  handleChangePassword,
  hasPassword,
  link,
}) {
  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  React.useEffect(() => {
    const startDateUTC = link ? buildLinkTimeUTC(startDate) : startDate.getTime();
    const endDateUTC = link ? buildLinkTimeUTC(endDate) : endDate.getTime();

    if (startDateUTC > endDateUTC) {
      toast.error("Verifique los horarios y fechas ingresados. El horiario de ingreso no puede ser posterior al de egreso.");
      setEndDate(startDate);
    } else {
      handleGuestAccessUpdated({
        fromDate: startDateUTC,
        toDate: endDateUTC,
      });
    }
  }, [startDate, endDate]);

  /**
   * Converts to UTC Time for link
   * */
  const buildLinkTimeUTC = (dateTime) => {
    return Math.floor(dateTime.getTime() / 1000);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Card className={classes.adminCard}>
        <Grid container>
          <Grid item xs={1} md={1} className={classes.checkMarkIconGrid}>
            <img className={classes.cardTitleIcon} alt="" src={IconVisitor} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Visitante
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item xs={11} md={11}>
            <Typography
              className={classes.permissionCardDescription}
              component="h3"
            >
              Tiene acceso desde y hasta fecha y hora específicas.
            </Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <img className={classes.checkMarkIcon} alt="" src={IconCheckmark} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Check In
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerContainer}>
            <KeyboardDatePicker
              disableToolbar
              disablePast="true"
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline-start"
              label="DESDE LA FECHA"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerContainer}>
            <KeyboardTimePicker
              margin="normal"
              disablePast="true"
              id="time-picker-start"
              label="DESDE LA HORA"
              value={startDate}
              onChange={setStartDate}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography className={classes.permissionCardTitle} component="h2">
              Check Out
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerContainer}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              minDate={startDate}
              disablePast="true"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline-end"
              label="HASTA LA FECHA"
              value={endDate}
              onChange={setEndDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.datePickerContainer}>
            <KeyboardTimePicker
              margin="normal"
              disablePast="true"
              id="time-picker-end"
              label="HASTA LA HORA"
              value={endDate}
              onChange={setEndDate}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </Grid>
        </Grid>
        {hasPassword ? (
          <>
            <Grid item xs={12} md={12}>
              <Typography
                className={classes.permissionCardTitle}
                component="h2"
              >
                Password Administrador
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} className={classes.passwordSectionGrid}>
              <TextField
                required
                id="passwordAdmin"
                label="Password Admin"
                type="password"
                className={classes.passwordSection}
                autoComplete="current-password"
                variant="outlined"
                onChange={handleChangePassword}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                className={classes.permissionCardTitle}
                component="h2"
              >
                Ingrese un nuevo password de Invitado
              </Typography>
            </Grid>

            <Grid item xs={12} md={12} className={classes.passwordSectionGrid}>
              <TextField
                required
                id="passwordGuest"
                label="Password Invitado"
                type="password"
                className={classes.passwordSection}
                autoComplete="current-password"
                variant="outlined"
                onChange={handleChangePassword}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Card>
    </MuiPickersUtilsProvider>
  );
}
