import {
  GET_USER_LOCKS,
  GET_LOCK_INFORMATION,
  DELETE_DEVICE_ACCESS,
  REQUEST_DEVICE_ACCESS,
} from "./actionTypes";
import Firebase from "firebase/compat/app";
import { BASE_URL } from "../../utils/network";

export const getUserLocks = () => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_USER_LOCKS,
      data: {
        userLocks: [],
        isLoading: true,
      },
    };
    const firebase = getFirebase();

    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        let userDoorLocksRef = Firebase.database().ref(
          "/userDoorLocks/" + user.uid
        );
        userDoorLocksRef.on("value", (snapshot) => {
          var state = {};

          snapshot.forEach(function (childSnapshot) {
            const key = childSnapshot.key;
            const value = snapshot.child(key).child("role").val();
            //add only if user is owner or admin
            if (value === "OWNER" || value === "ADMIN") {
              state[key] = snapshot.child(key).val();
              state[key].key = key;
            }
          });

          const locks = sortLocks(state);
          action.data.userLocks = locks;
          action.data.isLoading = false;
          dispatch(action);
        });
      } else {
        // No user is signed in.
        action.data.isLoading = false;
        dispatch(action);
      }
    });
  };
};

function sortLocks(locks) {
  var l = Object.values(locks);
  if (l !== undefined && l.length > 1) {
    l.sort(function (a, b) {
      if (a.deviceName === undefined) {
        a.deviceName = "";
      }
      if (b.deviceName === undefined) {
        b.deviceName = "";
      }
      return a.deviceName.localeCompare(b.deviceName);
    });
  }

  if (l === undefined) {
    l = [];
  }
  return l;
}

export const getLockInformation = (lockId) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: GET_LOCK_INFORMATION,
      data: {
        lockInfo: null,
        isLoading: true,
      },
    };

    let lockRef = Firebase.database().ref("/doorLocks/" + lockId);

    lockRef.on("value", (snapshot) => {
      var lock = snapshot.val();
      lock.key = lockId;

      action.data.lockInfo = lock;
      action.data.isLoading = false;
      dispatch(action);
    });
  };
};

export const getDeviceAccess = (deviceSerialNumber, handleAPIOnError) => {
  return (dispatch, getState, { getFirebase }) => {
    const action = {
      type: REQUEST_DEVICE_ACCESS,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);

    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/link/list";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "device-serial-number": deviceSerialNumber,
            };

            fetch(apiUrl, {
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
              // body data type must match "Content-Type" header
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  action.data.accessList = [];
                  handleAPIOnError(data.error);
                } else {
                  //success
                  action.data.accessList = data.success;
                }

                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                //hadle error
                handleAPIOnError(err);
                action.data.accessList = [];
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.accessList = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};

export const removeDeviceAccess = (
  deviceSerialNumber,
  access,
  handleAPISuccess,
  handleAPIOnError
) => {
  return function (dispatch, getState, { getFirebase }) {
    const action = {
      type: DELETE_DEVICE_ACCESS,
      data: {
        isLoading: true,
      },
    };
    dispatch(action);
    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        user
          .getIdToken(true)
          .then((latestToken) => {
            const apiUrl = BASE_URL + "/api/v1/firebase/link/device/access";
            const headers = {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              "user-token": latestToken,
              "device-serial-number": deviceSerialNumber,
              "access-uid": access._id,
            };

            return fetch(apiUrl, {
              method: "DELETE", // *GET, POST, PUT, DELETE, etc.
              headers: headers,
            })
              .then((response) => response.json())
              .then((data) => {
                if (data.error) {
                  handleAPIOnError(data.error);
                } else {
                  //success
                  handleAPISuccess(data);
                }

                action.data.isLoading = false;
                dispatch(action);
              })
              .catch((err) => {
                //hadle error
                handleAPIOnError(err);
                action.data.isLoading = false;
                dispatch(action);
              });
          })
          .catch((err) => {
            handleAPIOnError(err);
            action.data.accessList = [];
            action.data.isLoading = false;
            dispatch(action);
          });
      }
    });
  };
};
