import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import ActivityTable from "./ActivityTable";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import { NAME } from "./constants";
import { getLockActivity, getUserLocks } from "./actions";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#52c9f4",
    },
    secondary: {
      main: "#52c9f4",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
  },
  selected: {
    background: "white",
    backgroundColor: "white",
    borderStyle: "none!important",
    textTransform: "capitalize",
    "&:hover": {
      background: "white",
      backgroundColor: "white",
      borderStyle: "none!important",
    },
  },
  option: {
    color: "black",
  },
  title: {
    backgroundColor: "#fafafc",
    textTransform: "capitalize",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "14pt",
    height: "30px",
    paddingTop: "20px",
    paddingLeft: "50px",
    marginBottom: "0px",
  },
  tableSection: {
    backgroundColor: "#fafafc",
    paddingBottom: "50px",
  },
}));

const ActivitySection = ({ userLocks }) => {
  const classes = useStyles();
  const [locks, setUserLocks] = React.useState(userLocks);
  const [selectedLockKey, selectLock] = React.useState(
    userLocks.count > 0 ? userLocks[0].key : ""
  );
  const [currentLock, setCurrentLock] = React.useState(
    userLocks.count > 0 ? userLocks[0] : { key: "" }
  );

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    setUserLocks(userLocks);
    if (userLocks.length > 0) {
      const firstLock = userLocks[0];
      setCurrentLock(firstLock);
      selectLock(firstLock.key);
    }
  }, [userLocks]);

  const handleNewLockSelected = (event) => {
    event.persist();
    const key = event.target.value;
    const result = locks.filter((lock) => lock.key === key);
    setCurrentLock(result[0]);
    selectLock(key);
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={customTheme}>
        <Typography className={classes.title} gutterBottom component="h2">
          Seleccionar Acceso
        </Typography>
        <div className={classes.mainContent}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple" />
            <Select
              native
              value={selectedLockKey}
              onChange={handleNewLockSelected}
              labelWidth={labelWidth}
              inputProps={{
                lock: "",
                id: "age-native-helper",
              }}
              classes={{
                root: classes.selected,
              }}
            >
              {locks &&
                locks.map((newLock, index) => (
                  <option
                    className={classes.option}
                    key={newLock.key}
                    value={newLock.key}
                  >
                    {newLock.deviceName}
                  </option>
                ))}
            </Select>
          </FormControl>
        </div>
        <Typography className={classes.title} gutterBottom component="h2">
          Actividad
        </Typography>
        <div className={classes.tableSection}>
          <ActivityTable currentLock={currentLock} />
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

class Activity extends Component {
  render(props) {
    return (
      <ActivitySection props={this.props} userLocks={this.props.userLocks} />
    );
  }

  componentDidMount() {
    this.props.getUserLocks();
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  return {
    lockActivity: state[NAME].lockActivity,
    userLocks: state[NAME].userLocks,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLockActivity: (lockId, quantity) =>
      dispatch(getLockActivity(lockId, quantity)),
    getUserLocks: () => dispatch(getUserLocks()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
