import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";
import ControlPointActivityTable from "./ControlPointActivityTable";

const customTheme = createTheme({
    palette: {
        primary: {
            main: "#52c9f4",
        },
        secondary: {
            main: "#52c9f4",
        },
    },
});

const useStyles = makeStyles((theme) => ({
    mainContent: {
        backgroundColor: "#fafafc",
        padding: "10px",
        paddingLeft: "50px",
        paddingRight: "50px",
    },
    title: {
        backgroundColor: "#fafafc",
        textTransform: "capitalize",
        color: "rgba(66,64,64,0.5)",
        fontWeight: "bold",
        fontSize: "14pt",
        paddingLeft: 10,
        marginBottom: "0px",
    },
    tableSection: {
        backgroundColor: "#fafafc",
        paddingBottom: "50px",
    },
}));

const ControlPointActivitySection = ({ isLoading, activity }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <ThemeProvider theme={customTheme}>
                <Typography className={classes.title} gutterBottom component="h2">
                    Actividad
                </Typography>
                <div className={classes.tableSection}>
                    <ControlPointActivityTable
                        isLoading={isLoading}
                        activity={activity}
                    ></ControlPointActivityTable>
                </div>
            </ThemeProvider>
        </React.Fragment>
    );
};

class ControlPointActivity extends Component {
    render(props) {
        return (
            <ControlPointActivitySection {...this.props} />
        );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export default connect(null, null)(ControlPointActivity);
