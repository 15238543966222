import Firebase from "firebase/compat/app";
import "firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/database";

// Initialize Firebase
console.log("Mode is " + process.env.NODE_ENV);

const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};
Firebase.initializeApp(config);
//   firebase.firestore().settings({timestampsInSnapshots: true});

export default Firebase;
