import { set } from "dot-prop-immutable";

import { GET_USER_DATA, UPDATE_USER_DATA } from "./actionTypes";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

const homeReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_USER_DATA) {
    state = set(state, "user", data.user || null);
  }

  if (type === UPDATE_USER_DATA) {
    state = set(state, "isLoading", data.isLoading);
    state = set(state, "error", data.error);
  }

  return state;
};

export default homeReducer;
