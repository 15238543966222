import { set } from "dot-prop-immutable";
import { GET_USER_LOCKS } from "./actionTypes";

const initialState = {
  locks: [],
  isLoading: false,
};

const locksReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_USER_LOCKS) {
    state = set(state, "locks", data.locks);
    state = set(state, "isLoading", data.isLoading);
  }

  return state;
};

export default locksReducer;
