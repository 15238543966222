import "date-fns";

import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import SearchBar from "../../components/SearchBar";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { NAME, ADMIN, GUEST_VISITOR, GUEST_WORKER } from "./constants";
import {
  getUserLocks,
  getUserByEmail,
  removeUserFromRedux,
  getLockUsers,
  getLockInformation,
  createAccess,
  resetPermissionStore,
} from "./actions";

import * as CryptoJS from "crypto-js";

import IconAdministrator from "../../assets/permission/administrator_icon.svg";
import IconVisitor from "../../assets/permission/visitor_icon.svg";
import IconWorker from "../../assets/permission/worker_icon.svg";

import IconCheckmark from "../../assets/permission/checkmark.svg";
import IconAvatar from "../../assets/account/ic_avatar.svg";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#52c9f4",
    },
    secondary: {
      main: "#52c9f4",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#fafafc",
    width: "100%",
  },
  mainContent: {
    backgroundColor: "#fafafc",
    padding: "20px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    backgroundColor: "white",
  },
  option: {
    color: "black",
  },
  title: {
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "18pt",
    height: "50px",
    paddingTop: "20px",
    paddingLeft: "60px",
    marginBottom: "0px",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    padding: "50px",
    paddingTop: "5px",
    autoHeight: "true",
    width: "100%",
  },
  selected: {
    background: "white",
    backgroundColor: "white",
    borderStyle: "none!important",
    textTransform: "capitalize",
    "&:hover": {
      background: "white",
      backgroundColor: "white",
      borderStyle: "none!important",
    },
  },
  adminCard: {
    marginLeft: "50px",
    marginRight: "50px",
    backgroundColor: "white",
    padding: "10px",
  },
  permissionCardTitle: {
    textTransform: "capitalize",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "15pt",
    height: "50px",
    paddingTop: "20px",
    paddingLeft: "10px",
    marginBottom: "0px",
  },
  permissionCardDescription: {
    color: "black",
    fontSize: "13pt",
    paddingLeft: "20px",
    marginBottom: "20px",
    paddingBottom: "20px",
  },
  cardTitleIcon: {
    widh: "50px",
    height: "50px",
    marginTop: "10px",
    marginLeft: "10px",
    top: "0px",
    justifyContent: "center",
  },
  checkMarkIconGrid: {
    display: "flex",
    justifyContent: "center",
  },
  checkMarkIcon: {
    width: "20px",
    height: "20px",
    top: "0px",
    justifyContent: "center",
  },
  divider: {
    height: "1px",
    marginRight: "50px",
    marginLeft: "50px",
    marginTop: "5px",
    marginBottom: "20px",
  },
  datePickerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleUsers: {
    marginTop: "10px",
    backgroundColor: "#fafafc",
    color: "rgba(66,64,64,0.5)",
    fontWeight: "bold",
    fontSize: "12pt",
    height: "50px",
    paddingTop: "20px",
    paddingLeft: "60px",
    marginBottom: "0px",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "50px",
    paddingBottom: "50px",
  },
  button: {
    height: "50px",
    backgroundColor: "#def2fb",
    color: "#55c9f4",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "#55c9f4!important",
      color: "#ffffff!important",
    },
  },
  gridNewUsers: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "#fafafc",
    marginTop: "10px",
    paddingLeft: "50px",
    paddingRight: "50px",
    width: "100%",
  },
  gridCard: {
    minWidth: "150px",
    margin: "10px",
    padding: "0px!important",
  },
  cardContent: {
    marginTop: "50px",
    color: "white",
    background: "rgba(49,57,64,0.5)",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "5px",
  },
  cardTitle: {
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "20pt",
    top: "50%",
    height: "50px",
    marginLeft: "15px",
    padding: "0px!important",
    marginBottom: "0px",
    marginTop: "10px",
  },
  userCardTitle: {
    textTransform: "capitalize",
    color: "#424143",
    opacity: "0.5",
    fontWeight: "bold",
    fontSize: "16pt",
    top: "50%",
    marginLeft: "15px",
    marginTop: "10px",
  },
  picture: {
    height: "100px!important",
    width: "100px!important",
    borderRadius: "50%",
    marginTop: "10px",
    marginBottom: "10px",
    borderColor: "rgba(65,65,65,0.1)",
    borderWidth: "5px",
    borderStyle: "solid",
    margin: "auto",
  },
  passwordSectionGrid: {
    display: "flex",
    justifyContent: "center",
  },
  passwordSection: {
    marginTop: "10px",
    marginBottom: "10px",
    minWidth: "250px",
    width: "50%",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 50,
  },
}));

var currentLock = {};
var previousLock = {};

const permissionType = ["Administrador", "visitante", "Trabajador"];

const PermissionSection = (props) => {
  const {
    lockUsers,
    isLoading,
    newUser,
    getUserByEmail,
    removeUserFromRedux,
    getLockInformation,
    getLockUsers,
    createAccess,
    lockInfo,
    isPermissionLoading,
    error,
    isPermissionCreated,
    resetPermissionStore,
  } = props.props;
  const classes = useStyles();
  const { usersPreviousLock, userLocks } = props.props.location.state;

  const [state, setState] = React.useState({
    lock: "",
    permission: "",
    users: [],
    passwordAdmin: null,
    passwordGuest: null,
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [selectedCheckInDate, setSelectedCheckInDate] = React.useState(
    new Date()
  );

  const [selectedCheckOutDate, setSelectedCheckOutDate] = React.useState(
    new Date()
  );

  const handleCheckInDateChange = (date) => {
    setSelectedCheckInDate(date);
  };

  const handleCheckOutDateChange = (date) => {
    setSelectedCheckOutDate(date);
  };

  const [daysOfWeek, setDaysOfWeek] = React.useState(() => ["Lunes"]);

  const handleDaysOfWeek = (event, newDays) => {
    setDaysOfWeek(newDays);
  };

  if (error) {
    alert(error);
  }

  if (isPermissionCreated) {
    redirectUsers();
  }

  setSelectedLock();
  checkAndAddUser(newUser);

  function setSelectedLock() {
    if (userLocks) {
      if (state.lock !== "") {
        for (const userLock of userLocks) {
          if (userLock.key === state.lock) {
            currentLock = userLock;
            break;
          }
        }
      } else {
        if (usersPreviousLock !== undefined) {
          currentLock = usersPreviousLock;
        } else {
          for (const userLock of userLocks) {
            currentLock = userLock;
            break;
          }
        }
      }
    }

    if (previousLock === undefined || previousLock.key !== currentLock.key) {
      previousLock = currentLock;
      getLockUsers(previousLock.key);
      getLockInformation(previousLock.key);
      //event.target.value contains the lock id
      setState((state) => ({
        ...state,
        lock: previousLock.key,
      }));
    }
  }

  function checkAndAddUser(newUser) {
    if (newUser) {
      for (var user in lockUsers) {
        if (user === newUser.key) {
          const message = "El usuario ya tiene permisos para este acceso.";
          alert(message);
          removeUserFromRedux();
          return;
        }
      }
      if (state !== undefined && state.users != undefined) {
        for (var i = 0; i < state.users.length; i++) {
          if (state.users[i].key === newUser.key) {
            const message = "El usuario ya esta agregado a la lista";
            alert(message);
            removeUserFromRedux();
            return;
          }
        }
      }
      state.users.push(newUser);
      removeUserFromRedux();
    }
  }

  function redirectUsers() {
    resetPermissionStore();
    // const path = "/home/users";
    props.props.history.goBack();
  }

  function handleChangePassword(e) {
    if (state.hasOwnProperty(e.target.id)) {
      state[e.target.id] = e.target.value;
    }
  }

  const handleChange = (lock) => (event) => {
    event.persist();
    //event.target.value contains the lock id
    setState((state) => ({
      ...state,
      [lock]: event.target.value,
    }));
  };

  const handleChangePermissionType = (permission) => (event) => {
    event.persist();
    //event.target.value contains the lock id
    setState((state) => ({
      ...state,
      [permission]: event.target.value,
    }));
  };

  function handleSearchUserClick(email) {
    if (email !== undefined && email !== "") {
      getUserByEmail(email);
    } else {
      alert("Ingrese un correo válido");
    }
  }

  const removeUser = (user, index) => (event) => {
    event.persist();

    var array = state.users; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      setState((state) => ({
        ...state,
        users: array,
      }));
    }
  };

  function createPermission() {
    var permissionInfo;
    if (state.users.length <= 0) {
      alert("Debe agregar usuarios a la lista");
      return;
    }

    if (state.permission == ADMIN) {
      permissionInfo = {
        role: "ADMIN",
      };
    } else {
      if (state.passwordAdmin == null || state.passwordAdmin == "") {
        alert("Debe ingresar el password de administrador");
        return;
      }

      if (state.passwordGuest == null || state.passwordGuest == "") {
        alert("Debe ingresar el password de Invitado.");
        return;
      }

      var hash = calculateGuestHashTwoGuest(
        state.passwordAdmin,
        lockInfo.securityKeyTwo,
        state.passwordGuest,
        lockInfo.key
      );

      if (state.permission == GUEST_VISITOR) {
        var fromTimeStamp = selectedCheckInDate.getTime();
        var toTimeStamp = selectedCheckOutDate.getTime();

        if (fromTimeStamp >= toTimeStamp) {
          alert("La hora de egreso debe ser posterior a la hora de ingreso! ");
          return;
        }

        permissionInfo = {
          fromTimeStamp: fromTimeStamp,
          toTimeStamp: toTimeStamp,
          securityInviteHash: hash,
          role: "GUEST_VISITOR",
        };
      } else if (state.permission == GUEST_WORKER) {
        var fromTime =
          selectedCheckInDate.getHours() * 3600 +
          selectedCheckInDate.getMinutes() * 60 +
          selectedCheckInDate.getSeconds();
        var toTime =
          selectedCheckOutDate.getHours() * 3600 +
          selectedCheckOutDate.getMinutes() * 60 +
          selectedCheckOutDate.getSeconds();

        if (fromTime >= toTime) {
          alert("La hora de egreso debe ser posterior a la hora de ingreso! ");
          return;
        }

        permissionInfo = {
          fromTimeOfDay: fromTime,
          toTimeOfDay: toTime,
          monday: daysOfWeek.includes("Lunes") ? true : false,
          tuesday: daysOfWeek.includes("Martes") ? true : false,
          wednesday: daysOfWeek.includes("Miercoles") ? true : false,
          thursday: daysOfWeek.includes("Jueves") ? true : false,
          friday: daysOfWeek.includes("Viernes") ? true : false,
          saturday: daysOfWeek.includes("Sabado") ? true : false,
          sunday: daysOfWeek.includes("Domingo") ? true : false,
          securityInviteHash: hash,
          role: "GUEST_WORKER",
        };
      }
    }

    createAccess(state.users, currentLock, permissionInfo);
  }

  function calculateGuestHashTwoGuest(
    passwordOwner,
    key2,
    passwordGuest,
    deviceId
  ) {
    // console.log("deviceId :" + deviceId);
    // console.log("key2 :" + key2);
    // console.log("passwordOwner :" + passwordOwner);
    // console.log("passwordGuest :" + passwordGuest);

    if (
      passwordOwner == null ||
      key2 == null ||
      deviceId == null ||
      passwordGuest == null
    ) {
      alert("Hay parametros inválidos. Contacte al equipo de Cittyo.");
      return;
    }
    var guestHashTwo = null;

    var keyOne = CryptoJS.enc.Hex.parse(key2);
    var iv = CryptoJS.enc.Utf8.parse(completeString(passwordOwner));
    var val = CryptoJS.enc.Utf8.parse(completeString(passwordGuest));

    var encrypted = CryptoJS.AES.encrypt(val, keyOne, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });

    // console.log("Key :" + key);
    // console.log("iv :" + iv);
    // console.log("val :" + val);
    // console.log("ciphertext :" + encrypted.ciphertext.toString());

    var keyTwo = CryptoJS.enc.Utf8.parse(completeString(passwordGuest));
    var iv2 = CryptoJS.enc.Hex.parse(deviceId);
    var val2 = encrypted.ciphertext;

    var hash = CryptoJS.AES.encrypt(val2, keyTwo, {
      keySize: 16,
      iv: iv2,
      mode: CryptoJS.mode.CTR,
      padding: CryptoJS.pad.NoPadding,
    });

    // console.log("Key :" + key2);
    // console.log("iv :" + iv2);
    // console.log("val :" + val2);
    // console.log("ciphertext :" + hash.ciphertext.toString());

    guestHashTwo = hash.ciphertext.toString();
    return guestHashTwo;
  }

  function completeString(str) {
    var newString = str.substring(0, 16);
    var i;
    if (newString.length < 16) {
      var lenght = newString.length;
      for (i = 0; i < 16 - lenght; i++) {
        newString += "\0";
      }
    }
    return newString;
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={customTheme}>
        <div className={classes.mainContainer}>
          <Typography className={classes.title} gutterBottom component="h2">
            Seleccionar Acceso:
          </Typography>
          <div className={classes.mainContent}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={inputLabel}
                htmlFor="outlined-age-native-simple"
              />
              <Select
                native
                value={state.lock}
                onChange={handleChange("lock")}
                labelWidth={labelWidth}
                inputProps={{
                  lock: "",
                  id: "age-native-helper",
                }}
                classes={{
                  root: classes.selected,
                }}
              >
                {userLocks &&
                  userLocks.map((newLock, index) => (
                    <option
                      className={classes.option}
                      key={newLock.key}
                      value={newLock.key}
                    >
                      {newLock.deviceName}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>

          <Typography className={classes.title} gutterBottom component="h2">
            Tipo de Permiso:
          </Typography>
          <div className={classes.mainContent}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                ref={inputLabel}
                htmlFor="outlined-age-native-simple"
              />
              <Select
                native
                value={state.permission}
                onChange={handleChangePermissionType("permission")}
                labelWidth={labelWidth}
                inputProps={{
                  permission: "",
                  id: "age-native-helper",
                }}
                classes={{
                  root: classes.selected,
                }}
              >
                {permissionType &&
                  permissionType.map((option, index) => (
                    <option
                      className={classes.option}
                      key={index}
                      value={index}
                    >
                      {option}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </div>
          {
            // ADMINISTRADOR
            state.permission == ADMIN ? (
              <Card className={classes.adminCard}>
                <Grid container>
                  <Grid
                    item
                    xs={1}
                    md={1}
                    className={classes.checkMarkIconGrid}
                  >
                    <img
                      className={classes.cardTitleIcon}
                      alt=""
                      src={IconAdministrator}
                    />
                  </Grid>
                  <Grid item xs={11} md={11}>
                    <Typography
                      className={classes.permissionCardTitle}
                      component="h2"
                    >
                      Administrador:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={11} md={11}>
                    <Typography
                      className={classes.permissionCardDescription}
                      component="h3"
                    >
                      Acceso total y pueden generar permisos a nuevos invitados.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <img
                      className={classes.checkMarkIcon}
                      alt=""
                      src={IconCheckmark}
                    />
                  </Grid>
                </Grid>
              </Card>
            ) : state.permission == GUEST_VISITOR ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Card className={classes.adminCard}>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      className={classes.checkMarkIconGrid}
                    >
                      <img
                        className={classes.cardTitleIcon}
                        alt=""
                        src={IconVisitor}
                      />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Visitante:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <Typography
                        className={classes.permissionCardDescription}
                        component="h3"
                      >
                        Tiene acceso desde y hasta fecha y hora específicas.
                      </Typography>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <img
                        className={classes.checkMarkIcon}
                        alt=""
                        src={IconCheckmark}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Check In:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.datePickerContainer}
                    >
                      <KeyboardDatePicker
                        disableToolbar
                        disablePast="true"
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="DESDE LA FECHA:"
                        value={selectedCheckInDate}
                        onChange={handleCheckInDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.datePickerContainer}
                    >
                      <KeyboardTimePicker
                        margin="normal"
                        disablePast="true"
                        id="time-picker"
                        label="DESDE LA HORA:"
                        value={selectedCheckInDate}
                        onChange={handleCheckInDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Check Out:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.datePickerContainer}
                    >
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        minDate={selectedCheckInDate}
                        disablePast="true"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="HASTA LA FECHA:"
                        value={selectedCheckOutDate}
                        onChange={handleCheckOutDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className={classes.datePickerContainer}
                    >
                      <KeyboardTimePicker
                        margin="normal"
                        disablePast="true"
                        id="time-picker"
                        label="HASTA LA HORA:"
                        value={selectedCheckOutDate}
                        onChange={handleCheckOutDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.permissionCardTitle}
                      component="h2"
                    >
                      Password Administrador:
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.passwordSectionGrid}
                  >
                    <TextField
                      required
                      id="passwordAdmin"
                      label="Password Admin"
                      type="password"
                      className={classes.passwordSection}
                      autoComplete="current-password"
                      variant="outlined"
                      onChange={handleChangePassword}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Typography
                      className={classes.permissionCardTitle}
                      component="h2"
                    >
                      Ingrese un nuevo password de Invitado:
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={12}
                    className={classes.passwordSectionGrid}
                  >
                    <TextField
                      required
                      id="passwordGuest"
                      label="Password Invitado"
                      type="password"
                      className={classes.passwordSection}
                      autoComplete="current-password"
                      variant="outlined"
                      onChange={handleChangePassword}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.cssOutlinedInput,
                          focused: classes.cssFocused,
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                    />
                  </Grid>
                </Card>
              </MuiPickersUtilsProvider>
            ) : state.permission == GUEST_WORKER ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Card className={classes.adminCard}>
                  <Grid container>
                    <Grid
                      item
                      xs={1}
                      md={1}
                      className={classes.checkMarkIconGrid}
                    >
                      <img
                        className={classes.cardTitleIcon}
                        alt=""
                        src={IconWorker}
                      />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Trabajador:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                    <Grid item xs={11} md={11}>
                      <Typography
                        className={classes.permissionCardDescription}
                        component="h3"
                      >
                        Tiene acceso días específicos de la semana durante horas
                        específicas.
                      </Typography>
                    </Grid>
                    <Grid item xs={1} md={1}>
                      <img
                        className={classes.checkMarkIcon}
                        alt=""
                        src={IconCheckmark}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Días de la semana:
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <div className={classes.toggleContainer}>
                        <ToggleButtonGroup
                          value={daysOfWeek}
                          onChange={handleDaysOfWeek}
                          aria-label="text alignment"
                        >
                          <ToggleButton value="Lunes" aria-label="Lunes">
                            L
                          </ToggleButton>
                          <ToggleButton value="Martes" aria-label="Martes">
                            M
                          </ToggleButton>
                          <ToggleButton
                            value="Miercoles"
                            aria-label="Miercoles"
                          >
                            M
                          </ToggleButton>
                          <ToggleButton value="Jueves" aria-label="Jueves">
                            J
                          </ToggleButton>
                          <ToggleButton value="Viernes" aria-label="Viernes">
                            V
                          </ToggleButton>
                          <ToggleButton value="Sabado" aria-label="Sabado">
                            S
                          </ToggleButton>
                          <ToggleButton value="Domingo" aria-label="Domingo">
                            D
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Horarios:
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={12}
                      className={classes.datePickerContainer}
                    >
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="DESDE:"
                        value={selectedCheckInDate}
                        onChange={handleCheckInDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={12}
                      className={classes.datePickerContainer}
                    >
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="HASTA:"
                        value={selectedCheckOutDate}
                        onChange={handleCheckOutDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Password Administrador:
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.passwordSectionGrid}
                    >
                      <TextField
                        required
                        id="passwordAdmin"
                        label="Password Admin"
                        type="password"
                        className={classes.passwordSection}
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChangePassword}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Typography
                        className={classes.permissionCardTitle}
                        component="h2"
                      >
                        Ingrese un nuevo password de Invitado:
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      className={classes.passwordSectionGrid}
                    >
                      <TextField
                        required
                        id="passwordGuest"
                        label="Password Invitado"
                        type="password"
                        className={classes.passwordSection}
                        autoComplete="current-password"
                        variant="outlined"
                        onChange={handleChangePassword}
                        InputLabelProps={{
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </MuiPickersUtilsProvider>
            ) : (
              <div>Null</div>
            )
          }

          <div>
            {/* Search users section */}
            <Typography
              className={classes.titleUsers}
              gutterBottom
              component="h2"
            >
              Buscar usuarios:
            </Typography>
            <SearchBar
              className={classes.searchBar}
              onSearch={handleSearchUserClick}
              placeHolder={"Buscar usuario"}
              isLoading={isLoading}
            />
            <Grid container className={classes.gridNewUsers}>
              {state.users &&
                state.users.map((user, index) => (
                  <Grid item key={"USER_" + index} xs={10} sm={5} md={3} lg={2}>
                    <Card className={classes.gridCard}>
                      <Typography
                        className={classes.userCardTitle}
                        gutterBottom
                        component="h2"
                      >
                        {user.firstName + " " + user.lastName}
                      </Typography>
                      <Divider className={classes.firstDivider} />
                      <CardMedia
                        className={classes.picture}
                        component="img"
                        alt={user.lastName}
                        image={user.userPictureUrl}
                        title={user.lastName}
                        src={
                          !user.devicePictureUrl
                            ? IconAvatar
                            : user.devicePictureUrl
                        }
                        onError={(e) => {
                          e.target.src = IconAvatar;
                        }}
                      />
                      <Divider className={classes.firstDivider} />

                      <IconButton
                        className={classes.iconButton}
                        aria-label="remove"
                        onClick={removeUser(user, index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Card>
                  </Grid>
                ))}
            </Grid>
            <div className={classes.buttonContainer}>
              {isPermissionLoading ? (
                <div className={classes.spinnerContainer}>
                  <CircularProgress className={classes.spinnerStyle} />
                </div>
              ) : (
                <Button
                  className={classes.button}
                  onClick={() => createPermission()}
                >
                  Crear permiso
                </Button>
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

class Permission extends Component {
  render(props) {
    return <PermissionSection props={this.props} />;
  }

  componentDidMount() {
    // this.props.getUserLocks();
  }
}

//propiedades que puedo obtener del store
function mapStateToProps(state) {
  return {
    lockInfo: state[NAME].lockInfo,
    newUser: state[NAME].newUser,
    userLocks: state[NAME].userLocks,
    lockUsers: state[NAME].lockUsers,
    isLoading: state[NAME].isLoading,
    isPermissionLoading: state[NAME].isPermissionLoading,
    isPermissionCreated: state[NAME].isPermissionCreated,
    error: state[NAME].error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLockInformation: (lockId) => dispatch(getLockInformation(lockId)),
    removeUserFromRedux: () => dispatch(removeUserFromRedux()),
    getUserLocks: () => dispatch(getUserLocks()),
    getUserByEmail: (email) => dispatch(getUserByEmail(email)),
    getLockUsers: (lockId) => dispatch(getLockUsers(lockId)),
    createAccess: (users, lock, permissionInfo) =>
      dispatch(createAccess(users, lock, permissionInfo)),
    resetPermissionStore: () => dispatch(resetPermissionStore()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Permission);
