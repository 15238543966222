import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuContent from "./MenuContent";
import CittyoLogo from "../../assets/topbar/cittyo_small_logo.svg";

const drawerWidth = 300;
export default function PersistentDrawerLeft({ userInfo, open, handleClose }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      open={open}
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <img alt="" variant="h6" className={classes.logo} src={CittyoLogo} />

        <IconButton onClick={handleClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <MenuContent userInfo={userInfo} />
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#f9f7f8",
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  logo: {
    marginLeft: 48,
    height: "30px",
    flex: 1,
  },
}));
