import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import UserAvatar from "../../assets/account/ic_avatar.svg";

export default function ProfileMenu(props) {
  const classes = useStyles();
  const [userInfo, setUser] = useState(props.userInfo);

  useEffect(() => {
    setUser(props.userInfo);
  }, [props.userInfo]);

  return (
    <div className={classes.container}>
      <Avatar
        alt="Default Avatar"
        className={classes.avatar}
        src={
          userInfo && userInfo.userPictureUrl
            ? userInfo.userPictureUrl
            : UserAvatar
        }
        onError={(e) => {
          e.target.src = UserAvatar;
        }}
      />
      <p className={classes.name}>
        {userInfo && userInfo.firstName && userInfo.lastName
          ? userInfo.firstName + " " + userInfo.lastName
          : "-"}
      </p>
      <p className={classes.email}>
        {userInfo && userInfo.email ? userInfo.email : "-"}
      </p>
      <Divider className={classes.divider} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: "80px!important",
    height: "80px!important",
    textAlign: "center",
    justifyContent: "center",
    margin: "auto",
    borderRadius: "50%",
    borderColor: "rgba(65,65,65,0.1)",
    borderWidth: "5px",
    borderStyle: "solid",
  },
  container: {
    paddingTop: "50px",
  },
  name: {
    padding: "0px!important",
    margin: "0px!important",
    marginTop: "15px!important",
    color: "#fff!important",
    textAlign: "center",
    fontSize: "11pt",
    fontWeight: "bold",
  },
  email: {
    padding: "0px!important",
    marginTop: "10px!important",
    color: "#fff!important",
    textAlign: "center",
    fontSize: "8pt",
    marginLeft: "20px",
    marginRight: "20px",
  },
  divider: {
    backgroundColor: "#fff",
    height: "1px",
    marginLeft: "50px",
    marginRight: "50px",
    marginBottom: "30px",
    marginTop: "40px!important",
  },
}));
