import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import IconAvatar from "../../assets/account/ic_avatar.svg";
import IconOwner from "../../assets/permission/owner_tag.svg";
import ControlPointUserSchedule from "./ControlPointUserSchedule";

const useStyles = makeStyles((theme) => ({
    gridCard: {
        minWidth: 180,
        margin: 10,
        padding: "0px!important",
    },
    cardTitle: {
        display: "-webkit-box",
        textTransform: "capitalize",
        color: "#424143",
        opacity: "0.5",
        fontWeight: "bold",
        fontSize: "10pt",
        top: "50%",
        marginLeft: "15px",
        padding: "0px!important",
        marginTop: 10,
        marginBottom: 5,
        marginRight: 10,
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 1,
        maxLines: 1,
    },
    email: {
        marginTop: 0,
        marginLeft: 15,
        marginRight: 15,
        fontSize: 12,
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxLines: 1,
        color: "#424143",
    },
    picture: {
        height: "40px!important",
        width: "40px!important",
        borderRadius: "50%",
        marginTop: "10px",
        marginBottom: 10,
        borderColor: "rgba(65,65,65,0.1)",
        borderWidth: 2,
        borderStyle: "solid",
        margin: "auto",
    },
    selected: {
        background: "white",
        backgroundColor: "white",
        borderStyle: "none!important",
        textTransform: "capitalize",
        "&:hover": {
            background: "white",
            backgroundColor: "white",
            borderStyle: "none!important",
        },
    },
    ownerImage: {
        margin: 10,
    },
    iconButton: {
        margin: 0,
        padding: 5,
    },
}));

export default function ControlPointUser({ currentUserRole, user, onRemoveUser }) {
    const classes = useStyles();

    return (
        <Grid item xs={6} sm={4} md={3} lg={3}>
            <Card className={classes.gridCard}>
                <Grid container>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={2}>
                        <CardMedia
                            className={classes.picture}
                            component="img"
                            alt={user.email}
                            image={user.userPictureUrl}
                            title={user.email}
                            src={!user.devicePictureUrl ? IconAvatar : user.devicePictureUrl}
                            onError={(e) => {
                                e.target.src = IconAvatar;
                            }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.cardTitle} gutterBottom component="h2">
                            {user.lastName + " " + user.firstName}
                        </Typography>
                        <Typography className={classes.email} gutterBottom component="h2">
                            {user.email}
                        </Typography>
                    </Grid>

                </Grid>

                <div>
                    {
                        user.schedule ?
                            <div>
                                <Divider className={classes.firstDivider} />
                                <ControlPointUserSchedule schedule={user.schedule}>
                                </ControlPointUserSchedule>
                            </div>
                            : <div></div>
                    }

                </div>
                <div>
                    <Divider className={classes.firstDivider} />
                    {user.role === "OWNER" ? (
                        <img
                            alt="owner role"
                            className={classes.ownerImage}
                            src={IconOwner}
                        />
                    ) : (
                        currentUserRole === "OWNER" ?
                            <div>
                                <IconButton
                                    className={classes.iconButton}
                                    aria-label="remove"
                                    onClick={() => onRemoveUser(user)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div> :
                            <div></div>
                    )}
                </div>
            </Card>
        </Grid>
    );
}
