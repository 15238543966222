import React, { Component, useEffect, useState } from "react";
import NavBar from "../../components/navigation/NavBar";
import Container from "../../components/Container";
import { BrowserRouter as Router } from "react-router-dom";

import { connect } from "react-redux";
import { NAME } from "./constants";
import { getUserData } from "./actions";

const HomeSection = ({user, auth, isLoading, history}) => {
  const [userInfo, setUser] = useState(user);

  // Redirect to login if auth is empty
  useEffect(() => {
    if (auth.isEmpty) {
      let path = "/signin";
      history.push(path);
    } 
  }, [auth]);
  
  useEffect(() => {
    setUser(user);
  }, [user]);

  return (
    <React.Fragment>
      <Router>
        <NavBar userInfo={userInfo} />
        <Container userInfo={userInfo} isLoading={isLoading} />
      </Router>
    </React.Fragment>
  );
};

class Home extends Component {
  render(props) {
    return <HomeSection history = {this.props.history} user={this.props.user} auth={this.props.auth}></HomeSection>;
  }

  componentDidMount() {
    this.props.getUserData();
  }
}

function mapStateToProps(state) {
  return {
    user: state[NAME].user,
    auth: state["firebase"].auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getUserData: () => dispatch(getUserData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
