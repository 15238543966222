
function sortUsersByRole(users) {
    var u = Object.values(users);
    if (u !== undefined && u.length > 1) {
      u.sort(function (a, b) {
        if (a.role === "OWNER" && b.role !== "OWNER") {
          return -1;
        }
  
        if (a.role === "ADMIN" && b.role === "OWNER") {
          return 1;
        }
  
        if (
          (a.role === "OWNER" || a.role === "ADMIN") &&
          (b.role === "GUEST_VISITOR" || b.role === "GUEST_WORKER")
        ) {
          return -1;
        }
  
        return 0;
      });
    }
  
    if (u === undefined) {
      u = [];
    }
    return u;
  }
  
  function sortUsersDisplayedByName(users) {
    var u = Object.values(users);
    if (u !== undefined && u.length > 1) {
      u.sort(function (a, b) {
        if (a.firstName === undefined) {
          a.firstName = "";
        }
        if (a.lastName === undefined) {
          a.lastName = "";
        }
  
        const userA = a.lastName + " " + a.firstName;
  
        if (b.firstName === undefined) {
          b.firstName = "";
        }
        if (b.lastName === undefined) {
          b.lastName = "";
        }
  
        const userB = b.lastName + " " + b.firstName;
        return userA.localeCompare(userB);
      });
    }
  
    if (u === undefined) {
      u = [];
    }
    return u;
  }


export {
    sortUsersDisplayedByName,
    sortUsersByRole
}