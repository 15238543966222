// VENDOR
import { set } from "dot-prop-immutable";
// ACTION TYPES
import { GET_USER_LOCKS, GET_LOCK_USERS } from "./actionTypes";

const initialState = {
  userLocks: [],
  lockUsers: [],
  isLoading: false,
};

const lockUsersReducer = (state = initialState, action) => {
  const { type, data } = action;

  if (type === GET_USER_LOCKS) {
    state = set(state, "userLocks", data.userLocks);
  }

  if (type === GET_LOCK_USERS) {
    state = set(state, "lockUsers", data.lockUsers);
    state = set(state, "isLoading", data.isLoading);
  }

  return state;
};

export default lockUsersReducer;
