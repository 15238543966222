import spinner from "../assets/animations/spinner.json";
import closedToOpen from "../assets/animations/state/state_locked_unlocked.json";
import openToClosed from "../assets/animations/state/state_unlocked_locked.json";
import control from "../assets/animations/control/control_vertical_horizontal.json";
import doorBell from "../assets/animations/doorBell/bell_animation.json";

class Animations {
  static getLockLoadingAnimation() {
    const animation = spinner;
    return {
      loop: true,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  static getLockClosedtOpenAnimation() {
    const animation = closedToOpen;
    return {
      loop: false,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  static getLockOpenToClosedAnimation() {
    const animation = openToClosed;
    return {
      loop: false,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  static getActionAnimation() {
    return {
      loop: false,
      autoplay: false,
      animationData: control,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  static getDoorBellAnimation() {
    const animation = doorBell;
    return {
      loop: false,
      autoplay: false,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }

  static getDoorBellAnimationAutoplay() {
    const animation = doorBell;
    return {
      loop: false,
      autoplay: true,
      animationData: animation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  }
}

export default Animations;
