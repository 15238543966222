import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { NAME } from "./constants";
import { getLockActivity, getGuestLockActivity } from "./actions";

import CustomExcelExport from "./CustomExcelExport";

function getUser(activity) {
  if (activity.version === "2") {
    switch (activity.type) {
      case "DEVICE_STATUS":
        return "Device";
      case "ACTION":
        if (activity.author) {
          return activity.author.email;
        }
        if (activity.key) {
          return "KEY: " + activity.key.guestName;
        }

        return "-";
      case "KEY":
        if (activity.author) {
          return activity.author.email;
        }
        return "-";
      default:
        return "-";
    }
  }
  return activity.userEmail;
}

function getEvent(activity) {
  if (activity.version === "2") {
    return getEventV2(activity);
  }
  return getEventV1(activity);
}

function getEventV2(activity) {
  switch (activity.type) {
    case "DEVICE_STATUS":
      if (activity.eventType === "OFFLINE") {
        return "Offline";
      }
      return "Online";

    case "ACTION":
      if (activity.eventType === "OPEN") {
        return "Abrió";
      }
      break;
    case "KEY":
      if (activity.eventType === "CREATE") {
        return "Link Creado";
      }
      if (activity.eventType === "DELETE") {
        return "Link eliminado";
      }
      if (activity.eventType === "OPEN") {
        return "Abrió";
      }
      break;

    default:
      return "-";
  }

  return "-";
}

function getEventV1(activity) {
  if (activity.stateLock === "OPENED") {
    return "Abrió";
  } else if (activity.stateLock === "LOCKED") {
    return "Cerró";
  } else if (activity.stateLock === "UNLOCKED") {
    return "Desbloqueó";
  }
  return "-";
}

const columns = [
  {
    id: "stateLock",
    label: "Evento",
    minWidth: 100,
    align: "left",
    format: (value) => {
      return getEvent(value);
    },
  },
  {
    id: "timeStamp",
    label: "Fecha",
    minWidth: 100,
    align: "center",
    format: (value) => {
      var ts = new Intl.DateTimeFormat("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(value);
      return ts;
    },
  },
  {
    id: "timeStamp",
    label: "Hora",
    minWidth: 100,
    align: "center",
    format: (value) => {
      var ts = new Intl.DateTimeFormat("es-AR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(value);
      return ts;
    },
  },
  {
    id: "userEmail",
    label: "Email",
    minWidth: 100,
    align: "center",
    format: (value) => {
      return getUser(value);
    },
  },
  {
    id: "stateBattery",
    label: "Batería (%)",
    minWidth: 100,
    align: "center",
    format: (value) => {
      var bat = parseInt(value);
      if (bat === 0) value = "-";
      return value;
    },
  },
];

const useStyles = makeStyles({
  root: {
    marginLeft: "50px",
    marginRight: "50px",
    minWidth: 120,
  },
  tableWrapper: {
    overflow: "auto",
  },
  container: {
    backgroundColor: "red",
  },
  spinnerStyle: {
    color: "#55c9f4",
  },
  spinnerContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  downloadStyle: {
    width: "100%",
  },
});

const ActivityTableSection = ({
  isLoading,
  currentLock,
  currentLockActivity,
}) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [lockActivity, setLockActivity] = React.useState(
    Object.values(currentLockActivity)
  );
  const [newActivity, setNewActivity] = React.useState([]);

  React.useEffect(() => {
    setLockActivity(currentLockActivity);
  }, [currentLockActivity]);

  React.useEffect(() => {
    const values = lockActivity.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    setNewActivity(values);
  }, [lockActivity, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return isLoading ? (
    <div className={classes.spinnerContainer}>
      <CircularProgress className={classes.spinnerStyle} />
    </div>
  ) : (
    <div>
      <Grid container justifyContent="flex-end">
        <CustomExcelExport
          className={classes.downloadStyle}
          isLoading={isLoading}
          lockActivity={lockActivity}
          lockName={currentLock.deviceName}
        ></CustomExcelExport>
      </Grid>

      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id + index}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newActivity.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.timeStamp + index}
                  >
                    {columns.map((column) => {
                      const value =
                        column.id === "stateLock" || column.id === "userEmail"
                          ? row
                          : row[column.id];
                      return (
                        <TableCell
                          key={column.label + index}
                          align={column.align}
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={lockActivity.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const ActivityTable = ({
  isLoading,
  lockActivity,
  currentLock,
  getLockActivity,
  getGuestLockActivity,
}) => {
  const [currentLockKey, setCurrentLockKey] = React.useState(currentLock.key);

  React.useEffect(() => {
    if (currentLockKey !== currentLock.key) {
      setCurrentLockKey(currentLock.key);
      if (currentLock.role === "OWNER" || currentLock.role === "ADMIN") {
        getLockActivity(currentLock.key, 0);
      } else {
        getGuestLockActivity(currentLock);
      }
    }
  }, [currentLock, currentLockKey, getLockActivity, getGuestLockActivity]);

  return (
    <ActivityTableSection
      isLoading={isLoading}
      currentLockActivity={lockActivity}
      currentLock={currentLock}
    />
  );
};

function mapStateToProps(state) {
  return {
    lockActivity: state[NAME].lockActivity,
    userLocks: state[NAME].userLocks,
    isLoading: state[NAME].isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getLockActivity: (lockId, quantity) =>
      dispatch(getLockActivity(lockId, quantity)),
    getGuestLockActivity: (currentLock) =>
      dispatch(getGuestLockActivity(currentLock)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTable);
