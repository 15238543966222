import React from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  button: {
    height: "30px",
    backgroundColor: "#55c955",
    color: "#ffffff",
    fontWeight: "bold!important",
    textTransform: "capitalize!important",
    "&:hover": {
      backgroundColor: "#88ea88!important",
      color: "#ffffff!important",
    },
    marginBottom: "20px",
    marginRight: "50px",
  },
}));

const CustomExcelExport = ({ isLoading, lockActivity, lockName }) => {
  const classes = useStyles();
  const [activity, setActivity] = React.useState(lockActivity);
  const [fileName, setFileName] = React.useState(lockName);
  const [isProcessing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    function getDate(value) {
      var ts = new Intl.DateTimeFormat("es-AR", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(value);
      return ts;
    }

    function getTime(value) {
      var ts = new Intl.DateTimeFormat("es-AR", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(value);
      return ts;
    }

    function getUser(activity) {
      if (activity.version === "2") {
        switch (activity.type) {
          case "DEVICE_STATUS":
            return "Device";
          case "ACTION":
            if (activity.author) {
              return activity.author.email;
            }
            if (activity.key) {
              return "KEY: " + activity.key.guestName;
            }
    
            return "-";
          case "KEY":
            if (activity.author) {
              return activity.author.email;
            }
            return "-";
          default:
            return "-";
        }
      }
      return activity.userEmail;
    }
    
    function getEvent(activity) {
      if (activity.version === "2") {
        return getEventV2(activity);
      }
      return getEventV1(activity);
    }
    
    function getEventV2(activity) {
      switch (activity.type) {
        case "DEVICE_STATUS":
          if (activity.eventType === "OFFLINE") {
            return "Offline";
          }
          return "Online";
    
        case "ACTION":
          if (activity.eventType === "OPEN") {
            return "Abrió";
          }
          break;
        case "KEY":
          if (activity.eventType === "CREATE") {
            return "Link Creado";
          }
          if (activity.eventType === "DELETE") {
            return "Link eliminado";
          }
          if (activity.eventType === "OPEN") {
            return "Abrió";
          }
          break;
    
        default:
          return "-";
      }
    
      return "-";
    }
    
    function getEventV1(activity) {
      if (activity.stateLock === "OPENED") {
        return "Abrió";
      } else if (activity.stateLock === "LOCKED") {
        return "Cerró";
      } else if (activity.stateLock === "UNLOCKED") {
        return "Desbloqueó";
      }
      return "-";
    }

    async function convertDataToExcel(lockActivity) {
      var result = [];
      for (var i = 0; i < lockActivity.length; i++) {
        var event = getEvent(lockActivity[i]);
        var date = getDate(lockActivity[i].timeStamp);
        var time = getTime(lockActivity[i].timeStamp);
        var user = getUser(lockActivity[i]);

        const item = {
          stateLock: event,
          date: date,
          time: time,
          user: user,
        };
        result.push(item);
      }

      setActivity(result);
      setProcessing(false);
    }

    setProcessing(true);
    convertDataToExcel(lockActivity);
  }, [lockActivity]);

  React.useEffect(() => {
    function getCurrentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      return mm + "/" + dd + "/" + yyyy;
    }

    const file = "cittyo_historial_" + lockName + "_" + getCurrentDate();
    setFileName(file);
  }, [lockName]);

  return (
    <div>
      {isLoading || isProcessing ? (
        <div />
      ) : (
        <ExcelFile
          filename={fileName}
          element={
            <Button className={classes.button}>Descargar Historial</Button>
          }
        >
          <ExcelSheet data={activity} name="Historial">
            <ExcelColumn label="EVENTO" value="stateLock" />
            <ExcelColumn label="FECHA" value="date" />
            <ExcelColumn label="HORA" value="time" />
            <ExcelColumn label="USUARIO" value="user" />
          </ExcelSheet>
        </ExcelFile>
      )}
    </div>
  );
};

export default CustomExcelExport;
